import { Button } from "@mui/material";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  URL_CREATE_RANKING,
  URL_DELETE_RANKING,
  URL_EDIT_RANKING,
  URL_GETALLORGANISOR_FOR_DROPDOWN,
  URL_GETALLORGANISOR_WITH_RANKING,
  URL_GET_COMPANY_DETAILS,
  URL_GET_ORDER_TICKETID,
} from "../api/constants";
import Alert from "./alert/alert";
import Leftnav from "../components/leftNav/Leftnav";
import Navbar from "./navbar";
import SearchableDropdown from "./itemmaster/SearchableDropdown";
import myloader from "../images/loader.gif";
import Loader from "./Loader";

function Ranking() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchval, setsearchval] = useState("search company");

  const columns = [
    // { field: 'id', headerName: 'ID' },
    { field: "strCompanyName", headerName: "Company", width: 220 },
    { field: "iSortOrder", headerName: "Rank", width: 150 },
    { field: "orgName", headerName: "Organiser Name", width: 230 },
    // { field: 'sortOrder', headerName: 'sortOrder', width: 220 },
    { field: "mobile", headerName: "Mobile", width: 180 },
    // { field: 'userName', headerName: 'userName', width: 220 },

    {
      field: "Action",
      headerName: "Edit",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => EditRank(params.row)}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "DELETE",
      headerName: "Delete",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            style={{ backgroundColor: "red" }}
            color="primary"
            onClick={(e) => DeleteRank(params.row)}
          >
            DELETE
          </Button>
        );
      },
    },
  ];

  const [check, setcheck] = useState(false);
  const [tableData, settableData] = useState([]);
  const [orgname, setorgname] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropdown, setdropdown] = useState([]);
  const [rankednum, setrankednum] = useState("");
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [rowpos, setrowpos] = useState("");
  const [showpopup, setshowpopup] = useState(false);

  const [updatetrue, setupdatetrue] = useState(false);

  setTimeout(() => {
    setalert(false);
  }, 3000);

  const EditRank = (item) => {
    debugger;
    setorgname("");
    setupdatetrue(true);
    setsearchval(item.strCompanyName);

    setorgname(item.mcode);
    setrankednum(item.iSortOrder);
    var rowpos = item.rowpos;
    setrowpos(item.rowpos);
    var sortOrdrer = item.iSortOrder;
  };

  const DeleteRank = (item) => {
    setrowpos(item.rowpos);

    setshowpopup(true);
  };

  const deleteuser = () => {
    console.log(rowpos);

    axios.post(URL_DELETE_RANKING, { rowpos: rowpos }).then((response) => {
      console.log(response);
      if (response.data.isSuccess === true) {
        setalert(true);
        setalertmsg("Rank Deleted Successfully !");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handlecheck = (e) => {
    setcheck(e.target.checked);
  };

  var count = 0;
  const handlesubmit = (e) => {
    debugger;

    console.log(orgname, rankednum, check);
    count = count + 1;

    console.log(check, count);
    if (orgname === "") {
      setalert(true);
      setalertmsg("Please Select Company Name");
    } else if (rankednum === "") {
      setalert(true);
      setalertmsg("Please Select Ranking");
    } else if (orgname !== "" && rankednum !== "") {
      setIsLoading(true);
      axios
        .post(URL_CREATE_RANKING, {
          Mcode: orgname,
          iSortOrder: rankednum,
          userType: "ORGANISER",
        })
        .then((response) => {
          console.log(response.data.isSuccess);

          if (response.data.isSuccess === true) {
            setIsLoading(false);
            setalert(true);
            setalertmsg("Ranked Successfully created");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            setIsLoading(false);
            setalert(true);
            setalertmsg(`Rank ${rankednum} is  Already Exists !`);
          }
        });
    }

    // setTimeout(() => {
    //   var row = document.querySelector(".MuiDataGrid-overlay");
    //   row.innerHTML = `<img height="80px" src="https://gifimage.net/wp-content/uploads/2018/04/page-loading-gif-14.gif" />`;
    // }, 5000);
  };

  useEffect(() => {
    setIsLoading(true);
    var allcompany = [];

    axios.get(URL_GETALLORGANISOR_WITH_RANKING).then((response) => {
      // console.log(response?.data.data)
      settableData(response.data.isSuccess ? response?.data.data : []);
      setIsLoading(false);
      // setdropdown(response.data.data)
    });
    var config = {
      method: "get",
      url: URL_GET_COMPANY_DETAILS,
      headers: {},
    };

    axios(config).then(function (response) {
      // console.log(response?.data.data);
      for (var i = 0; i < response?.data.data.length; i++) {
        if (response?.data.data[i].strCompanyName !== "") {
          allcompany.push(response?.data.data[i]);
        }
      }

      setdropdown(response.data.isSuccess ? allcompany : []);
    });
  }, []);

  const handleUpdate = () => {
    console.log(rankednum, orgname);

    var data = JSON.stringify({
      rowpos: rowpos,
      iSortOrder: rankednum,
    });

    var config = {
      method: "post",
      url: URL_EDIT_RANKING,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess === true) {
          setalert(true);
          setalertmsg("Rank Updated Successfully !");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlesearchval = (val) => {
    debugger;
    // setvalueforsearch(val)
    setorgname(val);
  };

  const jsonArray = dropdown.map((item) => {
    return {
      id: item.mcode,
      name: item.strCompanyName,
    };
  });

  var color = "red";
  console.log(selectedRows);

  function handleSelectedRows(row) {
    const selectedRowData = tableData.filter((row) =>
      tableData.rowpos.has(row.rowpos)
    );
    console.log(selectedRowData);
    setSelectedRows(selectedRowData);
    console.log(selectedRows);
    return row.rowpos;
  }

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? (
        <Alert className="top animatingTop" msg={alertmsg} color={color} />
      ) : (
        ""
      )}
      <div className="main rnk-main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Organiser Ranking</h5>
        </center>
        <br />

        {/* {isLoading ? (
          <div className="loaderDiv">
            <img src={myloader} />
          </div>
        ) : null} */}
        {/* // loader is here */}

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="d-flex space">
              <div className="mb-3 space-child animatingTop">
                <label>Choose Company</label>
                <br />
                {/* <select className='das' value={orgname} onChange={(e) => setorgname(e.target.value)}>
              <option value="" >Select Company</option>
              {
                dropdown.map((item, i) => (
                  <>
                    <option value={item.mcode}>{item.strCompanyName}</option>
                  </>
                ))
              }
            </select> */}

                <SearchableDropdown
                  options={jsonArray}
                  label="name"
                  id="rowpos"
                  selectedVal={searchval}
                  handleChange={(val) => setsearchval(val)}
                  handleChangeval={(val) => handlesearchval(val)}
                />
              </div>
              <div className="mb-3 space-child animatingTop">
                <label>Select Ranking</label>
                <br />
                <select
                  value={rankednum}
                  onChange={(e) => setrankednum(e.target.value)}
                >
                  <option value="">Select Rank</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
              </div>
              {/* <div className="mb-3 animatingTop">
            <div className="form-check form-switch ps-0">
              <input className="form-check-input ms-auto mt-1" type="checkbox" id="flexSwitchCheckDefault" onChange={(e) => handlecheck(e)} />
              <label className="form-check-label ms-2" for="flexSwitchCheckDefault">I WANNA RANKED</label>
            </div>
          </div> */}

              <div className="mb-3 space-child animatingTop">
                {updatetrue ? (
                  <Button
                    style={{ background: "green", color: "#fff" }}
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    style={{ background: "green", color: "#fff" }}
                    onClick={handlesubmit}
                  >
                    SUBMIT
                  </Button>
                )}
              </div>
            </div>
            <br />
            <div className="dashtbl animatingBottom rankingtbl">
              <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.rowpos}
                components={{
                  Toolbar: GridToolbarExport,
                }}
                // checkboxSelection
                // onSelectionModelChange={(id) => {
                //   const selectedIDs = new Set(id);
                //   const selectedRowsData = tableData.filter((row) =>
                //     selectedIDs.has(row.rowpos)
                //   );

                //   setSelectedRows(selectedRowsData);
                //   // setfirstname(selectedRows.mcode)
                // }}
                {...tableData}
              />
              {showpopup ? (
                <div>
                  <div className="userpopup animatingTop">
                    <p
                      className="cross mb"
                      style={{ zIndex: "100000" }}
                      onClick={() => setshowpopup(false)}
                    >
                      <ion-icon name="close-outline"></ion-icon>
                    </p>
                    {tableData.map(
                      (item) =>
                        item.rowpos === rowpos && (
                          <>
                            <div className="popupdata">
                              <p>
                                {" "}
                                <span>
                                  Are you sure To delete <br />
                                  <br />
                                </span>
                                {item.strCompanyName}
                                RANK :{item.iSortOrder}
                              </p>
                            </div>
                            <Button
                              variant="contained"
                              onClick={() => deleteuser()}
                            >
                              delete
                            </Button>
                          </>
                        )
                    )}
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Ranking;
