import React from "react";
import axios from "axios";
import Select from "react-select";
import Modal from "react-modal";
import DataGrids from "../../table/DataGrid";
import Leftnav from "../leftNav/Leftnav";
import Navbar from "../navbar";
import "./categorymaster.css";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Alert from "../alert/alert";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  getAllUsers,
  updateUser,
  URL_ADDCATEGORY,
  URL_DELETECATEGORY,
  URL_DELETEUSER,
  URL_GETALCATEGORY,
  URL_GETALLUSER,
  URL_UPDATECATEGORY,
  URL_UPDATEUSER,
  URL_UPLOADIMAGE,
} from "../../api/constants";
import Loader from "../Loader";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    inset: "40% auto auto 50%",
  },
};
function Usermanager() {
  const [rowpos, setrowpos] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [sortorder, setsortorder] = useState("");
  const [active, setactive] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [catMedia, setCatMedia] = useState("");
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [updatebtn, setupdatebtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [plusbtn, setplusbtn] = useState(false);
  const [plusbtnview, setplusbtnview] = useState("none");
  const [minusbtnview, setminusbtnview] = useState("block");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedimg, setselectedimg] = useState("");

  const navigate = useNavigate();

  setTimeout(() => {
    setalert(false);
  }, 4000);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  //table columns

  const columns = [
    // { field: 'id', headerName: 'ID' },
    { field: "name", headerName: "Name", width: 280 },
    { field: "description", headerName: "Description", width: 360 },
    { field: "active", headerName: "Active", width: 100 },
    {
      field: "View",
      headerName: "View",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if (params.row.image !== "") {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                setCatMedia(params.row.image);
                openModal();
              }}
            >
              View
            </Button>
          );
        } else {
          return (
            <Button variant="contained" color="primary" disabled>
              View
            </Button>
          );
        }
      },
    },
    {
      field: "Action",
      headerName: "Edit",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => update(params.row)}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headerName: "Delete",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => edit(params.row)}
            style={{ background: "red" }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  //refresh function call next after update and delete api

  const refresh = () => {
    var config = {
      method: "post",
      url: URL_GETALCATEGORY,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        setIsLoading(false);
        var myData = response.data.data;
        let autoIncrementValue = 1;

        const newArray = myData.map((item) => ({
          ...item,
          autoIncrementKey: autoIncrementValue++,
        }));
        setTableData(newArray);
        // console.log(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //delete user

  const deleteuser = (item) => {
    console.log("fsdfsdf" + item.rowpos);
    var thisRowpos = item.rowpos;

    var data = JSON.stringify({
      ROWPOS: thisRowpos,
    });

    var config = {
      method: "post",
      url: URL_DELETECATEGORY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        refresh();
        setshowpopup(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setSelectedRows([]);
  };

  // edit function

  const edit = (item) => {
    setshowpopup(true);
    setrowpos(item.rowpos);

    // console.log(selectedRows.firstName)
  };

  //update

  const update = (item) => {
    // console.log(item)
    setrowpos(item.rowpos);
    setname(item.name);
    setdescription(item.description);
    setsortorder(item.sortOrder);
    setactive(item.active);
    setupdatebtn(true);
    handleplus(true);

    setshowpopup(false);
    // updateuser()
  };

  const updateCategory = () => {
    var data = JSON.stringify({
      ROWPOS: rowpos,
      Name: name,
      Description: description,
      SortOrder: sortorder,
      Active: active,
    });

    var config = {
      method: "post",
      url: URL_UPDATECATEGORY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.isSuccess) {
          if (selectedimg !== "") {
            addImage(response.data.data.rowpos);
            setselectedimg("");
          }
        } else {
          handleminus();
          setalert(true);
          setalertmsg(`${name} has been updated successfully !  `);
          refresh();
          setselectedimg("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // get all users
  useEffect(() => {
    setIsLoading(true);

    var config = {
      method: "post",
      url: URL_GETALCATEGORY,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setIsLoading(false);
        var myData = response.data.data;
        let autoIncrementValue = 1;
        // console.log(myData)

        const newArray = myData.map((item) => ({
          ...item,
          autoIncrementKey: autoIncrementValue++,
        }));
        setTableData(newArray);
        // console.log(newArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const addCategory = () => {
    var data = JSON.stringify({
      Name: name,
      Description: description,
      SortOrder: 1,
      Active: active,
    });

    var config = {
      method: "post",
      url: URL_ADDCATEGORY,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.isSuccess) {
          console.log(selectedimg);
          if (selectedimg !== "") {
            addImage(response.data.data.rowpos);
            setselectedimg("");
          } else {
            setalert(true);
            setalertmsg(`${name} category has been successfully added`);
            refresh();
            handleminus();
            setname("");
            setdescription("");
            setsortorder("");
            setactive("");
            setselectedimg("");
          }
        } else {
          setalert(true);
          setalertmsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onImageChange = (event) => {
    setselectedimg(event.target.files[0]);
  };

  const addImage = (id) => {
    console.log(selectedimg, id);

    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", selectedimg);
    data.append("Type", "CATEGORY");
    data.append("Id", id);
    data.append("SubType", "CATEGORY_IMAGE");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.isSuccess == true) {
          setalert(true);
          setalertmsg(`${name} category has been successfully added`);
          refresh();
          handleminus();
          setname("");
          setdescription("");
          setsortorder("");
          setactive("");
        } else {
          setalert(true);
          setalertmsg(`SOMETHING WENT WRONG!`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleplus = () => {
    setplusbtnview("block");
    setminusbtnview("none");
    setplusbtn(true);
    setIsDisabled(false);
  };
  const handleminus = () => {
    setplusbtnview("none");
    setminusbtnview("block");
    setplusbtn(false);
  };

  var color = "green";

  const handleOnCellClick = (params) => {
    console.log(params.row);
    setSelectedRows(params.row);
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? (
        <Alert className="top animating" msg={alertmsg} color={color} />
      ) : (
        ""
      )}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Category Master</h5>
        </center>
        <br />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {plusbtn ? (
              <div className="add-new-btn">
                <div className="d-flex space">
                  <div className="mb-3 animatingBottom">
                    <label className="form-label"> Category Name</label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  <div className="mb-3 animatingTop">
                    <label className="form-label"> Category Description</label>
                    <input
                      type="text"
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                      className="form-control small"
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Active</label>
                    <input
                      type="text"
                      value={active}
                      onChange={(e) => setactive(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  {/* <div className="mb-3 animatingBottom">
       <label className="form-label">Sortorder</label>
        <input type="text"
         value={sortorder}
         onChange={(e) => setsortorder(e.target.value)}
          className="form-control small"
          disabled={isDisabled}
          /> 
  
    

      </div> */}
                </div>

                <div className="d-flex space category-div">
                  <div>
                    <label className="form-label">UPLOAD IMAGE</label>
                    <input
                      type="file"
                      onChange={onImageChange}
                      className="filetype form-control small animatingTop"
                      id="group_image"
                    />
                  </div>

                  <div>
                    {updatebtn ? (
                      <Button variant="contained" onClick={updateCategory}>
                        update Category
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={addCategory}>
                        Add Category
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="toggle animatingTop">
              <button
                className={minusbtnview}
                id="plusadduser"
                onClick={handleplus}
              >
                <ion-icon name="add-outline"></ion-icon>
              </button>
              <button className={plusbtnview} onClick={handleminus}>
                <ion-icon name="arrow-up-outline"></ion-icon>
              </button>
            </div>

            <div className="dashtbl">
              <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={7}
                getRowId={(row) => row.autoIncrementKey}
                // onCellClick={handleOnCellClick}
                components={{
                  Toolbar: GridToolbarExport,
                }}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = tableData.filter((row) =>
                    selectedIDs.has(row.autoIncrementKey)
                  );

                  setSelectedRows(selectedRows);
                  console.log(selectedRows);
                  // setfirstname(selectedRows.mcode)
                }}
                {...tableData}
              />
              {showpopup ? (
                <div className="popupbg">
                  <div className="userpopup animatingTop">
                    <p className="cross mb" onClick={() => setshowpopup(false)}>
                      <ion-icon name="close-outline"></ion-icon>
                    </p>
                    {tableData.map(
                      (item) =>
                        item.rowpos === rowpos && (
                          <>
                            <div className="popupdata animatingBottom">
                              <p>
                                Are you Sure To Delete Category <br />
                                <br />
                                <span>{item.name}</span>
                              </p>
                            </div>
                            <Button
                              variant="contained"
                              onClick={() => deleteuser(item)}
                            >
                              delete
                            </Button>
                          </>
                        )
                    )}
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div>
          <h5>
            Category Image
            <button
              onClick={closeModal}
              style={{ border: "1px solid white", float: "right" }}
            >
              ❌
            </button>
          </h5>
          <img
            src={catMedia}
            alt="Category Image"
            style={{ width: "100%" }}
          ></img>
        </div>
      </Modal>
    </>
  );
}

export default Usermanager;
