import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { URL_GETALLORDER, URL_VERIFYTICKET,AllOrders,UPDATE_ORDER_STATUS } from '../api/constants';
import { useNavigate,useLocation  } from 'react-router-dom';
import Navbar from './navbar';
import {Fab, TextareaAutosize} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import { Link } from "react-router-dom";
import noticketimg from "../images/noticket.png";
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import "../components/verifyticket.css"
import Alert from './alert/alert';
import { useParams } from "react-router";

function TicketApproval() {
    const search = useLocation().search;
 const [tableData, setTableData] = useState([]);
    const columns = [
        // { field: 'id', headerName: 'ID' },
        { field: 'chNumber', headerName: 'Order Number', width: 130   },
        { field: 'chDate', headerName: 'Order Date', width: 130 },
        { field: 'balanceAmount', headerName: 'Amt', width: 180 },
        { field: 'remark', headerName: 'Status', width: 180 },
        { field: 'party', headerName: 'USerID', width: 180 },
        { field: 'pcQuantity', headerName: 'Quantity', width: 150 },
         {
                  field: "Action",
                  headerName: "Approve",
                  sortable: false,
                  width: 130,
                  disableClickEventBubbling: true,
                  renderCell: (params) => {
                    if(params.row.remark!=="CONFIRMED"&&(params.row.ticketType!=="ADDON"))
                    {
                        if(params.row.remark==="PENDING")
                        {
                            return (
                                // <Link to={`${(`/dashboard/rojezpay?${btoa(`MCODE=${MCODE}&CHNUMBER=${chNumber}&TICKETID=${TICKETID}`)}`)}`}>
                   <Button  variant="contained" color="primary" onClick={e=>{orderapp(params.row.chNumber,params.row.party,params.row.ticketName,params.row.strTicketID,params.row.pcQuantity,params.row.pcQuantity)}}>Approve</Button>
                    //</Link>
                            );
                        }
                        else{
                            return (
                                <Link to={`${(`/dashboard/rojezpay?${btoa(`MCODE=${params.row.party}&CHNUMBER=${params.row.chNumber}&ticketname=${params.row.ticketName}&ticketid=${params.row.strTicketID}&quantity=${params.row.pcQuantity}&amt=${params.row.balanceAmount}`)}`)}`}>
                   <Button  variant="contained" color="primary">Start Payment</Button>
                    </Link>
                            );
                        }
                   
                    }
                    else{
                        return("");
                    }
           
                  }
                }
        
      ]
const orderapp=(ord_id,MCODE,ticketName,ticketid,Quantity,balanceAmount)=>
{
    var config = {
        method: 'post',
        url: UPDATE_ORDER_STATUS,
        headers: { 
          'Content-Type': 'application/json'
        },
        data:{
            "CHNumber":ord_id,
            "Remark":"PAYMENT_APPROVAL_PENDING"
        }
      };
      
      axios(config).then(function (response) {
  
        if(response.data.isSuccess===true)
        {
          window.location.href=`/dashboard/rojezpay?${btoa(`MCODE=${MCODE}&CHNUMBER=${ord_id}&ticketname=${ticketName}&ticketid=${ticketid}&quantity=${Quantity}&amt=${balanceAmount}`)}`;
        }
    
      })
      .catch(function (error) {
        console.log(error);
       
      });
}
useEffect(()=>{
      var config = {
        method: 'get',
        url: AllOrders,
        headers: { 
          'Content-Type': 'application/json'
        },
      };
      
      axios(config).then(function (response) {
        if(response.data.isSuccess===true)
        {
            setTableData(response.data.data);
            // console.log(response.data.data);
        }
    
      })
      .catch(function (error) {
        console.log(error);
       
      });

  var ticketurl = atob(search.slice(1))

  var mytic = ticketurl?.split("=")
  var Mcode = mytic[1]?.split("&")[0]
  var Chnumber = mytic[2]?.split("&")[0]
  var TicketiD = mytic[3]?.split("&")[0]


},[])

    return(
        <>
            <Navbar />
     <div className='row px-4'>
 
        <div className='col-md-12'style={{height:"500px",marginTop: "2%"}}>
        <DataGrid
      rows={tableData}
      columns={columns}
      getRowId={(row) => row.rowpos}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
        }}
        {...tableData}
      />
        </div>
     </div>


      
        </>
    )

}
export default TicketApproval;