import React, { useState,useEffect } from "react";

import "../leftNav/leftnav.css";
import {Link, NavLink} from "react-router-dom"

import Cookies from "js-cookie";
function Leftnav() {

  var userCode = Cookies.get('orgmcode');
  if(userCode===undefined||userCode===""||userCode===null)
  {
    window.location.href="/";
  }
const [style,setstyle] = useState("block")
const [stylle,setstylle] = useState("block")
const [styllle,setstyllle] = useState("none")
var none = "block"

const toggleleft =(e)=>{
  setstyle("none")
  setstylle("none")
  setstyllle("block")
}
const toggleright =(e)=>{
  setstyle("block")
  setstylle("block")
  setstyllle("none")
  var li = document.getElementsByTagName("li");
  // li.onClick(function(){
  //   toggleleft()
  // })

  for(var i = 0;i<li.length;i++){
      li[i].addEventListener("click", myScript);
  }
  
  function myScript(e){
    var x= document.getElementsByClassName("block")[0]
    console.log("hua")
    x.click()
  }

  
}

const mobilenav =()=>{
  document.getElementsByClassName("block")[0].click()
 
}

var user = Cookies.get('userType')

if(user === "Reporting" || user == "REPORTING"){
  return (
    <>
    
        <h2 className={stylle} onClick={toggleleft}><ion-icon name="close-outline"></ion-icon></h2>
        <h1 className={styllle}  onClick={toggleright}><ion-icon name="ellipsis-vertical-outline"></ion-icon></h1>
    <div className={style}>
      <div className="leftnav "  >
  
        <ul className="animatingBottom">
          <Link to="/dashboard/reports" style={{ textDecoration: 'none'}}>
          <li><ion-icon name="document-attach-outline"></ion-icon><span className="title">reports</span></li>
          </Link>
         
        </ul>
      </div>
      </div>
    </>
  );
}

else if(user == "ticketMaster" || user == "TICKETMASTER"){
  return (
    <>
    
        <h2 className={stylle} onClick={toggleleft}><ion-icon name="close-outline"></ion-icon></h2>
        <h1 className={styllle}  onClick={toggleright}><ion-icon name="ellipsis-vertical-outline"></ion-icon></h1>
    <div className={style}>
      <div className="leftnav "  >
  
        <ul className="animatingBottom"   >
          <Link to="/OnspotBooking" style={{ textDecoration: 'none'}}>
          <li><ion-icon name="checkmark-done-outline"></ion-icon><span className="title">Onspot Booking</span></li>
          </Link>
          <Link to="/BuyTicket" style={{ textDecoration: 'none'}}>
          <li><ion-icon name="ticket-outline"></ion-icon><span className="title">Buy Ticket</span></li>
          </Link>
        </ul>
      </div>
      </div>
    </>
  );
}

else if(user === "eventMaster" || user == "EVENTMASTER"){
  return (
    <>
    
        <h2 className={stylle} onClick={toggleleft}><ion-icon name="close-outline"></ion-icon></h2>
        <h1 className={styllle}  onClick={toggleright}><ion-icon name="ellipsis-vertical-outline"></ion-icon></h1>
    <div className={style}>
      <div className="leftnav "  >
  
        <ul className="animatingBottom"   >
        <Link  to="/dashboard" style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="pricetags-outline"></ion-icon><span   className="title animatingTop">Dashboard</span></li>
          </Link>
        <Link to="/dashboard/itemmaster" style={{ textDecoration: 'none'}}>
          <li onClick={mobilenav}><ion-icon name="file-tray-stacked-outline"></ion-icon><span className="title animatingTop">Manage Events</span></li>
          </Link>
         
        </ul>
      </div>
      </div>
    </>
  );
}

   
  return (
    <>
    
        <h2 className={`${stylle} closenav`} onClick={toggleleft}><ion-icon name="close-outline"></ion-icon></h2>
        <h1 className={`${styllle} opennav`}  onClick={toggleright}><ion-icon name="ellipsis-vertical-outline"></ion-icon></h1>
    <div className={style}>
      <div className="leftnav "  >
  
        <ul className="animatingBottom"   >
          <Link  to="/dashboard" style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="pricetags-outline"></ion-icon><span   className="title animatingTop">Dashboard</span></li>
          </Link>
          {/* <Link to="/dashboard/brandmaster" style={{ textDecoration: 'none'}}>
          <li onClick={mobilenav}><ion-icon name="bag-check-outline"></ion-icon><span className="title animatingTop">Manage Ticket</span></li>
          </Link> */}
          <NavLink to="/dashboard/itemmaster" activeclassname="active" style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="file-tray-stacked-outline"></ion-icon><span className="title animatingTop">Manage Events</span></li>
          </NavLink>
          <NavLink to="/dashboard/ranking"  activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="easel-outline"></ion-icon><span className="title animatingTop"> ORGANISER RANKING</span></li>
          </NavLink>
          <NavLink to="/dashboard/Exhibitorranking"  activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="easel-outline"></ion-icon><span className="title animatingTop">EXHIBITOR RANKING</span></li>
          </NavLink>
          <NavLink to="/dashboard/usermanager" activeclassname="active"   style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="person-add-outline"></ion-icon><span className="title">Manage Users</span></li>
          </NavLink>
          <NavLink to="/dashboard/ordermaster" activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li ><ion-icon name="cart-outline"></ion-icon><span className="title">Manage Order </span></li>
          </NavLink>
          <NavLink to="/dashboard/categorymaster" activeclassname="active" style={{ textDecoration: 'none'}} >
          <li><ion-icon name="options-outline"></ion-icon><span className="title">category </span></li>
          </NavLink>
          <NavLink to="/dashboard/supportsticket" activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li><ion-icon name="checkmark-done-outline"></ion-icon><span className="title">support ticket</span></li>
          </NavLink>
          {/* <Link to="/dashboard/offers" style={{ textDecoration: 'none'}}>
          <li><ion-icon name="sparkles-outline"></ion-icon><span className="title">offers</span></li>
          </Link> */}
          <NavLink to="/dashboard/reports"  activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li><ion-icon name="document-attach-outline"></ion-icon><span className="title">reports</span></li>
          </NavLink>
          <NavLink to="/BuyTicket"  activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li><ion-icon name="ticket-outline"></ion-icon><span className="title">Buy Ticket</span></li>
          </NavLink>
          <NavLink to="/OnspotBooking" activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li><ion-icon name="easel-outline"></ion-icon><span className="title">ONSPOT BOOKING</span></li>
          </NavLink> 
          <NavLink to="/banner" activeclassname="active"  style={{ textDecoration: 'none'}}>
          <li><ion-icon name="easel-outline"></ion-icon><span className="title">BANNER</span></li>
          </NavLink>

        </ul>
      </div>
      </div>
    </>
  );
}

export default Leftnav;
