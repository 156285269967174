import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from './navbar';

function Notfound() {
  return (
    <>
    <Navbar />
    <div className='notfound'>
           <img className='' src="https://cdn.dribbble.com/users/2771385/screenshots/16267270/media/4d6fd1687943c6f90e7cb93111050c65.gif" />
     
    </div>
    <Link to="/dashboard" >
    <button className='backtohome' ><ion-icon name="arrow-back-outline"></ion-icon></button>
    </Link>
    </>
  )
}

export default Notfound;