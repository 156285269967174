
//export const API_URL = "http://localhost:6595";
//const  API_URL =  "https://192.168.1.79:45460/";
//const API_URL = "https://dev-api.accex.saitec.in";
const API_URL = "https://accex-api.saitec.in";


export const URL_LOGIN = `${API_URL}/api/Account/login`;

//DASHBOARD 

export const URL_ALLEVENTS = `${API_URL}/api/Event/getEventByStatus`;
export const GETALL_MUSEUM = `${API_URL}/api/Museum/GellAllMuseumOrder`;





export const URL_APPROVEUSER = `${API_URL}/api/Event/updateEventStatus`;

export const URL_GETALLHOMEBANNER = `${API_URL}/api/Event/getHomeMedia`;

//USER MANAGER

export const URL_GETALLUSER = `${API_URL}/api/Account/getAllUsers`;

export const URL_UPDATEUSER = `${API_URL}/api/Account/updateUser`;

export const URL_DELETEUSER = `${API_URL}/api/Account/deleteUser`;

export const URL_CREATE_USER = `${API_URL}/api/Account/createUser`;

export const URL_GETEVENTS_FOR_TICKETMASTER = `${API_URL}/api/Account/GetEventsForTicketMaster`;

// MANAGE TICKET

export const URL_GETALLTICKET = `${API_URL}/api/Ticket/getAllTickets`;

export const URL_CREATETICKET = `${API_URL}/api/Ticket/CreateTicket`;

export const URL_UPDATETICKET = `${API_URL}/api/Ticket/updateTicket`;

export const URL_DELETETICKET = `${API_URL}/api/Ticket/deleteTicket`;

export const URL_VERIFYTICKET = `${API_URL}/api/Order/verifyTicket`;

export const URL_GETtCKTbyID = `${API_URL}/api/Ticket/getTicketByTicketID`;

//MANAGE EVENTS

export const URL_UPLOADIMAGE = `${API_URL}/api/ImageUpload/uploadImage`;

export const URL_UPLOADProfileImg = `${API_URL}/api/ImageUpload/uploadProfileImg`;

export const URL_CREATEEVENTS = `${API_URL}/api/Event/createNewEvent`;

export const URL_DELETEEVENTS = `${API_URL}/api/Event/deleteEvent`;

export const URL_UPDATE_ORGANISER_EVENT = `${API_URL}/api/Event/updateOrganisorForEvent`;

export const URL_UPDATEEVENTS = `${API_URL}/api/Event/UpdateEventStatus`; // for approve or unapprove

export const URL_UPDATEEVENT = `${API_URL}/api/Event/updateEvent`; // for approve or unapprove

export const URL_GETORGDATA = `${API_URL}/api/Event/getOrgData`; // for dropdown in events (mcode)

export const URL_GETUNIQUEEVENT = `${API_URL}/api/Event/getEventByID`; // for dropdown in events (mcode)

export const URL_DELETEBANNERBYID = `${API_URL}/api/Event/DelelteHomeMediaById`;

//orders

export const URL_GETALLORDER = `${API_URL}/api/Order/getOrders`; // get all orders

export const URL_GETALLORDER_BY_EVENTID = `${API_URL}/api/Order/getOrdersforEvent`; // get all orders by event id

export const URL_GETORDER_BY_ORDERNUMBER = `${API_URL}/api/Order/getOrdersbyOrderNo`; // GET ORDER BY ORDR NUMBER

//category

export const URL_ADDCATEGORY = `${API_URL}/api/Category/addCategory`; // Add Item Category

export const URL_GETALCATEGORY = `${API_URL}/api/Category/getCategory`; // get Item Category

export const URL_UPDATECATEGORY = `${API_URL}/api/Category/updateCategory`; // update Item Category

export const URL_DELETECATEGORY = `${API_URL}/api/Category/deleteCategory`; // delete Item Category

//support ticket

export const URL_CREATESUPPORT = `${API_URL}/api/Ticket/CreateSupportTicket`;

export const URL_GETALLSUPPORT = `${API_URL}/api/Ticket/getAllSupportTickets`;

export const URL_GETREPORTS = `${API_URL}/api/Event/getReport`;

export const URL_GETALLBANNERS = `${API_URL}/api/Event/getAllBanners`;

//ranking

export const URL_CREATE_RANKING = `${API_URL}/api/Account/createSortOrder`;

export const URL_GETALLORGANISOR_WITH_RANKING = `${API_URL}/api/Account/getHomeOrganizer`;

export const URL_GETALLEXHIBITOR_WITH_RANKING = `${API_URL}/api/Account/getHomeExhibitor`;

export const URL_GETALLEXHIBITOR = `${API_URL}/api/Account/getAllExhibitor`;

export const URL_GETALLORGANISOR_FOR_DROPDOWN = `${API_URL}/api/Event/getOrgData`;

// report

export const URL_GETEVENT_WISE_REPORT = `${API_URL}/api/Order/getEventWiseReport`;

export const URL_GET_CLIENT_WISE_REPORT = `${API_URL}/api/Order/getReportByClient`;
//all Odres
export const AllOrders = `${API_URL}/api/Order/getAllOrders`;

export const URL_REGISTRATION_BY_QR = `${API_URL}/api/Ticket/getRegistrationByRegCode`;

export const URL_GET_QR_PDF = `${API_URL}/api/Ticket/createTicketQRPDF`;

export const URL_EDIT_RANKING = `${API_URL}/api/Account/EditHomeOrganizer`;

export const URL_DELETE_RANKING = `${API_URL}/api/Account/DeleteHomeOrganizer`;

export const URL_GET_ORDER_TICKETID = `${API_URL}/api/Order/getOrderByTicketID`;

export const URL_GET_REPORT_BY_CUSTOMER = `${API_URL}/api/Order/getReportByCustomer`;

export const URL_GET_COMPANY_DETAILS = `${API_URL}/api/Account/getCompanyDetail`;

export const URL_GET_REGIDTRATION_QR = `${API_URL}/api/Ticket/getRegistrationQR`;

// onspot buy tickcet

export const URL_CREATE_BUY_TICKET = `${API_URL}/api/Order/BuyTicket`;

export const URL_SAVE_COMPANY_NAME_TODB = `${API_URL}/api/Order/SaveOnspotCompanyName`;

export const URL_CREATE_ORDER_QR = `${API_URL}/api/Order/generateQRCode`; // crete qr

export const URL_GET_ALL_REGISTRATION = `${API_URL}/api/Ticket/getAllRegistration`; // get all registration

export const URL_CREATE_REGISTRATION = `${API_URL}/api/Ticket/submitRegistrationForm`;

export const URL_CREATE_REGISTRATION_QR = `${API_URL}/api/Ticket/generateQRCode`;

export const URL_GET_REGISTRATION_COUNT = `${API_URL}/api/Ticket/getRequiredFields`;

export const URL_GET_PROFILE_PHOTO = `${API_URL}/api/Event/GetProfilePhoto`;

//ORDER STATUS UPDATE
export const UPDATE_ORDER_STATUS = `${API_URL}/api/Order/orderStatusUpdate`;
//user exist by mcode
export const User_exist = `${API_URL}/api/Account/checkExistingUserByMcode`;
//user exist by mcode
export const ticket_exist = `${API_URL}/api/Ticket/checkExistingTicketByTicketID`;

//create order
export const ORDER_CREATE = `${API_URL}/api/Order/createOrder`;
//PAYMENT VARIFICATION
export const VERITY_PAYMENT = `${API_URL}/api/Order/verifyOrderPayment`;

export const SHARE_VIA_EMAIL = `${API_URL}/api/Ticket/ShareTickettoMail`;

//------------GET COMPANY DETAILS BY MCODE ----------------------
export const GET_COMPANY_DETAILS_BY_MCODE = `${API_URL}/api/Account/getHomeOrganizerByMcode`;
