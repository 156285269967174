import React from "react";
import axios from "axios";
import DataGrids from "../../table/DataGrid";
import Leftnav from "../leftNav/Leftnav";
import Navbar from "../navbar";
import "./usermanager.css";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Alert from "../alert/alert";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  getAllUsers,
  updateUser,
  GET_COMPANY_DETAILS_BY_MCODE,
  URL_ALLEVENTS,
  URL_CREATE_USER,
  URL_DELETEUSER,
  URL_GETALLUSER,
  URL_GET_COMPANY_DETAILS,
  URL_UPDATEUSER,
} from "../../api/constants";
import Loader from "../Loader";
// import { validator  } from 'validator';
// import SearchFilter from 'react-filter-search'
import Select from "react-select";

function Usermanager() {
  const [rowpos, setrowpos] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [mobile, setmobile] = useState("");
  const [username, setusername] = useState("");
  const [Password, setPassword] = useState("");
  const [usertype, setusertype] = useState("");
  const [occupation, setoccupation] = useState("");
  const [mcode, setmcode] = useState("");
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [updatebtn, setupdatebtn] = useState(false);
  const [updatebutton, setupdatebutton] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [plusbtn, setplusbtn] = useState(false);
  const [plusbtnview, setplusbtnview] = useState("none");
  const [minusbtnview, setminusbtnview] = useState("block");
  const [isDisabled, setIsDisabled] = useState(false);
  const [comapanypopup, setcomapanypopup] = useState(false);
  const [companyArray, setcompanyArray] = useState({});
  const [allEvent, setallEvent] = useState([]);
  const [eventlist, seteventlist] = useState([]);
  const [multievent, setmultievent] = useState("");
  const [isEventShow, setisEventShow] = useState(false);
  const [sendtoadmin, setsendtoadmin] = useState(false);
  const [tblheight, settblheight] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  setTimeout(() => {
    setalert(false);
  }, 4000);

  var list = [];
  function handleSelect(data) {
    setSelectedOptions(data);

    data.map((i) => {
      list.push(i.value);
      console.log(list);
      seteventlist(list);
    });
  }

  //table columns

  const columns = [
    // { field: 'id', headerName: 'ID' },
    { field: "firstName", headerName: "First Name", width: 130 },
    { field: "lastName", headerName: "Last Name", width: 130 },
    { field: "mail", headerName: "Email", width: 180 },
    { field: "addresss", headerName: "Address", width: 180 },
    { field: "password", headerName: "Password", width: 180 },
    { field: "mobile", headerName: "Mobile", width: 150 },
    { field: "userType", headerName: "User Type", width: 150 },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.status === "BLOCKED" ? (
          <p style={{ color: "red" }}>BLOCKED</p>
        ) : (
          "CONFIRMED"
        );
      },
    },
    { field: "strCompanyName", headerName: "Company Name", width: 150 },
    {
      field: "getCompanyDetail",
      headerName: "Company Details",
      sortable: false,
      width: 170,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.userType === "ORGANISER" ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => companyDetail(params.row.mcode)}
            style={{ background: "green" }}
          >
            Company Details
          </Button>
        ) : (
          ""
        );
      },
    },

    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.userType === "VISITOR" ? (
          ""
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => update(params.row)}
          >
            {" "}
            Edit
          </Button>
        );
      },
    },
    {
      field: "Delete",
      headerName: "Delete",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => edit(params.row)}
            style={{ background: "red" }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const OpenBrocure = (a) => {
    window.open(a, "_blank");
  };

  const companyDetail = (userCode) => {
    setcomapanypopup(true);
    var config = {
      method: "POST",
      url: GET_COMPANY_DETAILS_BY_MCODE,
      header: "Content-Type: application/json",
      data: {
        Mcode: userCode,
      },
    };

    axios(config)
      .then(function (response) {
        setcompanyArray(response.data.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //

  //refresh function call next after update and delete api

  const refresh = () => {
    var config = {
      method: "get",
      url: URL_GETALLUSER,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setTableData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //delete user

  const deleteuser = (item) => {
    var rowpos = item.rowpos;
    var data = JSON.stringify({
      ROWPOS: rowpos,
    });
    setrowpos(item.rowpos);

    var config = {
      method: "post",
      url: URL_DELETEUSER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        refresh();
        setalert(true);
        setalertmsg(`✔ ${item.firstName}  has been deleted successfully !`);
        setshowpopup(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // edit function

  const edit = (item) => {
    setshowpopup(true);
    setrowpos(item.rowpos);

    // console.log(selectedRows.firstName)
    console.log(firstname);
  };

  //update

  var arr = [];
  const update = (item) => {
    console.log(item);
    // debugger;

    setsendtoadmin(item.next_change);

    // if(item.next_change){
    //   document.querySelector('.sendotpadmin').checked = true
    // }

    setplusbtnview("block");
    setminusbtnview("none");
    // console.log(item)
    setIsDisabled(true);
    setupdatebtn(true);
    setplusbtn(true);
    // console.log(item)
    setfirstname(item.firstName);
    setlastname(item.lastName);

    setmobile(item.mobile);
    setusername(item.mail);
    setmcode(item.mcode);
    setPassword(item.password);
    setusertype(item.userType);
    // console.log(item.userType)
    setoccupation(item.occupation);
    setrowpos(item.rowpos);
    setmultievent(item.ticketMasterEvents);
    // console.log(eventlist)
    // console.log(item.ticketMasterEvents)

    arr.push(item.ticketMasterEvents);
    // console.log(arr)

    // console.log(selectedOptions)

    setshowpopup(false);

    // updateuser()
  };

  const updateuser = () => {
    console.log("fine  update button clicked  !");
    var data = JSON.stringify({
      ROWPOS: rowpos,
      userName: username,
      password: Password,
      firstName: firstname,
      lastName: lastname,
      mobile: mobile,
      userType: usertype,
      occupation: occupation,
      Mcode: mcode,
      next_change: sendtoadmin,
    });

    var config = {
      method: "post",
      url: URL_UPDATEUSER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        refresh();

        handleminus();
        setalert(true);
        setalertmsg(`✔ ${firstname}  has been Updated successfully !`);
        console.log(usertype);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // select options
  const options = [
    { value: "1", label: "ADMIN" },
    { value: "2", label: "STAFF" },
    { value: "3", label: "USER" },
  ];

  var onlyUSers = [];
  // get all users
  useEffect(() => {
    setIsLoading(true);

    var config = {
      method: "get",
      url: URL_GETALLUSER,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);

        for (var i = 0; i < response.data.length; i++) {
          if (response.data[i].eversion != "ONSPOT_REGISTRATION") {
            onlyUSers.push(response.data[i]);
          }
        }
        setTableData(onlyUSers);

        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    // ----

    localStorage.getItem("login", false);
    // const isLogin= localStorage.getItem("login");
    // if(isLogin==="false")
    // {
    //   navigate("/")
    // }

    // --------------------
    var approvedEvent = [];
    //GET ONSPOT ORDRES
    var data = JSON.stringify({
      status: "ALL",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        var mydata = response.data.data;

        for (var i = 0; i < mydata.length; i++) {
          var today = new Date();
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].status === "APPROVED" && today < EndDate) {
            approvedEvent.push(mydata[i]);
          }
        }

        setallEvent(approvedEvent);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function findValidEvents(mydata) {
    var approvedEvent = [];
    var today = new Date();

    // Binary search to find the first valid event
    var low = 0;
    var high = mydata.length - 1;
    while (low <= high) {
      var mid = Math.floor((low + high) / 2);
      var EndDate = new Date(mydata[mid].exhibitionEndDate);

      if (today < EndDate) {
        high = mid - 1;
      } else {
        low = mid + 1;
      }
    }

    // Iterate from the first valid event and collect approved events
    for (var i = low; i < mydata.length; i++) {
      var EndDate = new Date(mydata[i].exhibitionEndDate);
      if (mydata[i].status === "APPROVED" && today < EndDate) {
        approvedEvent.push(mydata[i]);
      }
    }

    return approvedEvent;
  }

  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  const adduser = () => {
    console.log(sendtoadmin);

    console.log(eventlist.toString());

    if (firstname == "") {
      setalert(true);
      setalertmsg("firstname can not be blank !");
    } else if (lastname == "") {
      setalert(true);
      setalertmsg("lastname can not be blank !");
    } else if (mobile == "") {
      setalert(true);
      setalertmsg("mobile No can not be blank !");
    } else if (mobile.length > 10 || mobile.length < 9) {
      setmobile("");
      setalert(true);
      setalertmsg("mobile No must be at least 10 numbers !");
    } else if (username == "") {
      setalert(true);
      setalertmsg("username can not be blank !");
    } else if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(username)
    ) {
      setusername("");
      console.log("true");
      setalert(true);
      setalertmsg("Please Enter a Valid Email !");
    } else if (Password == "") {
      setalert(true);
      setalertmsg("Password can not be blank !");
    } else {
      var data = JSON.stringify({
        mail: username,
        Mobile: mobile,
        FirstName: firstname,
        LastName: lastname,
        userType: usertype,
        Occupation: occupation,
        Password: Password,
        userName: username,
        status: "CONFIRMED",
        ticketMasterEvents: eventlist.toString(),
        next_change: sendtoadmin,
      });

      var config = {
        method: "post",
        url: URL_CREATE_USER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          if (response.data.IsSuccess === true) {
            handleminus();
            setalert(true);
            setalertmsg(`✔ ${firstname}  has been created successfully !`);
            setTimeout(() => {
              window.location.reload();
            }, 4000);
            // --------------------------end
          } else {
            handleminus();
            setalert(true);
            setalertmsg(`❌ ${response.data.Message} !`);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleplus = () => {
    setplusbtnview("block");
    setminusbtnview("none");
    setplusbtn(true);
    setIsDisabled(false);
    settblheight(50);
  };
  const handleminus = () => {
    setplusbtnview("none");
    setminusbtnview("block");
    setplusbtn(false);
    settblheight(0);
  };

  const closecomapnypopup = () => {
    setcomapanypopup(false);
  };

  const handleusertypSelect = (e) => {
    setusertype(e.target.value);
    console.log(e.target.value);
    if (e.target.value !== "TICKETMASTER") {
      setisEventShow(false);
    } else {
      setisEventShow(true);
    }
  };

  const handlecheck = (e) => {
    console.log(e.target.checked);
    setsendtoadmin(e.target.checked);
  };

  var color = "green";
  console.log(tableData);
  console.log(rowpos);

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? (
        <Alert className="top animating" msg={alertmsg} color={color} />
      ) : (
        ""
      )}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Manage users</h5>
        </center>
        <br />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {plusbtn ? (
              <div className="add-new-btn">
                <form className="d-flex space">
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Firstname</label>
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  <div className="mb-3 animatingBottom">
                    <label className="form-label">Lastname</label>
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Mobile</label>

                    <input
                      type="number"
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                </form>
                <div className="d-flex space">
                  <div className="mb-3 animatingBottom">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setusername(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Password</label>
                    <input
                      type="text"
                      value={Password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control small"
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="mb-3 animatingBottom">
                    <label className="form-label">User type</label>
                    <br />
                    <select
                      className="selectinp small"
                      value={usertype}
                      onChange={(e) => handleusertypSelect(e)}
                    >
                      <option value="ADMIN">ADMIN</option>
                      <option value="ORGANISER">ORGANISER</option>
                      {/* <option value="user" >USER</option> */}
                      <option value="EVENTMASTER">EVENT MASTER</option>
                      <option value="TICKETMASTER">TICKET MASTER</option>
                      <option value="EXHIBITOR">EXHIBITOR</option>
                      <option value="REPORTING">REPORTING</option>
                    </select>

                    {/* --------------------------- */}
                  </div>
                </div>
                <div className="d-flex manageusersmobilediv space">
                  {isEventShow ? (
                    <>
                      <div className="mb-3 animatingTop">
                        <label className="form-label">Select Events</label>
                        <Select
                          placeholder="Select Events"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isSearchable={true}
                          isMulti
                          options={allEvent.map((item) => ({
                            value: item.rowpos,
                            label: item.name,
                          }))}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="animatingTop">
                    <div className="form-check form-switch ps-0">
                      <input
                        className="form-check-input ms-auto mt-1 sendotpadmin"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={sendtoadmin}
                        onChange={(e) => setsendtoadmin(e.target.checked)}
                      />
                      <label
                        className="form-check-label ms-2"
                        for="flexSwitchCheckDefault"
                      >
                        Send Otp To Admin
                      </label>
                    </div>
                  </div>
                  <div>
                    {updatebtn ? (
                      <Button variant="contained" onClick={updateuser}>
                        update user
                      </Button>
                    ) : (
                      <Button variant="contained" onClick={adduser}>
                        Add User
                      </Button>
                    )}
                  </div>
                </div>

                {/* ------------------------------- */}
              </div>
            ) : (
              ""
            )}

            <div className="toggle animatingTop add-new">
              <button
                className={minusbtnview}
                id="plusadduser"
                onClick={handleplus}
              >
                <ion-icon name="add-outline"></ion-icon>
              </button>
              <button className={plusbtnview} onClick={handleminus}>
                <ion-icon name="arrow-up-outline"></ion-icon>
              </button>
            </div>

            <div className="animatingBottom ordertbl dashtbl">
              <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.rowpos}
                components={{
                  Toolbar: GridToolbarExport,
                }}
                // checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = tableData.filter((row) =>
                    selectedIDs.has(row.rowpos)
                  );

                  setSelectedRows(selectedRows);
                  console.log(selectedRows);
                  // setfirstname(selectedRows.mcode)
                }}
                {...tableData}
              />
              {showpopup ? (
                <div className="popupbg">
                  <div className="userpopup animatingTop">
                    <p className="cross mb" onClick={() => setshowpopup(false)}>
                      <ion-icon name="close-outline"></ion-icon>
                    </p>
                    {tableData.map(
                      (item) =>
                        item.rowpos === rowpos && (
                          <>
                            <div className="popupdata animatingBottom">
                              <p>
                                {" "}
                                <span>
                                  Are you sure To delete <br />
                                  <br />
                                </span>
                                {item.firstName}
                              </p>
                            </div>
                            <Button
                              variant="contained"
                              onClick={() => deleteuser(item)}
                            >
                              delete
                            </Button>
                          </>
                        )
                    )}
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </>
        )}

        {comapanypopup ? (
          <>
            <div className="userpopup animatingTop scllCls">
              <p className="cross mb" onClick={closecomapnypopup}>
                <ion-icon name="close-outline"></ion-icon>
              </p>
              <br />
              <div className="popupdata animatingBottom popoupalign">
                <p>
                  {" "}
                  <span>
                    <img
                      src={companyArray.strLogo}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                  <span className="span" style={{ padding: "12px" }}>
                    {companyArray.strCompanyName}
                  </span>
                </p>
                <p>
                  {" "}
                  <span>Email: </span>
                  <span className="span">{companyArray.mail}</span>
                </p>
                {/* <p> <span>GST Number: </span><span className="span">{companyArray.strGSTNumber}</span></p> */}
                {/* <p> <span>About: </span><span className="span">{companyArray.aboutMe}</span></p>
        <p> <span>Address: </span><span className="span">{companyArray.strAddress}</span></p> */}
                {/* <p> <span>Mobile: </span><span className="span">{companyArray.telephone}</span></p> */}
                {/* <p> <span>Fax: </span><span className="span">{companyArray.fax}</span></p> */}
                {/* <p> <span>Website: </span><span className="span">{companyArray.strCompanyName}</span></p> */}
                {/* <p> <span>Globle Pass: </span><span className="span">{companyArray.globalPass}</span></p> */}
                <p>
                  {" "}
                  <span>Address: </span>
                  <span className="span">{companyArray.factAddress}</span>
                </p>
                <p>
                  {" "}
                  <span>Telephone: </span>
                  <span className="span">{companyArray.factTelephone}</span>
                </p>
                {/* <p> <span>Fax: </span><span className="span">{companyArray.factFax}</span></p> */}
                {/* <p> <span>Email: </span><span className="span">{companyArray.factEmail}</span></p> */}
                <p>
                  {" "}
                  <span>Website: </span>
                  <span className="span">{companyArray.factWebsite}</span>
                </p>
                <h6 style={{ textAlign: "center", fontSize: "18px" }}>
                  Contact Person Details
                </h6>
                <p>
                  {" "}
                  <span>Personal Name: </span>
                  <span className="span">{companyArray.persName}</span>
                </p>
                <p>
                  {" "}
                  <span>Personal Mail: </span>
                  <span className="span">{companyArray.persEmail}</span>
                </p>
                <p>
                  {" "}
                  <span>Personal Phone: </span>
                  <span className="span">{companyArray.persPhone}</span>
                </p>
                <p>
                  {" "}
                  <span>Designation: </span>
                  <span className="span">{companyArray.designation}</span>
                </p>
                <p>
                  {" "}
                  <span>Brocure: </span>
                  <span className="span">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => OpenBrocure(companyArray.profile_PDF)}
                    >
                      brocure_PDF
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Usermanager;
