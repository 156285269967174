import React from 'react'
import axios from "axios"
import Select from 'react-select';
import DataGrids from '../../table/DataGrid';
import Leftnav from '../leftNav/Leftnav'
import Navbar from '../navbar';
import "../usermanager/usermanager.css"
import Button from '@mui/material/Button';
import { useState,useEffect } from 'react';
import Alert from '../alert/alert';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from '@mui/material/TextField';
import { URL_CREATETICKET, URL_DELETETICKET, URL_GETALLTICKET, URL_UPDATETICKET } from '../../api/constants';






function Brandmaster() {

const [rowpos,setrowpos]= useState("")
  const [ticketname,setticketname]= useState("")
  const [tickettype,settickettype]= useState("")
  const [ticketid,setticketid]= useState("")
  const [eventid,seteventid]= useState("")
  const [maxcount,setmaxcount]= useState("")
  const [price,setprice]= useState("")
  const[gstpercent,setgstpercent] = useState("")
  const [bookingend,setbookingend]= useState("")
  const[eversion,seteversion] = useState("")
  const [alert,setalert] = useState(false)
  const [alertmsg,setalertmsg] = useState("")
  const [tableData, setTableData] = useState([])
const [showpopup,setshowpopup] = useState(false)
const [updatebtn,setupdatebtn] = useState(false)
const [selectedRows, setSelectedRows] =useState([]);
const [plusbtn,setplusbtn] = useState(false)
const [plusbtnview,setplusbtnview] = useState("none")
const [minusbtnview,setminusbtnview] = useState("block")


setTimeout(() => {
  setalert(false)
}, 4000);


  
//table columns

const columns = [
  // { field: 'id', headerName: 'ID' },
  { field: 'ticketName', headerName: 'Ticket Name', width: 100   },
  { field: 'ticketType', headerName: 'ticketType', width: 100 },
  { field: 'ticketId', headerName: 'Ticket Id', width: 150 },
  { field: 'booking_Start_Date', headerName: 'Start Date', width: 180 },
  { field: 'booking_End_Date', headerName: 'End Date', width: 150 },
  { field: 'price', headerName: 'Price', width: 150 },
          {
            field: "Action",  
            headerName: "Edit",
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
            renderCell: () => {
              return (
                <Button  variant="contained" color="primary" onClick={edit} >view Detail</Button>
              );
            }
          }
          // {
          //   field: "delete",
          //   headerName: "Delete",
          //   sortable: false,
          //   width: 80,
          //   disableClickEventBubbling: true,
          //   renderCell: () => {
          //     return (
          //       <Button variant="contained" color="secondary"  > <DeleteIcon /> </Button>
          //     );
          //   }
          // }
]

//delete user

const deleteuser =(item)=>{
  setshowpopup(false)
  var rowpos = item.rowpos
  var data = JSON.stringify({
    "ROWPOS": rowpos
  });
  
  var config = {
    method: 'post',
    url: URL_DELETETICKET,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log(response.data);
    setalert(true)
   setalertmsg(`✔ ${ticketname} has been deleted successfully !`)
    refresh()
  })
  .catch(function (error) {
    console.log(error);
  });
  
  
}

// edit function

const edit =()=>{
  setshowpopup(true)

  // console.log(selectedRows.firstName)

  
}

//update

const update =(item)=>{
  setrowpos(item.rowpos)
  setupdatebtn(true)
  setplusbtn(true)
  console.log("item:"+item)
  setticketname(item.ticketName)
  settickettype(item.ticketType)
  setticketid(item.ticketId)
  seteventid(item.eventId)
  setmaxcount(item.maxCount)
  setprice(item.price)
  setgstpercent(item.gsT_PERCENT)
  setbookingend(item.booking_End_Date)
  seteversion(item.eversion)

  setshowpopup(false)
  // updateuser()
}


const updateuser = ()=>{
 
var data = JSON.stringify({
  "ROWPOS": rowpos,
  "TicketId": ticketid,
  "EventId": eventid,
  "TicketType": tickettype,
  "TicketName": ticketname,
  "MaxCount": maxcount,
  "Price": price,
  "GST_PERCENT": gstpercent,
  "Booking_End_Date": bookingend,
  "Eversion": eversion
});

var config = {
  method: 'post',
  url: URL_UPDATETICKET,
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data);
  setalert(true)
   setalertmsg(`✔ ${ticketname}  has been Updated successfully !`)
  refresh();
  handleminus();

})
.catch(function (error) {
  console.log(error);
});


  

}



//select options
  const options = [
    { value:"1", label: 'ADMIN' },
    { value:"2", label: 'STAFF' },
    { value:"3", label: 'USER' },
  ]

  //refresh function 
  const refresh =()=>{
    var config = {
      method: 'get',
      url: URL_GETALLTICKET,
      headers: { }
    };
    axios(config)
    .then(function (response) {
      console.log(response.data);
      setTableData(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }




  // get all users 
useEffect(()=>{
  console.log("i m fine ")
  var config = {
    method: 'get',
    url: URL_GETALLTICKET,
    headers: { }
  };
  axios(config)
  .then(function (response) {
    console.log(response.data);
    setTableData(response.data)
  })
  .catch(function (error) {
    console.log(error);
  });


},[])


const adduser = ()=>{
  
  if(ticketname == ""){
    setalert(true)
    setalertmsg("ticketname can not be blank !")
   }
   else if(tickettype == ""){
    setalert(true)
    setalertmsg("tickettype can not be blank !")
   }
   else if(ticketid == ""){
    setalert(true)
    setalertmsg("ticketid  can not be blank !")
   }
   else if(eventid == ""){
    setalert(true)
    setalertmsg("eventid can not be blank !")
   }
   else if(maxcount == ""){
    setalert(true)
    setalertmsg("maxcount can not be blank !")
   }
   else if(price == ""){
    setalert(true)
    setalertmsg("price can not be blank !")
   }  
   
   var data = JSON.stringify({
    "TicketId": ticketid,
    "EventId": eventid,
    "TicketType": tickettype,
    "TicketName": ticketname,
    "MaxCount": maxcount,
    "Price": price,
    "GST_PERCENT": gstpercent,
    "Booking_End_Date":bookingend ,
    "Eversion": eversion
  });
  
  var config = {
    method: 'post',
    url: URL_CREATETICKET,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    console.log((response.data));
    handleminus()
   setalert(true)
   setalertmsg(`✔ ${ticketname}  has been added successfully !`)
    refresh()
  })
  .catch(function (error) {
    console.log(error);
  });
  

}



const handleplus =()=>{
  setplusbtnview("block")
  setminusbtnview("none")
  setplusbtn(true)
}
const handleminus =()=>{
  setplusbtnview("none")
  setminusbtnview("block")
  setplusbtn(false)
}

  var color = "red";
  return (
    <>
          
      <Navbar   />
    <Leftnav />
    {alert ?  <Alert className="top" msg={alertmsg} color={color} />:""}
    <div className='main'>
   
  {
    plusbtn ?
    <div className="">
    <form className="d-flex space" >
      <div className="mb-3 animatingTop">
        <label className="form-label">Ticket Name</label>
        <input type="text"
         value={ticketname}
         onChange={(e) => setticketname(e.target.value)}
          className="form-control small" />
        
      </div>
      <div className="mb-3 animatingBottom">
        <label className="form-label">Ticket Type</label>
        <input type="text"
         value={tickettype}
         onChange={(e) => settickettype(e.target.value)}
          className="form-control small" />
      
      </div>
      <div className="mb-3 animatingTop">
      <label className="form-label">Ticket Id</label>
     
     <input type="text"
     value={ticketid}
     onChange={(e) => setticketid(e.target.value)}
      className="form-control small" />
      </div>
     
    </form>
    <div className='d-flex space'>
    <div className="mb-3 animatingBottom">
        <label className="form-label">Event ID</label>
        <input type="text"
         value={eventid}
         onChange={(e) => seteventid(e.target.value)}
          className="form-control small" />
        
      </div>
      <div className="mb-3 animatingTop">
        <label className="form-label">Max Count</label>
        <input type="text"
         value={maxcount}
         onChange={(e) => setmaxcount(e.target.value)}
          className="form-control small" />
        
      </div>
      <div className="mb-3 animatingBottom">
        <label className="form-label">Price</label>
        <input type="text"
         value={price}
         onChange={(e) => setprice(e.target.value)}
          className="form-control small" />
        
      </div>
      
    </div>
    <div className='d-flex space'>
    <div className="mb-3 animatingTop">
        <label className="form-label">GST Percent</label>
        <input type="text"
         value={gstpercent}
         onChange={(e) => setgstpercent(e.target.value)}
          className="form-control small" />
        
      </div>
      <div className="mb-3 animatingBottom">
        <label className="form-label">Booking End Date</label>
        <input type="text"
         value={bookingend}
         onChange={(e) => setbookingend(e.target.value)}
          className="form-control small" />
        
      </div>
      <div className="mb-3 animatingTop">
        <label className="form-label">Eversion</label>
        <input type="text"
         value={eversion}
         onChange={(e) => seteversion(e.target.value)}
          className="form-control small" />
        
      </div>
      </div>
    <div className="mb-3 mtop animatingBottom">
      <Button style={{marginLeft:"40px"}}  variant="contained" onClick={adduser}>Add Ticket</Button> 
      {
        updatebtn ? 
        <Button style={{marginLeft:"40px"}}  variant="contained" onClick={updateuser}>Update Ticket</Button>                   
       :""
      }
      </div>
    </div>    : ""
  }
      
      <div className='toggle animatingTop'>  
      <button className={minusbtnview} id="plusadduser" onClick={handleplus}><ion-icon name="add-outline"></ion-icon></button>
      <button className={plusbtnview} onClick={handleminus}><ion-icon name="arrow-up-outline"></ion-icon></button>
      </div>
        <div className='table animatingBottom'>
        <DataGrid
      rows={tableData}
      columns={columns}
      getRowId={(row) => row.rowpos}
      pageSize={10}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = tableData.filter((row) =>
            selectedIDs.has(row.rowpos),
         
          );

          setSelectedRows(selectedRows);
          console.log(selectedRows)
          // setfirstname(selectedRows.mcode)

        }}
        {...tableData}
      />
        </div>
    {
      showpopup ? 
      <div className='userpopup animatingTop'>
         <p className='cross mb' onClick={()=>setshowpopup(false)}><ion-icon name="close-outline"></ion-icon></p>
      {
        
        selectedRows.map((item)=>(
     <>
        
        <div className="popupdata animatingBottom">
        <p> <span>Ticket Name:&nbsp;&nbsp;</span>{item.ticketName}</p>
         <p><span>Ticket Type:&nbsp;&nbsp;</span>{item.ticketType}</p>
         <p><span>Ticket Id:&nbsp;&nbsp;</span>{item.ticketId}</p>
         <p><span>Max Count:&nbsp;&nbsp;</span>{item.eventId}</p>
         <p><span>Price:&nbsp;&nbsp;</span>{item.price}</p>
         <p><span>GST Percent:&nbsp;&nbsp;</span>{item.gsT_PERCENT}</p>
         <p><span>Booking End Date:&nbsp;&nbsp;</span>{item.booking_Start_Date}</p>
         <p><span>Eversion:&nbsp;&nbsp;</span>{item.eversion}</p>
        </div>
         <Button
         className='animatingBottom'
         style={{marginLeft:"40px"}}  variant="contained" onClick={()=>update(item)}>edit</Button>          
         <Button
         className='animatingBottom'
         style={{
        borderRadius: 5,
        backgroundColor: "red",
        padding: "6px 18px",
        fontSize: "14px",
        float:"right",
        marginRight:"20px"
    }}
    variant="contained"  onClick={()=>deleteuser(item)}>delete</Button>
   
     </>
          
        ))
      }
    </div>   :   " "   }
    </div>
   
      </>
  )
}

export default Brandmaster;