import React from "react";
import axios from "axios";
import Select from "react-select";
import DataGrids from "../../table/DataGrid";
import Leftnav from "../leftNav/Leftnav";
import Navbar from "../navbar";
import "../usermanager/usermanager.css";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Alert from "../alert/alert";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  getAllUsers,
  updateUser,
  URL_DELETEUSER,
  URL_GETALLORDER,
  URL_GETALLUSER,
  URL_GET_ALL_REGISTRATION,
  URL_GET_PROFILE_PHOTO,
  URL_GET_QR_PDF,
  URL_GET_REGIDTRATION_QR,
  URL_UPDATEUSER,
  URL_UPLOADIMAGE,
} from "../../api/constants";
import Cookies from "js-cookie";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tick from "../../images/tiick-unscreen.gif";
import Camera from "../Camera";
import Print from "../ReactToPrint";
import { ContactlessOutlined } from "@material-ui/icons";
import { fabClasses } from "@mui/material";
import Loader from "../Loader";

function Usermanager() {
  var userCode = Cookies.get("orgmcode");
  if (userCode === undefined || userCode === "" || userCode === null) {
    window.location.href = "/";
  }

  const [rowpos, setrowpos] = useState("nothing");

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [mobile, setmobile] = useState("");
  const [username, setusername] = useState("");
  const [Password, setPassword] = useState("");
  const [usertype, setusertype] = useState("");
  const [occupation, setoccupation] = useState("");
  const [uploadfiledisable, setuploadfiledisable] = useState(true);

  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [updatebtn, setupdatebtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [plusbtn, setplusbtn] = useState(false);
  const [plusbtnview, setplusbtnview] = useState("none");
  const [minusbtnview, setminusbtnview] = useState("block");
  const [isDisabled, setIsDisabled] = useState(false);
  const [printPopup, setprintPopup] = useState(false);
  const [profileImg, setprofileImg] = useState("");
  const [printingArr, setprintingArr] = useState([]);
  const [Printer, setprinter] = useState(false);
  const [capturedImage, setcapturedImage] = useState("");
  const [printwithImg, setprintwithImg] = useState(false);
  const [AskForImage, setAskForImage] = useState(false);
  const [registrationArr, setregistrationArr] = useState([]);
  const [ThisOrderRegistration, setThisOrderRegistration] = useState([]);
  const [askcompany, setaskcompany] = useState("");
  const [camerapopup, setcamerapopup] = useState(false);
  const [registrationCarosel, setregistrationCarosel] = useState(false);
  const [Regshowpopup, setRegshowpopup] = useState(false);
  const [thisRegData, setthisRegData] = useState([]);
  const [registrationmcode, setregistrationmcode] = useState("");
  const [registrationDetailsForQR, setregistrationDetailsForQR] = useState([]);
  const [thisQrcode, setthisQrcode] = useState("");
  const [regisPopup, setregisPopup] = useState(false);
  const [capturedRegImage, setcapturedRegImage] = useState("");
  const [RegPrinter, setRegPrinter] = useState(false);
  const [hideprintbtn, sethideprintbtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  setTimeout(() => {
    setalert(false);
  }, 5000);

  const settings = {
    infinite: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var user = Cookies.get("userType");
  var Orgmcode = Cookies.get("orgmcode");

  //table columns
  if (user === "ORGANIZER") {
    var columns = [
      // { field: 'id', headerName: 'ID' },
      { field: "eventName", headerName: "Event Name", width: 150 },
      { field: "clientName", headerName: "Client Name", width: 150 },
      { field: "ticketName", headerName: "Ticket Name", width: 150 },
      { field: "ticketType", headerName: "Ticket Type", width: 150 },
      {
        field: "ticketType",
        headerName: "Ticket Type",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.refunD_REQ == true
            ? "REFUND"
            : params.row.ticketType;
        },
      },
      { field: "remark", headerName: "Remark", width: 150 },
      { field: "pcQuantity", headerName: "Quantity", width: 150 },
      {
        field: "billDate",
        headerName: "Booking Date",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.billDate.replace("T", " ");
        },
      },
      {
        field: "remark",
        headerName: "QR CODE",
        sortable: false,
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => getRegistration(params.row)}
            >
              Get Registration
            </Button>
          );
        },
      },
      {
        field: "remark",
        headerName: "QR CODE",
        sortable: false,
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.remark === "CONFIRMED" ? (
            <Button variant="contained" color="primary" onClick={edit}>
              QR CODE
            </Button>
          ) : (
            ""
          );
        },
      },
      {
        field: "remark",
        headerName: "PRINT",
        sortable: false,
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.remark === "CONFIRMED" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => printQR(params.row)}
            >
              Print QR
            </Button>
          ) : (
            ""
          );
        },
      },
      {
        field: "Refundable",
        headerName: "Refundable",
        sortable: false,
        width: 160,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.refund_Accept === "True" ? (
            <Button variant="contained" color="primary">
              Refundable
            </Button>
          ) : (
            ""
          );
        },
      },
    ];
  } else {
    var columns = [
      // { field: 'id', headerName: 'ID' },
      {
        field: "PRINT",
        headerName: "PRINT",
        sortable: false,
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.remark === "CONFIRMED" &&
            params.row.refunD_REQ_ACCEPT != true ? (
            <Button
              style={{ position: "sticky" }}
              variant="contained"
              color="primary"
              onClick={(e) => printQR(params.row)}
            >
              Print QR
            </Button>
          ) : (
            ""
          );
        },
      },
      { field: "eventName", headerName: "Event Name", width: 150 },
      { field: "clientName", headerName: "Client Name", width: 150 },
      { field: "vistorPhone", headerName: "Client Mobile", width: 150 },
      { field: "vistorMail", headerName: "Client Email", width: 150 },
      { field: "strCompanyName", headerName: "Print Company", width: 150 },
      { field: "chNumber", headerName: "Order Number", width: 150 },
      { field: "ticketName", headerName: "Ticket Name", width: 150 },
      {
        field: "ticketType",
        headerName: "Ticket Type",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.refunD_REQ == true
            ? "REFUND"
            : params.row.ticketType;
        },
      },
      {
        field: "remark",
        headerName: "Remark",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.refunD_REQ == true &&
            params.row.refunD_REQ_ACCEPT == true
            ? "CANCELLED"
            : params.row.remark;
        },
      },
      {
        field: "icode",
        headerName: "Scanned",
        width: 100,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.icode === "IN" ? (
            <img style={{ height: "80px", marginLeft: "-30px" }} src={tick} />
          ) : (
            <img
              style={{ height: "30px", width: "50px" }}
              src="https://th.bing.com/th/id/OIP.pYx4niZBEXZvzCYXPDQTLQHaHa?w=173&h=180&c=7&r=0&o=5&pid=1.7"
            />
          );
        },
      },
      { field: "pcQuantity", headerName: "Quantity", width: 150 },
      {
        field: "strInOut",
        headerName: "Booked from",
        width: 220,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.strInOut === "ONSPOT" ||
            params.row.strInOut === "ONSPOT_REGISTRATION"
            ? params.row.strInOut
            : "APP";
        },
      },
      {
        field: "billDate",
        headerName: "Booking Date",
        width: 180,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.billDate.replace("T", " ");
        },
      },

      { field: "balanceAmount", headerName: " Amount", width: 150 },
      {
        field: "Action",
        headerName: "QR CODE",
        sortable: false,
        width: 130,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.remark === "CONFIRMED" &&
            params.row.refunD_REQ_ACCEPT != true ? (
            <Button variant="contained" color="primary" onClick={edit}>
              QR CODE
            </Button>
          ) : (
            ""
          );
        },
      },
      {
        field: "Registration",
        headerName: "Registration",
        sortable: false,
        width: 160,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.ticketType === "REGISTRATION" &&
            params.row.refunD_REQ_ACCEPT != true ? (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => getRegistration(params.row)}
            >
              Registrations
            </Button>
          ) : (
            ""
          );
        },
      },
      {
        field: "Refundable",
        headerName: "Refundable",
        sortable: false,
        width: 160,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return params.row.refund_Accept === "True" &&
            params.row.refunD_REQ_ACCEPT != true ? (
            <Button variant="contained" color="primary">
              Refundable
            </Button>
          ) : (
            ""
          );
        },
      },
    ];
  }

  var registrationcolumns = [
    { field: "name", headerName: "Name" },
    { field: "surname", headerName: "Surname", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "jobTitle", headerName: "Job Title", width: 150 },
    {
      field: "companyOrganisation",
      headerName: "Company / Organisation",
      width: 150,
    },
    { field: "workAddress", headerName: "Work Address", width: 150 },
    { field: "workPhone", headerName: "Work Phone", width: 150 },
    { field: "homeAddress", headerName: "Home Address", width: 150 },
    { field: "homePhone", headerName: "Home Phone", width: 150 },
    { field: "website", headerName: "Website", width: 150 },
    { field: "cellPhone", headerName: "Cell Phone", width: 150 },

    { field: "blog", headerName: "Blog", width: 150 },
    {
      field: "View Event",
      headerName: "QR Code",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewQR(params.row)}
            variant="contained"
            color="primary"
          >
            {" "}
            Qr Code
          </Button>
        );
      },
    },
  ];

  const viewQR = (item) => {
    setthisRegData(item);
    setregistrationmcode(item.mcode);
    setRegshowpopup(true);
    console.log(item);
    setregistrationDetailsForQR(item);
    var data = JSON.stringify({
      rowpos: item.rowpos,
    });

    var config = {
      method: "post",
      url: URL_GET_REGIDTRATION_QR,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data.path);
        setthisQrcode(response.data.data.path);
      })
      .catch(function (error) {
        console.log(error);
      });

    // upload image
  };

  const hidepopup = () => {
    setRegshowpopup(false);
    setRegPrinter(false);
  };

  const handleRegCheck = (e) => {
    setprintwithImg(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };
  const handleRegisterCamera = () => {
    setregisPopup(true);
  };

  const closeregcamera = () => {
    setregisPopup(false);
  };

  const captureimage = () => {
    setcamerapopup(true);
  };

  const reactPrintRegistration = async () => {
    console.log(registrationmcode);
    var data = JSON.stringify({
      Media_ID: registrationmcode,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data.data[0].path);
        setcapturedRegImage(response.data.data[0].path);
        setRegPrinter(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    setRegPrinter(true);

    sethideprintbtn(true);
  };

  //refresh function call next after update and delete api

  const refresh = () => {
    // var config = {
    //   method: 'get',
    //   url: URL_GETALLUSER,
    //   headers: { }
    // };
    // axios(config)
    // .then(function (response) {
    //   console.log(response.data);
    //   setTableData(response.data)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  };

  //delete user

  const deleteuser = (item) => {
    var rowpos = item.rowpos;
    var data = JSON.stringify({
      ROWPOS: rowpos,
    });

    var config = {
      method: "post",
      url: URL_DELETEUSER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        refresh();
        setalert(true);
        setalertmsg(`✔ ${item.firstName}  has been added successfully !`);
        setshowpopup(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // edit function

  const edit = () => {
    setshowpopup(true);
    setrowpos(tableData[2].rowpos)
    console.log(rowpos)

    console.log(selectedRows.firstName);
    console.log(firstname);
  };

  //update

  const update = (item) => {
    setupdatebtn(true);
    setplusbtn(true);
    console.log(item);
    setfirstname(item.firstName);
    setlastname(item.lastName);

    setmobile(item.mobile);
    setusername(item.userName);
    setPassword(item.password);
    setusertype(item.userType);
    console.log(item.userType);
    setoccupation(item.occupation);
    setrowpos(item.rowpos);

    setshowpopup(false);
    // updateuser()
  };

  var allregistrations = [];
  const getRegistration = (item) => {

    for (var i = 0; i < registrationArr.length; i++) {
      if (
        registrationArr[i].mcode === item.party &&
        registrationArr[i].eventID === item.eventRowpos
      ) {
        allregistrations.push(registrationArr[i]);
        setThisOrderRegistration(allregistrations);
      }
    }
    console.log(allregistrations);
    if (allregistrations.length === 0) {
      setalert(true);
      setalertmsg("NO RESULT FOUND !!");
    } else {
      setregistrationCarosel(true);
    }
  };

  const updateuser = () => {
    console.log("fine  update button clicked  !");
    var data = JSON.stringify({
      ROWPOS: rowpos,
      userName: username,
      password: Password,
      firstName: firstname,
      lastName: lastname,
      mobile: mobile,
      userType: usertype,
      occupation: occupation,
    });

    var config = {
      method: "post",
      url: URL_UPDATEUSER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        refresh();

        handleminus();
        setalert(true);
        setalertmsg(`✔ ${firstname}  has been Updated successfully !`);
        console.log(usertype);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var Org = [];

  var allorders = [];
  var allRegistration = [];
  var merge = [];
  // get all order
  useEffect(() => {
    setIsLoading(true);
    // console.log("fsdfO", Orgmcode)

    var config = {
      method: "post",
      url: URL_GETALLORDER,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        var mydata = response.data.data;

        if (user === "ORGANIZER") {
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].party === "saite0000000000000027") {
              Org.push(mydata[i]);
            }
          }
          console.log("orgorder", Org);
          setTableData(Org);
          setIsLoading(false);
        } else {
          allorders.push(response.data.data);
          var unique = [
            ...new Map(
              response.data.data.map((m) => [m.qrCodePath, m])
            ).values(),
          ];
          setTableData(unique);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // load all registration then filter based on their rowpos

    var data = JSON.stringify({
      EventID: "",
    });

    var config = {
      method: "post",
      url: URL_GET_ALL_REGISTRATION,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data)
        allRegistration.push(response.data.data);
        setregistrationArr(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    //  setTimeout(() => {
    //   console.log(allorders)
    //   console.log(allRegistration)
    //   console.log(merge.concat(...allorders,...allRegistration))
    //   var totalorder = merge.concat(...allorders,...allRegistration)
    //   const unique = [...new Map(totalorder.map((m) => [m.rowpos, m])).values()];
    //   console.log(unique)
    //   setTableData(unique)

    //  }, 2000);
  }, []);

  const adduser = () => {
    if (firstname == "") {
      setalert(true);
      setalertmsg("firstname can not be blank !");
    } else if (lastname == "") {
      setalert(true);
      setalertmsg("lastname can not be blank !");
    } else if (mobile == "") {
      setalert(true);
      setalertmsg("mobile No can not be blank !");
    } else if (username == "") {
      setalert(true);
      setalertmsg("username can not be blank !");
    } else if (Password == "") {
      setalert(true);
      setalertmsg("Password can not be blank !");
    } else if (usertype == "") {
      setalert(true);
      setalertmsg("usertype can not be blank !");
    }

    var data = JSON.stringify({
      mail: username,
      Mobile: mobile,
      FirstName: firstname,
      LastName: lastname,
      userType: usertype,
      Occupation: occupation,
    });

    var config = {
      method: "post",
      url: "https://dev-api.accex.saitec.in/api/Account/signup",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.IsSuccess);

        if (response.data.IsSuccess === true) {
          handleminus();
          setalert(true);
          setalertmsg(`✔ ${firstname}  has been created successfully !`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const printTicketPDF = () => {
    console.log(profileImg);

    if (profileImg !== "") {
      var FormData = require("form-data");
      var data = new FormData();
      data.append("File", profileImg);
      data.append("Type", "ORDER");
      data.append("Id", printingArr.party);
      data.append("SubType", "ProfilePhoto");

      var config = {
        method: "post",
        url: URL_UPLOADIMAGE,
        headers: {},
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.isSuccess);
          if (response.data.isSuccess == true) {
            setalert(true);
            setalertmsg("File Uploaded Sccessfully !");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    // if image is not uploaded yet
    var data = JSON.stringify({
      MCODE: printingArr.party,
      QRCodePath: printingArr.qrCodePath,
      strTicketID: printingArr.strTicketID,
      strCompanyName: askcompany,
    });

    var config = {
      method: "post",
      url: URL_GET_QR_PDF,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess === true) {
          setprofileImg("");
          console.log(response.data.data);
          window.open(response.data.data, "_blank");
        } else {
          setalert(true);
          setalertmsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const printQR = (item) => {

    setprintPopup(true);
    setshowpopup(false);
    setaskcompany("");
    setprintingArr(item);
    sethideprintbtn(false);
  };
  const closesetprintPopup = () => {
    setprintPopup(false);
    // setprintwithImg(true)
    setprintingArr([]);
    setprinter(false);
  };

  const handleplus = () => {
    setplusbtnview("block");
    setminusbtnview("none");
    setplusbtn(true);
    setIsDisabled(true);
  };
  const handleminus = () => {
    setplusbtnview("none");
    setminusbtnview("block");
    setplusbtn(false);
  };

  const closecamera = () => {
    setcamerapopup(false);
  };

  const handlereactPrint = async () => {
    console.log(printwithImg);
    setAskForImage(false);

    var data = JSON.stringify({
      Media_ID: printingArr.party,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        setcapturedImage(response.data.data[0].path);
        console.log(capturedImage);
        setprinter(true);
        setAskForImage(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setprinter(true);
    sethideprintbtn(true);
  };

  const handleIfChecked = (e) => {
    setprintwithImg(e.target.checked);
    console.log(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };

  const closeRegistrationDetailsPopup = () => {
    setregistrationCarosel(false);
    setThisOrderRegistration([]);
  };

  const handlePhotoPrint = (e) => {
    console.log(askcompany);
    console.log(e.target.files[0]);
    setprofileImg(e.target.files[0]);
    setuploadfiledisable(false);
  };

  const UploadSelectedPhoto = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", profileImg);
    data.append("Type", "ORDER");
    data.append("Id", printingArr.party);
    data.append("SubType", "ProfilePhoto");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess == true) {
          setalert(true);
          setalertmsg("File Uploaded Sccessfully !");
          setAskForImage(false);
          setuploadfiledisable(true);
        } else {
          setalert(true);
          setalertmsg(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var color = "green";
  console.log(tableData[2]);

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? (
        <Alert className="top animating" msg={alertmsg} color={color} />
      ) : (
        ""
      )}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">All orders</h5>
        </center>
        <br />

        {isLoading ? (
          <Loader />
        ) : (
          <div className="dashtbl">
            <DataGrid
              rows={tableData}
              columns={columns}
              autoPageSize
              getRowId={(row) => row.qrCodePath}
              components={{
                Toolbar: GridToolbarExport,
              }}
              // checkboxSelection
              // onSelectionModelChange={(ids) => {
              //   const selectedIDs = new Set(ids);
              //   const selectedRows = tableData.filter((row) =>
              //     selectedIDs.has(row.qrCodePath)
              //   );

              //   setSelectedRows(selectedRows);

              //   console.log(selectedRows);
              //   // setfirstname(selectedRows.mcode)
              // }}
              {...tableData}
            />
          </div>
        )}
        {/* <h4>ALL <span style={{color:"red"}}>REGISTRATIONS</span> </h4>
      <div className='table animatingBottom' style={{height:"340px",width:"80%"}}>
      <DataGrid
            rows={registrationArr}
            columns={registrationcolumns}
            pageSize={5}
            getRowId={(row) => row.rowpos}
       
            {...tableData}
          />
      </div> */}
        {Regshowpopup ? (
          <>
            <div className="userpopup">
              <div className="popupdata animatingBottom">
                <p
                  className="cross mb"
                  style={{ color: "#111", fontSize: "24px" }}
                  onClick={hidepopup}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </p>
                <div
                  style={{ height: "400px", width: "400px", display: "flex" }}
                >
                  <img
                    className="animatingBottom"
                    style={{ padding: "20px" }}
                    src={thisQrcode}
                  />
                  <br />
                  <input type="hidden" value={registrationmcode} />
                </div>
                <center>
                  <input
                    type="checkbox"
                    id="isPhoto"
                    checked={printwithImg}
                    onChange={(e) => handleRegCheck(e)}
                  />
                  <label>Photo Capture:</label>
                  {printwithImg ? (
                    <p
                      style={{
                        display: "inline-block",
                        transform: "scale(1.5)",
                      }}
                      onClick={handleRegisterCamera}
                    >
                      {" "}
                      <ion-icon name="camera-outline"></ion-icon>
                    </p>
                  ) : (
                    ""
                  )}
                </center>

                {hideprintbtn ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {/* <Button style={{background:"#016fe8",border:"2px solid #016fe8",color:"#fff",padding:"5px"}} onClick={printThisQR}>Print QR</Button> */}
                    <Button
                      style={{ background: "green" }}
                      variant="contained"
                      color="primary"
                      disabled={AskForImage}
                      onClick={reactPrintRegistration}
                    >
                      Print Ticket
                    </Button>
                  </div>
                )}

                {RegPrinter ? (
                  <Print
                    type="reg"
                    pic={capturedRegImage}
                    isImg={printwithImg}
                    qr={thisQrcode}
                    name={thisRegData.name}
                    company={thisRegData.companyOrganisation}
                    ticket={thisRegData.strRegistrationCode}
                    event={thisRegData.eventID}
                    tckqty={thisRegData.pcQuantity}
                    tckprice={thisRegData.balanceAmount}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {regisPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb "
                id="camerapop"
                style={{ display: "inline-flex" }}
                onClick={closeregcamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera upload={setAskForImage} mcode={registrationmcode} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {showpopup ? (
          <div className="popupbg">
            <div className="userpopup animatingTop">
              <p className="cross mb" onClick={() => setshowpopup(false)}>
                <ion-icon name="close-outline"></ion-icon>
              </p>
              <br />
              <br />
              {selectedRows.map((item) => (
                <>
                  <div className="popupdata animatingBottom">
                    <div className="qrdivMobile">
                      <img
                        className="animatingBottom"
                        style={{ padding: "20px" }}
                        src={item.qrCodePath}
                      />
                      <br />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        ) : (
          " "
        )}
        {printPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p className="cross mb" onClick={closesetprintPopup}>
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <label>Company:</label>
                  <input
                    type="text"
                    placeholder="Enter Company"
                    value={askcompany}
                    onChange={(e) => setaskcompany(e.target.value)}
                  />
                </div>
                <br />
                <input
                  type="checkbox"
                  id="isPhoto"
                  checked={printwithImg}
                  onChange={(e) => handleIfChecked(e)}
                />
                <label>Photo :</label>

                {printwithImg ? (
                  <>
                    <input
                      type="file"
                      placeholder="Upload Photo"
                      onChange={(e) => handlePhotoPrint(e)}
                    />
                    <button
                      style={{
                        background: "#016fe8",
                        border: "2px solid #016fe8",
                        color: "#fff",
                        padding: "2px 6px",
                        position: "absolute",
                        right: "8%",
                      }}
                      onClick={UploadSelectedPhoto}
                      disabled={uploadfiledisable}
                    >
                      upload
                    </button>
                    <p
                      style={{
                        display: "inline-block",
                        transform: "scale(1.5)",
                      }}
                      onClick={captureimage}
                    >
                      {" "}
                      <ion-icon name="camera-outline"></ion-icon>
                    </p>
                  </>
                ) : (
                  ""
                )}
                {Printer ? (
                  <Print
                    type="reg"
                    pic={capturedImage}
                    isImg={printwithImg}
                    qr={printingArr.qrCodePath}
                    name={printingArr.clientName}
                    company={askcompany}
                    ticket={printingArr.strTicketID}
                    event={printingArr.eventRowpos}
                    tckqty={printingArr.pcQuantity}
                    tckprice={printingArr.balanceAmount}
                  />
                ) : (
                  ""
                )}
              </div>
              {hideprintbtn ? (
                ""
              ) : (
                <div style={{ textAlign: "center" }}>
                  {/* <Button onClick={printTicketPDF} style={{background:"red",border:"2px solid red#016fe8",color:"#fff",padding:"5px"}}>Print</Button>  */}
                  <Button
                    style={{ background: "green" }}
                    variant="contained"
                    color="primary"
                    disabled={AskForImage}
                    onClick={handlereactPrint}
                  >
                    Print Ticket
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          ""
        )}
        {camerapopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb"
                id="camerapop"
                style={{ display: "inline-flex" }}
                onClick={closecamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera upload={setAskForImage} mcode={printingArr.party} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {registrationCarosel ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb"
                id="camerapop"
                style={{ display: "inline-flex" }}
                onClick={closeRegistrationDetailsPopup}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>
              <div style={{ marginTop: "20px" }}>
                {ThisOrderRegistration?.map((tickets, index) => {
                  return (
                    <>
                      <div
                        className=""
                        key={index}
                        style={{ marginTop: "20px" }}
                      >
                        <center>
                          <h5
                            className="registratoinh3"
                            style={{ color: "red" }}
                          >
                            REGISTRATION DETAILS
                          </h5>
                        </center>
                        <div className="eventdetails carouselRegistration registrationbox">
                          <div style={{ height: "300px", overflowY: "scroll" }}>
                            {tickets.name === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>name:</span>
                                {tickets.name}
                              </p>
                            )}
                            {tickets.surname === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>surname:</span>
                                {tickets.surname}
                              </p>
                            )}
                            {tickets.gender === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>gender:</span>
                                {tickets.gender}
                              </p>
                            )}

                            {tickets.email === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>email:</span>
                                {tickets.email}
                              </p>
                            )}
                            {tickets.jobTitle === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>jobTitle:</span>
                                {tickets.jobTitle}
                              </p>
                            )}
                            {tickets.companyOrganisation === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>
                                  company / <br />
                                  Organisation:
                                </span>
                                {tickets.companyOrganisation}
                              </p>
                            )}
                            {tickets.workAddress === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>workAddress:</span>
                                {tickets.workAddress}
                              </p>
                            )}
                            {tickets.workPhone === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>workPhone</span>:{tickets.workPhone}
                              </p>
                            )}
                            {tickets.homeAddress === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>homeAddress:</span>
                                {tickets.homeAddress}
                              </p>
                            )}
                            {tickets.homePhone === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>homePhone:</span>
                                {tickets.homePhone}
                              </p>
                            )}
                            {tickets.website === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>website:</span>
                                {tickets.website}
                              </p>
                            )}
                            {tickets.cellPhone === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>cellPhone:</span>
                                {tickets.cellPhone}
                              </p>
                            )}
                            {tickets.blog === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>blog:</span>
                                {tickets.blog}
                              </p>
                            )}
                            {tickets.visitor === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>Visitor:</span>
                                {tickets.visitor}
                              </p>
                            )}
                            {tickets.seller === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>Seller:</span>
                                {tickets.seller}
                              </p>
                            )}
                            {tickets.buyer === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>Buyer:</span>
                                {tickets.buyer}
                              </p>
                            )}
                            {tickets.purpose === "" ? (
                              ""
                            ) : (
                              <p>
                                <span>Purpose:</span>
                                {tickets.purpose}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Usermanager;
