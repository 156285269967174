import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import {
  URL_DELETEBANNERBYID,
  URL_GETALLBANNERS,
  URL_GETALLHOMEBANNER,
} from "../api/constants";
import Alert from "./alert/alert";
import Loader from "./Loader";

function Banner() {
  const [bannersimgs, setbannersimgs] = useState([]);
  const [projectno, setprojectno] = useState([]);
  const [deletebannerid, setdeletebannerid] = useState("");

  const [alertmsg, setalertmsg] = useState("");
  const [alert, setalert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char =
      props.type === "next" ? (
        <ion-icon name="arrow-forward-outline"></ion-icon>
      ) : (
        <ion-icon name="arrow-back-outline"></ion-icon>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }

  const settings = {
    className: "b-radius",
    infinite: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
    responsive: [
      {
        breakpoint: 2400,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var searchArr = [];

  useEffect(() => {
    setIsLoading(true);
    // handleLoading()

    axios.get(URL_GETALLHOMEBANNER).then((response) => {
      var res = response.data.data;
      // console.log(response.data.data.length , res)
      if (response.data.isSuccess == true) {

        setbannersimgs(response.data.data);
      }
      setIsLoading(false);
      // handleLoading()

      // var ifSeleted = document.querySelector('.selectinp').value;
      // if(ifSeleted === ""){
      //   setbannersimgs(response.data.data)
      // }
      // else{
      //     for (var i = 0 ; i < response.data.data.length ; i++){
      //       if(response.data.data[i].media_ID === "20020") {
      //         searchArr.push(response.data.data[i])
      //       }
      //       }
      //       setbannersimgs(searchArr)
      // }
    });
  }, []);

  var buttons = document.querySelectorAll(".deleltebannerbtn");

  // Create an empty array to store the button values
  var buttonValues = [];

  // Loop through each button and add a click event listener
  buttons.forEach(function (button) {
    button.addEventListener("click", function () {
      // Get the value of the clicked button and push it to the array
      var buttonValue = this.value;
      console.log(buttonValue);
      setdeletebannerid(buttonValue);
      setalert(true);
      setalertmsg("wanna delete this banner !!!");
    });
  });

  const deletethisbanner = () => {
    var axios = require("axios");
    var data = JSON.stringify({
      rowpos: deletebannerid,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL_DELETEBANNERBYID,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setalert(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="bannerMobile">
          <Slider {...settings}>
            {bannersimgs.map((item, i) => (
              <>
                <div key={i}>
                  <h6>
                    {/* <center> <h5 key={i}>{item.media_ID}</h5></center> */}
                    <Link
                      to={`/dashboard/itemmaster/eventdetails/${item.media_ID}`}
                    >
                      <img className="bannersimg" src={item?.path} />
                    </Link>
                  </h6>
                </div>

                {alert ? (
                  <>
                    <p>Are You Sure to delete This Banner ?</p>
                    <center>
                      <Button
                        variant="contained"
                        onClick={deletethisbanner}
                      >
                        Delete Banner
                      </Button>
                    </center>
                  </>
                ) : (
                  <center>
                    <input
                      className="deleltebannerbtn"
                      style={{
                        width: "200px",
                        background: "red",
                        color: "#fff",
                      }}
                      type="submit"
                      value={item.rowpos}
                    />
                  </center>
                )}
              </>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default Banner;
