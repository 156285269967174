import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { URL_ALLEVENTS } from '../../api/constants';
import "./dashboard.css"
import CountUp from 'react-countup';
import Cookies from 'js-cookie';


function Gridbox(props) {

    const [ ALL,setALL] = useState("")
   const [unappr,setunappr] = useState("")
   const [appr,setappr] = useState("")
   const [draft,setdraft] = useState("")


   var user = Cookies.get("userType");
   var Orgmcode = Cookies.get("orgmcode");
   var Org = [];
   var all=[];
   var appro = [];
   var unapprove = [];
   var drft = [];

   useEffect(()=>{
    var data = JSON.stringify({
      "Status": "APPROVED"
    });
    
    var config = {
      method: 'post',
      url: URL_ALLEVENTS,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      // console.log(response.data.length);
      var mydata = response.data.data;
      for (var i = 0; i < mydata.length; i++) {
        var today = new Date();
        var EndDate = new Date(mydata[i].exhibitionEndDate);
        if (mydata[i].status === "APPROVED" && today < EndDate) {
          unapprove.push(mydata[i])

        }
      }
      if (user === "ORGANIZER") {
        console.log("hi theere")
        for (var i = 0; i < mydata.length; i++) {
          var today = new Date(); 
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].orgMcode === Orgmcode ) {
            Org.push(mydata[i]);
          }
        }
        
  
        console.log('apprlen',Org)
        props.setapprCnt(Org.length);
        localStorage.setItem("appr",Org.length);
      }
      else{
        props.setapprCnt(unapprove.length);
        localStorage.setItem("appr",unapprove.length);
      }
    
      
    })
    .catch(function (error) {
      console.log(error);
    });

   },[Org]) //all approved

    useEffect(()=>{
      var data = JSON.stringify({
        "status": "PENDING"
      });
      
      var config = {
        method: 'post',
        url: URL_ALLEVENTS,
      
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        // console.log(response.data.data.length);
        var mydata = response.data.data;
        // console.log("from unapproved")
        // console.log(mydata)
        for (var i = 0; i < mydata.length; i++) {
          var today = new Date();
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].status === "PENDING" && today < EndDate) {
            appro.push(mydata[i])
  
          }
        }

        if (user === "ORGANIZER") {
          console.log("hi theere")
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode === Orgmcode) {
              all.push(mydata[i]);
            }
          }
          console.log('orwgunapprrcount',all)
          props.setunapprCnt(all.length);
        localStorage.setItem("unappr",all.length)
        }
        else{
          // console.log('orwgunapprrcount',appro.length)
          props.setunapprCnt(appro.length)
          localStorage.setItem("unappr",appro.length)
        }
      })
      .catch(function (error) {
        console.log("oops"+error);
      });
    },[all])    // all unapproved counts

useEffect(()=>{
    var data = JSON.stringify({
        "status":"ALL"
      });
      
      var config = {
        method: 'post',
        url: URL_ALLEVENTS,
       
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        var mydata = response.data.data;
        if (user === "ORGANIZER") {
         
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode === Orgmcode) {
              appro.push(mydata[i]);
            }
          }
          console.log('allcount',appro)
          props.setALLCount(appro.length);
          localStorage.setItem("all",appro.length)
        }
        else{
          props.setALLCount(response.data.data.length)
          localStorage.setItem("all",response.data.data.length)
        }
      
        // setalert(true)
        // setalertmsg(`✔`)
       
      })
      .catch(function (error) {
        console.log("oops"+error);
      });


},[appro])
var draftC = []
useEffect(()=>{
     //draft
     var data = JSON.stringify({
      "status": "DRAFT"
    });
    
    var config = {
      method: 'post',
      url: URL_ALLEVENTS,
     
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      // console.log(response.data.data);
      var mydata = response.data.data;
      // console.log("from draft")
      // console.log(mydata)
      for (var i = 0; i < mydata.length; i++) {
        var today = new Date();
        var EndDate = new Date(mydata[i].exhibitionEndDate);
        if (mydata[i].status === "DRAFT" && today < EndDate) {
          draftC.push(mydata[i])

        }
      }
// console.log(draftC)
      if (user === "ORGANIZER") {
       
        for (var i = 0; i < mydata.length; i++) {
          if (mydata[i].orgMcode === Orgmcode) {
            drft.push(mydata[i]);
          }
        }
        // console.log('orgDRAFT',drft)
        props.setdraftcount(draftC.length);
        localStorage.setItem("draft",draftC.length)
      }
      else{
        // console.log("countdraft")
        // console.log(draftC.length)
        props.setdraftcount(draftC.length)
         localStorage.setItem("draft",draftC.length)
      }
    
    })
    .catch(function (error) {
      console.log("oops"+error);
    });
    
},[drft])
   



  return (
   
    <div className='responsivebox animatingTop' style={{display:"none"}} >
        <div className='focus animatingBottom'><CountUp end={ALL}/> </div>
        <div className='focus animatingTop'><CountUp end={appr}/></div>
        <div className='focus animatingBottom' ><CountUp end={unappr}/></div>
        <div className='focus animatingBottom' ><CountUp end={draft}/></div>
    </div>
  )
}

export default Gridbox;