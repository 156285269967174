import { Button, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  SHARE_VIA_EMAIL,
  URL_ALLEVENTS,
  URL_CREATE_BUY_TICKET,
  URL_CREATE_ORDER_QR,
  URL_CREATE_REGISTRATION,
  URL_CREATE_REGISTRATION_QR,
  URL_GETALLORDER,
  URL_GETEVENTS_FOR_TICKETMASTER,
  URL_GETUNIQUEEVENT,
  URL_GET_ALL_REGISTRATION,
  URL_GET_PROFILE_PHOTO,
  URL_GET_REGISTRATION_COUNT,
  URL_SAVE_COMPANY_NAME_TODB,
  URL_UPLOADIMAGE,
} from "../api/constants";
import Leftnav from "./leftNav/Leftnav";
import Navbar from "./navbar";
import tick from "../images/tiick-unscreen.gif";
import Alert from "./alert/alert";
import load from "../images/loader.gif";

import Select from "react-select";
import Loader from "./Loader";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Print from "./ReactToPrint";
import Camera from "./Camera";
import SearchableDropdown from "./itemmaster/SearchableDropdown";

// import tick from "../../images/tiick-unscreen.gif"

function BuyTicket() {
  // if (window.location.protocol === 'http:') {
  //   window.location.protocol = 'https:';
  // }
  // setTimeout(() => {
  //   setalert(false)
  // }, 6000);

  const [alertpop, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [loader, setloader] = useState(false);
  const [uploadfiledisable, setuploadfiledisable] = useState(true);

  const [isdisable, setisdisable] = useState(true);
  const [isticketselected, setisticketselected] = useState(false);
  const [searchval, setsearchval] = useState("search Event");

  const [eventname, seteventname] = useState("");
  const [ticketType, setticketType] = useState("");
  const [ticketName, setticketName] = useState("");
  const [vistorname, setvistorname] = useState("");
  const [vistoraddress, setvistoraddress] = useState("");
  const [Quantity, setQuantity] = useState("");

  const [boothminsize, setboothminsize] = useState(0);
  const [maxCount, setmaxcount] = useState(0);
  const [maxtickets, setmaxtickets] = useState(0);
  const [boothincrement, setboothincrement] = useState(0);
  const [inetialbootharea, setinetialbootharea] = useState(0);
  const [EventsForTicketmaster, setEventsForTicketmaster] = useState("");

  const [registrtionform, setregistrtionform] = useState(false);
  const [boothForm, setboothForm] = useState(false);
  const [registrationArr, setregistrationArr] = useState([]);
  const [totalamtcard, settotalamtcard] = useState(0);
  const [registrationArrL, setregistrationArrL] = useState(0);

  const [registrationfName, setregistrationfName] = useState("");
  // registration form hooks

  const [regPrifix, setregPrifix] = useState("");
  const [regfName, setregfName] = useState("");
  const [reglName, setreglName] = useState("");
  const [regEmail, setregEmail] = useState("");
  const [regGender, setregGender] = useState("");
  const [regHomephone, setregHomephone] = useState("");
  const [regCellphone, setregCellphone] = useState("");
  const [regHaddress, setregHaddress] = useState("");
  const [regWebsite, setregWebsite] = useState("");
  const [regBlog, setregBlog] = useState("");
  const [regJobtitle, setregJobtitle] = useState("");
  const [regCompany, setregCompany] = useState("");
  const [regWaddress, setregWaddress] = useState("");
  const [regWorkPhone, setregWorkPhone] = useState("");
  const [regvisitorname, setregvisitorname] = useState("");
  const [regbuyername, setregbuyername] = useState("");
  const [regsellername, setregsellername] = useState("");
  const [purposeval, setpurposeval] = useState("");

  const [prifixD, setprifixD] = useState(true);
  const [fnameD, setfnameD] = useState(true);
  const [lnameD, setlnameD] = useState(true);
  const [emailD, setemailD] = useState(true);
  const [genderD, setgenderD] = useState(true);
  const [homephD, sethomephD] = useState(true);
  const [cellphD, setcellphD] = useState(true);
  const [homeAddD, sethomeAddD] = useState(true);
  const [websiteD, setwebsiteD] = useState(true);
  const [blogD, setblogD] = useState(true);
  const [JobtitleD, setJobtitleD] = useState(true);
  const [companyD, setcompanyD] = useState(true);
  const [workaddD, setworkaddD] = useState(true);
  const [workphD, setworkphD] = useState(true);

  const [visitorDD, setvisitorDD] = useState(true);
  const [buyerDD, setbuyerDD] = useState(true);
  const [sellerDD, setsellerDD] = useState(true);
  const [purposeDD, setpurposeDD] = useState(true);

  // ------

  const [printPopup, setprintPopup] = useState(false);
  const [askcompany, setaskcompany] = useState("");
  const [printwithImg, setprintwithImg] = useState(false);
  const [AskForImage, setAskForImage] = useState(false);
  const [profileImg, setprofileImg] = useState("");
  const [printingArr, setprintingArr] = useState([]);
  const [Printer, setprinter] = useState(false);
  const [capturedImage, setcapturedImage] = useState("");
  const [camerapopup, setcamerapopup] = useState(false);
  const [currentQR, setcurrentQR] = useState("");
  const [currentTicketID, setcurrentTicketID] = useState("");
  const [currentUserMcode, setcurrentUserMcode] = useState("");

  const [currentRegQr, setcurrentRegQr] = useState("");
  const [Regshowpopup, setRegshowpopup] = useState(false);
  const [thisRegData, setthisRegData] = useState([]);
  const [registrationmcode, setregistrationmcode] = useState("");
  const [regisPopup, setregisPopup] = useState(false);
  const [capturedRegImage, setcapturedRegImage] = useState("");
  const [RegPrinter, setRegPrinter] = useState(false);
  const [currentregistrationTicketID, setcurrentregistrationTicketID] =
    useState("");
  const [currentregistrationName, setcurrentregistrationName] = useState("");
  const [currentregistraionMcode, setcurrentregistraionMcode] = useState("");
  const [hideprintbtn, sethideprintbtn] = useState(false);

  const [addclass, setaddclass] = useState("spaced");
  const [dnone, setdnone] = useState("d-none ");
  const [fullwidth, setfullwidth] = useState("bannerfullwidth");

  // registration form hooks  ---- end

  // total counts  of ticket , addon , booth and registration  banners data

  const [totalonspotBook, settotalonspotBook] = useState(0);
  const [totalonspotTickt, settotalonspotTickt] = useState(0);
  const [totalonspotBooth, settotalonspotBooth] = useState(0);
  const [totalonspotAddon, settotalonspotAddon] = useState(0);
  const [totalonspotRegistration, settotalonspotRegistration] = useState(0);

  const [allticket, setallticket] = useState([]);
  const [bannerArray, setbannerArray] = useState([]);
  const [bannerSelectedTCKT, setbannerSelectedTCKT] = useState([]);

  const [myticket, setmyticket] = useState([]);
  const [mybooth, setmybooth] = useState([]);
  const [myaddon, setmyaddon] = useState([]);
  const [myregistration, setmyregistration] = useState([]);

  const [vistorTele, setvistorTele] = useState("");
  const [vistormail, setvistormail] = useState("");
  const [allEvent, setallEvent] = useState([]);
  const [tableData, setTableData] = useState([]);

  //table columns

  var columns = [
    // { field: 'id', headerName: 'ID' },
    { field: "eventName", headerName: "Event Name", width: 150 },
    { field: "clientName", headerName: "Client Name", width: 150 },
    { field: "ticketName", headerName: "Ticket Name", width: 150 },
    { field: "ticketType", headerName: "Ticket Type", width: 150 },
    { field: "remark", headerName: "Remark", width: 150 },

    {
      field: "icode",
      headerName: "Scanned",
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.icode === "IN" ? (
          <img style={{ height: "80px", marginLeft: "-30px" }} src={tick} />
        ) : (
          <img
            style={{ height: "30px", width: "50px" }}
            src="https://th.bing.com/th/id/OIP.pYx4niZBEXZvzCYXPDQTLQHaHa?w=173&h=180&c=7&r=0&o=5&pid=1.7"
          />
        );
      },
    },
    { field: "pcQuantity", headerName: "Quantity", width: 150 },

    {
      field: "billDate",
      headerName: "Booking Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.billDate.replace("T", " ");
      },
    },

    { field: "balanceAmount", headerName: " Amount", width: 150 },
    {
      field: "Action",
      headerName: "QR CODE",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.remark === "CONFIRMED" ? (
          <Button variant="contained" color="primary">
            QR CODE
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      field: "PRINT",
      headerName: "PRINT",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.remark === "CONFIRMED" ? (
          <Button variant="contained" color="primary">
            Print QR
          </Button>
        ) : (
          ""
        );
      },
    },
  ];
  console.log(allticket);

  var onspot = [];

  var onspotTckt = [];
  var onspotBooth = [];
  var onspotAddon = [];

  const closesetprintPopup = () => {
    setaskcompany("");
    setprintPopup(false);
    setprinter(false);
    setprintwithImg(false);
    sethideprintbtn(false);

    //  localStorage.setItem('CurrentEventVal',eventname)
    //  localStorage.setItem('CurrentEventname', searchval)
    //  localStorage.setItem('Currentticketname', ticketName)
    //  localStorage.setItem('Currenttickettype', ticketType)
    //   // setprintwithImg(true)
    //   window.location.reload()
  };

  const hidepopup = () => {
    setRegshowpopup(false);
    setRegPrinter(false);
    setprintwithImg(false);
    sethideprintbtn(false);
    // localStorage.setItem('CurrentEventVal',eventname)
    // localStorage.setItem('CurrentEventname', searchval)
    // window.location.reload()
  };

  const handleIfChecked = (e) => {
    setprintwithImg(e.target.checked);
    console.log(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };

  const handleRegCheck = (e) => {
    setprintwithImg(e.target.checked);
    if (printwithImg) {
      setAskForImage(false);
    } else {
      setAskForImage(true);
    }
  };

  const handleRegisterCamera = () => {
    setregisPopup(true);
  };

  const handlePhotoPrint = (e) => {
    console.log(askcompany);
    console.log(e.target.files[0]);
    setprofileImg(e.target.files[0]);
    setuploadfiledisable(false);
  };

  const UploadSelectedPhoto = () => {
    if (profileImg.length < 1) {
      alert("plese select the photo first ");
      return;
    }
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", profileImg);
    data.append("Type", "ORDER");
    data.append("Id", currentUserMcode);
    data.append("SubType", "ProfilePhoto");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.isSuccess);
        if (response.data.isSuccess == true) {
          //  setalert(true)
          //  setalertmsg('File Uploaded Sccessfully !')
          alert("file uploaded successfully ! You can Print Ticket Now");
          setAskForImage(false);
          setuploadfiledisable(true);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // registration
  const UploadSelectedPhotoreg = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", profileImg);
    data.append("Type", "ORDER");
    data.append("Id", currentregistraionMcode);
    data.append("SubType", "ProfilePhoto");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.isSuccess == true) {
          //  setalert(true)
          //  setalertmsg('File Uploaded Sccessfully !')
          alert("file uploaded successfully ! You can Print ");
          setAskForImage(false);
          setuploadfiledisable(true);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const captureimage = () => {
    setcamerapopup(true);
  };

  const SaveClientCompanyNameToDB = async (name, mcode) => {
    try {
      console.log(name, mcode);
      const response = await axios.post(URL_SAVE_COMPANY_NAME_TODB, {
        strCompanyName: name,
        MCODE: mcode,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handlereactPrint = async () => {
    console.log(printwithImg);
    setAskForImage(false);

    var data = JSON.stringify({
      Media_ID: currentUserMcode,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response);
        setcapturedImage(response.data.data[0].path);
        console.log(capturedImage);
        setprinter(true);
        setAskForImage(false);
      })
      .catch(function (error) {
        console.log(error);
      });

    setprinter(true);

    sethideprintbtn(true);

    // now save the company name enterd the before printing ticket

    SaveClientCompanyNameToDB(askcompany, currentUserMcode);
  };

  const closecamera = () => {
    setcamerapopup(false);
  };

  //

  const reactPrintRegistration = async () => {
    console.log(registrationmcode);
    var data = JSON.stringify({
      Media_ID: currentregistraionMcode,
    });

    var config = {
      method: "post",
      url: URL_GET_PROFILE_PHOTO,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        console.log(response.data.data[0].path);
        setcapturedRegImage(response.data.data[0].path);
        setRegPrinter(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    setRegPrinter(true);

    sethideprintbtn(true);

    SaveClientCompanyNameToDB(askcompany, currentregistraionMcode);
  };

  const closeregcamera = () => {
    setregisPopup(false);
  };
  // -------- printing work end

  useEffect(() => {
    // const currentEventVal = localStorage.getItem('CurrentEventVal');
    // if (currentEventVal) {
    //   fillpreviousData();
    //   setaddclass("space ")
    //   setdnone("block animatingRight")
    //   setfullwidth("animatingLeft")
    // }
  }, []); //onspotTckt ,onspotAddon, onspotBooth
  //

  function fillpreviousData() {
    handlesearchval(localStorage.getItem("CurrentEventVal"));
    //  handleTicket(localStorage.getItem('Currenttickettype'))
    setsearchval(localStorage.getItem("CurrentEventname"));
     setticketName(localStorage.getItem('Currentticketname'))
    // setticketType(localStorage.getItem('Currenttickettype'))
  }

  var filterdata = [];
  function GetTicketCounts(param) {
    settotalonspotBook(0);
    settotalonspotTickt(0);
    settotalonspotBooth(0);
    settotalonspotAddon(0);

    var count = 0;
    // setloader(true)
    // console.log(count)
    var config = {
      method: "post",
      url: URL_GETALLORDER,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        var mydata = response.data.data;
        count = count + 1;
        if (param == "search Event") {
          filterdata = response.data.data;
        } else {
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].eventRowpos == param) {
              filterdata.push(mydata[i]);
            }
          }
        }

        // setloader(false)
        onspot = [];
        for (var i = 0; i < filterdata.length; i++) {
          if (filterdata[i].strInOut === "ONSPOT") {
            onspot.push(filterdata[i]);
          }
        }

        settotalonspotBook(onspot.length); // total onspot booking
        onspotAddon = [];
        for (var i = 0; i < onspot.length; i++) {
          if (onspot[i].ticketType === "ADDON") {
            onspotAddon.push(onspot[i]);
          }
        }

        settotalonspotAddon(onspotAddon.length); // total onspot addon
        onspotTckt = [];
        for (var i = 0; i < onspot.length; i++) {
          if (onspot[i].ticketType === "TICKET") {
            onspotTckt.push(onspot[i]);
          }
        }

        settotalonspotTickt(onspotTckt.length); // total onspot ticket
        onspotBooth = [];
        for (var i = 0; i < onspot.length; i++) {
          if (onspot[i].ticketType === "BOOTH") {
            onspotBooth.push(onspot[i]);
          }
        }
        settotalonspotBooth(onspotBooth.length); // totoal onspot booth
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(count)
  }

  const sendMailtoClient = async (mailData) => {
    try {
      console.log(mailData);
      const response = await axios.post(SHARE_VIA_EMAIL, mailData);

      console.log(response);

      if (response.data) {
        console.info("mail successfully send on this details " + mailData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  var SelectedTicket = [];
  var totalAmount = 0;

  const handleBuy = () => {
    var euser = Cookies.get("orgmcode");
    setloader(true);
    if (eventname === "") {
      // setalert(true)
      alert("Event name Can't be Empty");
      setloader(false);
      return;
    } else if (ticketType === "") {
      // setalert(true)
      alert("Ticket Type Can't be Empty");
      setloader(false);
      return;
    } else if (ticketType == "TICKET" && Quantity > maxtickets) {
      // setalert(true)
      alert(`You can't Exceed Ticket Quantity more than ${maxtickets}`);
      setloader(false);
      return;
    } else if (ticketName === "") {
      // setalert(true)
      alert("Ticket Name Can't be Empty");
      setloader(false);
      return;
    } else if (vistorname === "") {
      // setalert(true)
      alert("vistor Name Can't be Empty");
      setloader(false);
      return;
    } else if (vistoraddress === "") {
      // setalert(true)
      alert("Address Can't be Empty");
      setloader(false);
      return;
    } else if (vistorTele === "") {
      //setalert(true)
      alert("vistor Phone Can't be Empty");
      setloader(false);
      return;
    } else if (!validatePhoneNumber(vistorTele)) {
      //setalert(true)
      alert("mobile No must be at least 10 numbers !");
      setvistorTele("");
      setloader(false);
      return;
    } else if (
      !new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/i).test(
        vistormail
      )
    ) {
      //setalert(true)
      alert("Please Enter a Valid Email !");
      setvistormail("");
      setloader(false);
      return;
    } else {
      setaskcompany("");
      // set name address and qty in local storage for later views

      localStorage.setItem("onspotname", vistorname);
      localStorage.setItem(
        "onspotqty",
        ticketType === "BOOTH" ? boothminsize : Quantity
      );
      localStorage.setItem("onspotaddress", vistoraddress);

      setisdisable(false);
      console.log(eventname);
      console.log(ticketName);
      console.log(Quantity);
      console.log(allticket);

      for (var i = 0; i < allticket.length; i++) {
        if (allticket[i].ticketId === ticketName) {
          SelectedTicket.push(allticket[i]);
        }
      }
      console.log(SelectedTicket); //  this is your Booking  ticket array
      // setbannerSelectedTCKT(SelectedTicket)

      if (ticketType === "BOOTH") {
        var data = JSON.stringify({
          EventName: eventname,
          VistorName: vistorname,
          VistorAddress: vistoraddress,
          VistorPhone: vistorTele,
          PCQuantity: boothminsize,
          VistorMail: vistormail,
          EUSER: euser,
          ticket: {
            StallType: SelectedTicket[0].stallType,
            TicketName: SelectedTicket[0].ticketName,
            TicketId: SelectedTicket[0].ticketId,
            dSellingPrice: SelectedTicket[0].dSellingPrice,
            GST_PERCENT: SelectedTicket[0].gsT_PERCENT,
            TicketType: ticketType,
          },
        });
      } else {
        var data = JSON.stringify({
          EventName: eventname,
          VistorName: vistorname,
          VistorAddress: vistoraddress,
          VistorPhone: vistorTele,
          PCQuantity: Quantity,
          VistorMail: vistormail,
          EUSER: euser,
          ticket: {
            StallType: SelectedTicket[0].stallType,
            TicketName: SelectedTicket[0].ticketName,
            TicketId: SelectedTicket[0].ticketId,
            dSellingPrice: SelectedTicket[0].dSellingPrice,
            GST_PERCENT: SelectedTicket[0].gsT_PERCENT,
            TicketType: ticketType,
          },
        });
      }

      var config = {
        method: "post",
        url: URL_CREATE_BUY_TICKET,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.isSuccess === true) {
            setalert(true);
            setalertmsg(
              "Order Created Successfully ! Please Wait While We create Your QR CODE"
            );
            setcurrentTicketID(response?.data.data.ticket.ticketId);
            setcurrentUserMcode(response?.data.data.mcode);
            // now hit api to create qr

            var data = JSON.stringify({
              MCODE: response?.data.data.mcode,
              CHNumber: response?.data.data.chNumber,
              ticket: {
                ticketID: response?.data.data.ticket.ticketId,
                quantity: response?.data.data.pcQuantity,
              },
            });

            var config = {
              method: "post",
              url: URL_CREATE_ORDER_QR,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            // https://dev-api.accex.saitec.in\verifyTicket?UkVHSVNUUkFUSU9OSUQ9NjA4JlJFR0lTVFJBVElPTkNPREU9R0RWV1NRVVRXVVBXRldDRQ==

            axios(config)
              .then(function (response) {
                console.log(response);
                setcurrentQR(response.data);
                // async function to immidiatly send message to client
                sendMailtoClient({
                  Name: vistorname,
                  Company: "",
                  TicketID: ticketName,
                  profile_pic: "",
                  QRPath: response.data,
                  MailTo: vistormail,
                  EventID: eventname,
                  TicketPrice: localStorage.ticketprice * Quantity,
                  TicketQty: Quantity,
                });
                // setQuantity("")
                setvistorname("");
                setvistoraddress("");
                setQuantity("");
                setvistorTele("");
                setvistormail("");
                setloader(false);
                // setalert(true)
                setalert(false);
                alert("Order Created Successfully");
                setprintPopup(true);
                // alert(hideprintbtn)
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            setloader(false);
            // setalert(true)
            console.log(response.data.message);
            alert(response.data.message + " Order Creation Failed");
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Something Went Wrong ! Please Try Again !!");
          setloader(false);
        });
    }
  };

  var regcount = [];
  const allregistration = (param) => {
    settotalonspotRegistration(0);

    var data = JSON.stringify({
      EventID: "",
    });

    var config = {
      method: "post",
      url: URL_GET_ALL_REGISTRATION,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        var mydata = response.data.data;
        // console.log(mydata)

        if (param == "ALL") {
          regcount = mydata;
        } else {
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].eventID == param) {
              regcount.push(mydata[i]);
            }
          }
        }

        setregistrationArrL(regcount.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    allregistration("ALL");
    GetTicketCounts(searchval);

    var usertyp = Cookies.get("userType");
    var loginmcode = Cookies.get("orgmcode");

    var approvedEvent = [];

    console.log(usertyp);
    if (
      usertyp === "TICKETMASTER" ||
      usertyp === "ORGANISER" ||
      usertyp === "ADMIN"
    ) {
      console.log("ticket master");
      // filter filter unique ticket _master
      var data = JSON.stringify({
        Mcode: loginmcode,
      });

      var config = {
        method: "post",
        url: URL_GETEVENTS_FOR_TICKETMASTER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config).then(function (response) {
        console.log(response);
        console.log(response.data.data.event_id);
        var ticketevents = response.data.data.event_id;
        console.log(ticketevents);

        if (response.data.isSuccess == true) {
          var data = JSON.stringify({
            status: "ALL",
          });

          var config = {
            method: "post",
            url: URL_ALLEVENTS,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(response.data.data);
              var mydata = [];
              console.log(ticketevents.split(","));
              var eventIds = ticketevents?.split(",");
              console.log(eventIds);
              for (var i = 0; i < response.data.data.length; i++) {
                // if(response.data.data[i].rowpos.includes('70175','70176')){
                if (
                  eventIds.some((id) =>
                    response.data.data[i].rowpos.includes(id)
                  )
                ) {
                  mydata.push(response.data.data[i]);
                }
              }

              console.log(mydata);
              for (var i = 0; i < mydata.length; i++) {
                var today = new Date();
                var EndDate = new Date(mydata[i].exhibitionEndDate);
                console.log(today);
                console.log(EndDate);

                if (mydata[i].status === "APPROVED" && today < EndDate) {
                  console.log(mydata[i].status);
                  console.log(mydata[i].name);
                  approvedEvent.push(mydata[i]);
                }
              }
              console.log(approvedEvent);
              setallEvent(approvedEvent);
              console.log(approvedEvent);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });

      // --------------------------
    } else {
      var data = JSON.stringify({
        status: "ALL",
      });

      var config = {
        method: "post",
        url: URL_ALLEVENTS,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          var mydata = response.data.data;
          // console.log(mydata)
          for (var i = 0; i < mydata.length; i++) {
            var today = new Date();
            var EndDate = new Date(mydata[i].exhibitionEndDate);
            if (mydata[i].status === "APPROVED" && today < EndDate) {
              approvedEvent.push(mydata[i]);
            }
          }
          setallEvent(approvedEvent);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    return re.test(input_str);
  }
  const handlesearchval = (val) => {
    seteventname(val);
    GetTicketCounts(val);
    allregistration(val);

    // setvalueforsearch(val)
    console.log(val);
    var data = JSON.stringify({
      ROWPOS: val,
    });

    var config = {
      method: "post",
      url: URL_GETUNIQUEEVENT,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data.data);

        setbannerArray(response.data.data);

        setmyticket(response.data.data[0].ticket_list);
        setmybooth(response.data.data[0].booth_list);

        setmyregistration(response.data.data[0].registration_list);
        setmyaddon(response.data.data[0].addon_list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var liveTickets = [];
  var liveBooths = [];
  var liveAddon = [];
  const handleTicket = (e) => {
    setticketType(e.target ? e.target.value : e);
    var type = e.target ? e.target.value : e;

    if (type === "TICKET") {
      console.log(myticket);

      for (var i = 0; i < myticket.length; i++) {
        var today = new Date();
        var EndDate = new Date(myticket[i].booking_End_Date);
        if (today < EndDate) {
          liveTickets.push(myticket[i]);
        }
      }
      console.log("liveticket");
      console.log(liveTickets);
      setallticket(liveTickets);
      setregistrtionform(false);
      setboothForm(false);
    } else if (type === "BOOTH") {
      console.log(mybooth);

      for (var i = 0; i < mybooth.length; i++) {
        var today = new Date();
        var EndDate = new Date(mybooth[i].booking_End_Date);
        if (today < EndDate) {
          liveBooths.push(mybooth[i]);
        }
      }
      setallticket(liveBooths);
      setregistrtionform(false);
      setboothForm(true);
    } else if (type === "ADDON") {
      console.log(myaddon);
      for (var i = 0; i < myaddon.length; i++) {
        var today = new Date();
        var EndDate = new Date(myaddon[i].booking_End_Date);
        if (today < EndDate) {
          liveAddon.push(myaddon[i]);
        }
      }
      console.log("liveAddon");
      setallticket(myaddon);
      setregistrtionform(false);
      setboothForm(false);
    } else if (type === "REGISTRATION") {
      setregistrtionform(true);
      setboothForm(false);
      //  get registration  Count
      setisticketselected(false);

      var data = JSON.stringify({
        ROWPOS: eventname,
      });

      var config = {
        method: "post",
        url: URL_GET_REGISTRATION_COUNT,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.data[0].requiredFields);
          var a = response.data.data[0].requiredFields.toString();
          setregistrationArr(response.data.data[0].requiredFields);

          if (a.includes("Name") || a.includes("First Name")) {
            setfnameD(false);
          }
          if (a.includes("SurName") || a.includes("Last Name")) {
            setlnameD(false);
          }
          if (a.includes("Email")) {
            setemailD(false);
          }
          if (a.includes("Gender")) {
            setgenderD(false);
          }
          if (a.includes("Home Phone")) {
            sethomephD(false);
          }
          if (a.includes("Website")) {
            setwebsiteD(false);
          }
          if (
            a.includes("Company/Organisation") ||
            a.includes("Company Name")
          ) {
            setcompanyD(false);
          }
          if (a.includes("Work Address") || a.includes("Company Address")) {
            setworkaddD(false);
          }
          if (a.includes("CellPhone") || a.includes("Mobile Number")) {
            setcellphD(false);
          }
          if (a.includes("Home Address")) {
            sethomeAddD(false);
          }
          if (a.includes("Blog")) {
            setblogD(false);
          }
          if (a.includes("Job Title")) {
            setJobtitleD(false);
          }
          if (a.includes("Work Phone") || a.includes("Company Phone")) {
            setworkphD(false);
          }
          if (a.includes("Prefix")) {
            setprifixD(false);
          }
          if (a.includes("Seller")) {
            setsellerDD(false);
          }
          if (a.includes("Visitor")) {
            setvisitorDD(false);
          }
          if (a.includes("Buyer")) {
            setbuyerDD(false);
          }
          if (a.includes("Purpose") || a.includes("purpose of Visit")) {
            setpurposeDD(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  var registerfname = "";
  const handleRegister = () => {
    var euser = Cookies.get("orgmcode");

    setloader(true);
    console.log(reglName);
    if (eventname == "") {
      // setalert(true)
      alert("Fields Can't Be Empty");
      setloader(false);
      return;
    } else if (fnameD === false && regfName == "") {
      alert("Please Enter First Name");
      setloader(false);
      return;
    } else if (fnameD === false && regfName.length > 20) {
      alert("First Name Can't be greater than 20 letters");
      setloader(false);
      return;
    } else if (lnameD === false && reglName == "") {
      alert("Please Enter Last Name");
      setloader(false);
      return;
    } else if (
      emailD === false &&
      !new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/i).test(
        regEmail
      )
    ) {
      // setalert(true)
      alert("Please Enter valid Email");
      setloader(false);

      return;
    } else if (genderD === false && regGender == "") {
      alert("Please select your Gender");
      setloader(false);
      return;
    } else if (homephD === false && !validatePhoneNumber(regHomephone)) {
      // setalert(true)
      alert("Please Enter Valid  Home phone");
      setloader(false);
      return;
    } else if (cellphD === false && !validatePhoneNumber(regCellphone)) {
      // setalert(true)
      alert("Please Enter Valid  Mobile Number");
      setloader(false);
      return;
    } else if (homeAddD === false && regHaddress == "") {
      alert("Please Enter your Home Address");
      setloader(false);
      return;
    } else if (websiteD === false && regWebsite == "") {
      alert("Please Enter Your Website");
      setloader(false);
      return;
    } else if (blogD === false && regBlog == "") {
      alert("Please Enter  your Blog");
      setloader(false);
      return;
    } else if (JobtitleD === false && regJobtitle == "") {
      alert("Please Enter  your Job title / Designation");
      setloader(false);
      return;
    } else if (companyD === false && regCompany == "") {
      alert("Please Enter Company/Organisation");
      setloader(false);
      return;
    } else if (sellerDD === false && regsellername == "") {
      alert("Please Enter  your Seller Name");
      setloader(false);
      return;
    } else if (visitorDD === false && regvisitorname == "") {
      alert("Please Enter  your Visitor Name");
      setloader(false);
      return;
    } else if (buyerDD === false && regbuyername == "") {
      alert("Please Enter  your Visitor Name");
      setloader(false);
      return;
    } else if (purposeDD === false && purposeval == "") {
      alert("Please Enter  your Purpose of Visit");
      setloader(false);
      return;
    } else if (workaddD === false && regWaddress == "") {
      alert("Please Enter Work Address");
      setloader(false);
      return;
    } else if (workphD === false && !validatePhoneNumber(regWorkPhone)) {
      alert("Please Enter  your Valid Work phone number");
      setloader(false);
      return;
    } else {
      debugger;
      console.log(regfName);
      var data = JSON.stringify({
        ROWPOS: eventname,
        Prefix: regPrifix,
        Name: regfName,
        Surname: reglName,
        Email: regEmail,
        Gender: regGender,
        HomePhone: regHomephone,
        CellPhone: regCellphone,
        HomeAddress: regHaddress,
        Website: regWebsite,
        Blog: regBlog,
        JobTitle: regJobtitle,
        CompanyOrganisation: regCompany,
        WorkAddress: regWaddress,
        WorkPhone: regWorkPhone,
        MCODE: regfName,
        Visitor: regvisitorname,
        Seller: regsellername,
        Buyer: regbuyername,
        Purpose: purposeval,
        EUSER: euser,
      });

      var config = {
        method: "post",
        url: URL_CREATE_REGISTRATION,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);
          if (response.data.isSuccess === true) {
            localStorage.setItem(
              "onspotname",
              regfName + " " + (reglName ?? "")
            );
            localStorage.setItem("ticketprice", 0);
            localStorage.setItem("onspotqty", 0);

            sethideprintbtn(false);

            setalert(true);
            setalertmsg(
              "Registration Created Successfully ! Please Wait While We create Your QR CODE"
            );
            setloader(false);
            setregGender("");
            setregEmail("");
            setreglName("");
            setreglName("");
            setregEmail("");
            setregGender("");
            setregHomephone("");
            setregCellphone("");
            setregHaddress("");
            setregWebsite("");
            setregBlog("");
            setregJobtitle("");
            setregCompany("");
            setregWaddress("");
            setregWorkPhone("");
            setregfName("");
            setregvisitorname("");
            setregbuyername("");
            setpurposeval("");
            setregsellername("");
            setaskcompany("");
            // setalert(true)

            console.log(response.data.data);
            console.log(response.data.data[0].regBillNumber);
            console.log(response.data.data[0].strRegistrationCode);
            setcurrentregistrationTicketID(
              response.data.data[0].strRegistrationCode
            );
            // REGISTRATION NAME
            var regclientname =
              response.data.data[0].name +
              " " +
              (response?.data?.data[0]?.surname ?? "");
            setcurrentregistrationName(regclientname);

            setcurrentregistraionMcode(
              response.data.data[0].strRegistrationMcode
            );

            var data = JSON.stringify({
              strRegistrationID: response.data.data[0].regBillNumber,
              strRegistrationCode: response.data.data[0].strRegistrationCode,
            });

            var config = {
              method: "post",
              url: URL_CREATE_REGISTRATION_QR,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                console.log(response.data);
                setRegshowpopup(true);
                setcurrentRegQr(response.data);
                setalert(false);
                setloader(false);
                alert("Registration ticket created !!");
                // setalert(true)
                console.log(emailD);
                // {emailD === false &&   sendMailtoClient({ "Name": vistorname, "Company": "", "TicketID": response.data.data[0].strRegistrationCode, "profile_pic": "", "QRPath": response.data, "MailTo": regEmail, "EventID": eventname, "TicketPrice": "", "TicketQty": "" }); }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            // setalert(true)
            alert(
              "Registration ticket QR creation Failed  ! Please try again !"
            );
            setloader(false);
            window.location.reload();
          }
        })
        .catch(function (error) {
          setloader(false);
          console.log(error);
        });
    }
  };

  function getnameForThis(name, length) {
    let formattedName = name;

    while (formattedName.length < length) {
      formattedName += name;
    }
    return formattedName;
  }

  var SelectedBooth = [];
  const handleticketName = (e) => {
    
    setticketName(e.target.value);
    console.log(allticket);
    for (var i = 0; i < allticket.length; i++) {
      if (allticket[i].ticketId === e.target.value) {
        SelectedBooth.push(allticket[i]);
      }
    }
    console.log(SelectedBooth);
    console.log(SelectedBooth[0].price);
    totalAmount = SelectedBooth[0].price;
    setmaxtickets(SelectedBooth[0].maxCount);
    if (ticketType == "BOOTH") {
      settotalamtcard(SelectedBooth[0].rental);
    } else {
      settotalamtcard(SelectedBooth[0].dSellingPrice);
    }

    localStorage.setItem("ticketprice", SelectedBooth[0].dSellingPrice);
    setisticketselected(true);
    setbannerSelectedTCKT(SelectedBooth);

    setboothminsize(SelectedBooth[0].minSize);
    setinetialbootharea(SelectedBooth[0].minSize);
    setboothincrement(SelectedBooth[0].incrementThereAfter);

    setmaxcount(SelectedBooth[0].area);
  };

  const plus = (e) => {
    console.log(maxCount);
    if (boothminsize < maxCount) {
      setboothminsize(boothminsize + parseInt(boothincrement));
    }
  };

  const minus = () => {
    console.log(boothminsize);
    if (boothminsize > inetialbootharea) {
      setboothminsize(boothminsize - parseInt(boothincrement));
    }
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handleoption = () => {
    console.log();
  };

  const jsonArray = allEvent?.map((item) => {
    return {
      id: item.rowpos,
      name: item.name,
    };
  });

  // const handleQuantity =(e)=>{
  //   setQuantity(e.target.value)
  //   settotalamtcard(totalamtcard *   e.target.value)
  // }

  const handleEventchange = (val) => {
    setsearchval(val);
    setaddclass("space ");
    setdnone("block animatingRight");
    setfullwidth("animatingLeft");
    setisticketselected(false);
  };

  return (
    <>
      <Navbar />
      <Leftnav />
      {alertpop ? <Alert className="top animating" msg={alertmsg} /> : ""}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Onspot Buy </h5>
        </center>
        <br />
        <div className={`banner`}>
          <div className="banner_data">
            <div>
              <span>{totalonspotBook}</span>
              <br />
              Total Booking
            </div>
            <div>
              <span>{totalonspotTickt}</span>
              <br />
              Total Ticket
            </div>
            <div>
              <span>{totalonspotBooth}</span>
              <br />
              Total Booth
            </div>
            <div>
              <span>{totalonspotAddon}</span>
              <br />
              Total Addon
            </div>
            <div>
              <span>{registrationArrL}</span>
              <br />
              Total registration
            </div>
          </div>
        </div>

        <br />

        {loader ? (
          <div style={{ position: "absolute", left: "32%", top: "37%" }}>
            <img style={{ width: "100px" }} src={load} />
          </div>
        ) : (
          <div className="flexedbox">
            <div className={`d-flex ${addclass} `}>
              <div className="mb-3">
                <label className="form-label">Select Event</label>
                <br />
                {/* <Select
                  className="selectedEvent"
                  value={eventname}
                  onChange={(e) => handleEvent(e)}
                  options={allEvent.map((item) => ({
                    value: item.rowpos,
                    label: item.name,
                  }))}
                ></Select> */}

                <SearchableDropdown
                  options={allEvent}
                  label="name"
                  id="rowpos"
                  selectedVal={searchval}
                  handleChange={(val) => handleEventchange(val)}
                  handleChangeval={(val) => handlesearchval(val)}
                />
              </div>
              <div className="mb-3 animatingLeft">
                <label className="form-label">Select Ticket Type</label>
                <br />
                <select value={ticketType} onChange={(e) => handleTicket(e)}>
                  <option value="">Select</option>
                  <option value="TICKET">TICKET</option>
                  <option value="BOOTH">BOOTH</option>
                  <option value="ADDON">ADDON</option>
                  <option value="REGISTRATION">REGISTRATION</option>
                </select>
              </div>
              {registrtionform ? (
                ""
              ) : (
                <div className="mb-3 animatingLeft">
                  <label className="form-label">Select Ticket Name</label>
                  <br />
                  <select
                    value={ticketName}
                    onChange={(e) => handleticketName(e)}
                  >
                    <option value="">select</option>
                    {allticket.map((item, index) => (
                      <option key={index} value={item.ticketId}>
                        {item.ticketName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            {registrtionform ? (
              <>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {/* <div className='mb-3'>
                    <label className="form-label">Prifix</label>
                    <br />
                    <select style={{width:"200px"}} value={regPrifix} onChange={(e)=>setregPrifix(e.target.value)} disabled={prifixD}>
                        <option value="">Select</option>
                        <option value="MR">MR</option>
                        <option value="MRS">MRS</option>
                   </select>
                </div> */}
                  {fnameD ? (
                    ""
                  ) : (
                    <>
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <br />
                        <input
                          type="text"
                          placeholder="Enter First Name"
                          value={regfName}
                          onChange={(e) => setregfName(e.target.value)}
                          disabled={fnameD}
                        />
                      </div>
                    </>
                  )}
                  {lnameD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Last Name</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Last Name"
                        value={reglName}
                        onChange={(e) => setreglName(e.target.value)}
                        disabled={lnameD}
                      />
                    </div>
                  )}
                </div>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {emailD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter  Email"
                        value={regEmail}
                        onChange={(e) => setregEmail(e.target.value)}
                        disabled={emailD}
                      />
                    </div>
                  )}
                  {genderD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Gender</label>
                      <br />
                      <select
                        style={{ width: "260px" }}
                        value={regGender}
                        onChange={(e) => setregGender(e.target.value)}
                        disabled={genderD}
                      >
                        <option value="">Select</option>
                        <option value="MALE">MALE</option>
                        <option value="FEMALE">FEMALE</option>
                        <option value="OTHER">OTHER</option>
                        <option value="TRANSGENDER">TRANSGENDER</option>
                      </select>
                    </div>
                  )}
                  {homephD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Home Phone</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Enter Home Phone"
                        value={regHomephone}
                        onChange={(e) => setregHomephone(e.target.value)}
                        disabled={homephD}
                      />
                    </div>
                  )}
                </div>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {cellphD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Mobile Number</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Enter Your Phone"
                        value={regCellphone}
                        onChange={(e) => setregCellphone(e.target.value)}
                        disabled={cellphD}
                      />
                    </div>
                  )}
                  {homeAddD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Home Address</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter  Home Address"
                        value={regHaddress}
                        onChange={(e) => setregHaddress(e.target.value)}
                        disabled={homeAddD}
                      />
                    </div>
                  )}
                  {websiteD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label"> Company Website</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Home Website url"
                        value={regWebsite}
                        onChange={(e) => setregWebsite(e.target.value)}
                        disabled={websiteD}
                      />
                    </div>
                  )}
                </div>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {blogD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Blog Address</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Your Blog"
                        value={regBlog}
                        onChange={(e) => setregBlog(e.target.value)}
                        disabled={blogD}
                      />
                    </div>
                  )}
                  {JobtitleD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Job Title</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter  Job-Title"
                        value={regJobtitle}
                        onChange={(e) => setregJobtitle(e.target.value)}
                        disabled={JobtitleD}
                      />
                    </div>
                  )}
                  {companyD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Company Name</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Company Name"
                        value={regCompany}
                        onChange={(e) => setregCompany(e.target.value)}
                        disabled={companyD}
                      />
                    </div>
                  )}
                </div>
                {/* ADDING MORE FIELDS INTO REGISTRATION FORM  */}

                <div className={`d-flex ${addclass} animatingLeft`}>
                  {visitorDD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Visitor Name</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Visitor Name"
                        value={regvisitorname}
                        onChange={(e) => setregvisitorname(e.target.value)}
                        disabled={visitorDD}
                      />
                    </div>
                  )}
                  {buyerDD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Buyer Name</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Buyer Name"
                        value={regbuyername}
                        onChange={(e) => setregbuyername(e.target.value)}
                        disabled={buyerDD}
                      />
                    </div>
                  )}

                  {sellerDD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Seller Name</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Seller Name"
                        value={regsellername}
                        onChange={(e) => setregsellername(e.target.value)}
                        disabled={sellerDD}
                      />
                    </div>
                  )}
                </div>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {purposeDD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Purpose Of Visit</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter the Purpose Of Visit"
                        value={purposeval}
                        onChange={(e) => setpurposeval(e.target.value)}
                        disabled={purposeDD}
                      />
                    </div>
                  )}
                </div>

                {/* END */}
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {workaddD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Company Address</label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter Your Work Address"
                        value={regWaddress}
                        onChange={(e) => setregWaddress(e.target.value)}
                        disabled={workaddD}
                      />
                    </div>
                  )}
                  {workphD ? (
                    ""
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">Company Phone</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Enter your Work Phone"
                        value={regWorkPhone}
                        onChange={(e) => setregWorkPhone(e.target.value)}
                        disabled={workphD}
                      />
                    </div>
                  )}

                  {workphD === true &&
                  workaddD === true &&
                  companyD === true &&
                  JobtitleD === true &&
                  blogD === true &&
                  websiteD === true &&
                  cellphD === true &&
                  homeAddD === true &&
                  genderD === true &&
                  homephD === true &&
                  fnameD === true &&
                  lnameD === true &&
                  emailD === true ? (
                    " NO REQUIRED FIELDS"
                  ) : (
                    <div className="mb-3">
                      <Button
                        className="animatingBottom"
                        style={{
                          borderRadius: 5,
                          backgroundColor: "tomato",
                          padding: "6px 24px",
                          fontSize: "14px",
                          float: "right",
                          marginRight: "20px",
                          marginTop: "20px",
                        }}
                        variant="contained"
                        onClick={handleRegister}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  {boothForm ? (
                    <div className="mb-3 boothcounter">
                      <label className="form-label">Area</label>
                      <br />
                      <button onClick={minus} id="minusBtn">
                        -
                      </button>
                      <input
                        type="text"
                        style={{ textalign: "center" }}
                        className="boothinput"
                        value={boothminsize}
                        onChange={(e) => setboothminsize(e.target.value)}
                        disabled="true"
                      />
                      <button onClick={(e) => plus(e)} id="plusBtn">
                        +
                      </button>
                    </div>
                  ) : (
                    <div className="mb-3">
                      <label className="form-label">TICKET QUANTITY</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Enter Ticket Quantity"
                        value={Quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="mb-3">
                    <label className="form-label"> Visitor Name</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter name"
                      value={vistorname}
                      onChange={(e) => setvistorname(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Visitor Address</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Address"
                      value={vistoraddress}
                      onChange={(e) => setvistoraddress(e.target.value)}
                    />
                  </div>
                </div>
                <div className={`d-flex ${addclass} animatingLeft`}>
                  <div className="mb-3">
                    <label className="form-label">Visitor Phone</label>
                    <br />
                    <input
                      type="number"
                      placeholder="Enter Phone"
                      min={0} // Set the minimum value
                      max={10}
                      value={vistorTele}
                      onChange={(e) => setvistorTele(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label"> Visitor Mail</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Enter Mail"
                      value={vistormail}
                      onChange={(e) => setvistormail(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <Button
                      className="animatingBottom"
                      style={{
                        borderRadius: 5,
                        backgroundColor: "tomato",
                        padding: "6px 24px",
                        fontSize: "14px",
                        float: "right",
                        marginRight: "20px",
                        marginTop: "20px",
                      }}
                      variant="contained"
                      onClick={handleBuy}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </>
            )}
            <br />
          </div>
        )}

        {/* dont know */}
        {/* <div className={`Buydetails ${dnone}`}>
          {bannerArray.map((item) => {
            return (
              <>
                <div className="">
                  <div className="shopping-image">
                    <img src={item.media_list[0].path} alt="shopping" />
                  </div>
                  <center>
                    <h4 className="BTheading">{item.name}</h4>
                  </center>
                  <div className="text">
                    <span style={{ color: "green" }}>{item.orgName}</span>
                    <span style={{ color: "red" }}>
                      {item.category?.slice(0, 20)}
                    </span>
                  </div>
                </div>
              </>
            );
          })}
          {isticketselected ? (
            <>
              {bannerSelectedTCKT.map((item) => {
                return (
                  <>
                    <div className="text">
                      <span style={{ color: "green" }}>{item.ticketName}</span>
                      <span style={{ color: "red" }}>{item.ticketType}</span>
                    </div>
                    <div className="text">
                      <span style={{ color: "green" }}>{item.ticketType}</span>
                      <Link
                        to={`${`/dashboard/itemmaster/eventdetails/${item.eventId}/id?name=${item.ticketId}&event=${item.eventId}`}`}
                      >
                        <span style={{ color: "red" }}>{item.ticketId}</span>
                      </Link>
                    </div>
                    {item.ticketType === "BOOTH" ? (
                      <div className="text">
                        <span>Rental :</span>
                        <span style={{ color: "red" }}>{item.rental}</span>
                      </div>
                    ) : (
                      <div className="text">
                        <span>Price :</span>
                        <span style={{ color: "red" }}>
                          {item.dSellingPrice}
                        </span>
                      </div>
                    )}

                    {item.ticketType === "BOOTH" ? (
                      <div className="text">
                        <span>TOTAL RENTAL :</span>
                        {item.rental == boothminsize ? (
                          <span style={{ color: "red" }}>{item.rental}</span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {totalamtcard * parseInt(boothminsize)}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="text">
                        <span>TOTAL PRICE :</span>
                        {Quantity == "" ? (
                          <span style={{ color: "red" }}>
                            {item.dSellingPrice}
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            {totalamtcard * parseInt(Quantity)}
                          </span>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <>
              {
                <div style={{ padding: "4px" }}>
                  <span style={{ color: "green" }}>
                    {registrationArr.slice(1, -1)}
                  </span>
                </div>
              }
            </>
          )}
        </div> */}

        {/* -----popups--- */}

        {printPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p className="cross mb" onClick={closesetprintPopup}>
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                  }}
                >
                  <label>Company:</label>
                  <input
                    type="text"
                    placeholder="Enter Company"
                    value={askcompany}
                    onChange={(e) => setaskcompany(e.target.value)}
                  />
                </div>
                <br />
                <input
                  type="checkbox"
                  id="isPhoto"
                  checked={printwithImg}
                  onChange={(e) => handleIfChecked(e)}
                />
                <label>Photo :</label>

                {printwithImg ? (
                  <>
                    <input
                      type="file"
                      placeholder="Upload Photo"
                      onChange={(e) => handlePhotoPrint(e)}
                    />
                    <button
                      style={{
                        background: "#016fe8",
                        border: "2px solid #016fe8",
                        color: "#fff",
                        padding: "2px 6px",
                        position: "absolute",
                        right: "8%",
                      }}
                      onClick={UploadSelectedPhoto}
                      disabled={uploadfiledisable}
                    >
                      upload
                    </button>
                    <p
                      style={{
                        display: "inline-block",
                        transform: "scale(1.5)",
                      }}
                      onClick={captureimage}
                    >
                      {" "}
                      <ion-icon name="camera-outline"></ion-icon>
                    </p>
                  </>
                ) : (
                  ""
                )}
                {Printer ? (
                  <Print
                    type="ticket"
                    pic={capturedImage}
                    isImg={printwithImg}
                    qr={currentQR}
                    name={vistorname}
                    company={askcompany}
                    ticket={currentTicketID}
                    reload={true}
                    event={eventname}
                  />
                ) : (
                  ""
                )}
              </div>
              <div style={{ textAlign: "center" }}>
                {/* <Button onClick={printTicketPDF} style={{background:"red",border:"2px solid red#016fe8",color:"#fff",padding:"5px"}}>Print</Button>  */}
                {hideprintbtn ? (
                  ""
                ) : (
                  <Button
                    style={{ background: "green" }}
                    variant="contained"
                    color="primary"
                    disabled={AskForImage}
                    onClick={handlereactPrint}
                  >
                    Print Ticket
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {camerapopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb"
                id="camerapop"
                style={{ display: "inline-flex" }}
                onClick={closecamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera upload={setAskForImage} mcode={currentUserMcode} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {/* -------------------------------registration popup ---------------------------------- */}

        {Regshowpopup ? (
          <>
            <div className="userpopup">
              <div className="popupdata animatingBottom">
                <p
                  className="cross mb"
                  style={{ color: "#111", fontSize: "24px" }}
                  onClick={hidepopup}
                >
                  <ion-icon name="close-outline"></ion-icon>
                </p>
                <div
                  style={{ height: "80px", width: "400px", display: "flex" }}
                >
                  {/* <img className='animatingBottom' style={{padding:"20px"}} src={thisQrcode} /><br /> */}
                  <input type="hidden" value={currentregistraionMcode} />
                </div>
                <center>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                    }}
                  >
                    <label>Company:</label>
                    <input
                      type="text"
                      placeholder="Enter Company"
                      value={askcompany}
                      onChange={(e) => setaskcompany(e.target.value)}
                    />
                  </div>
                  <br />
                  <input
                    type="checkbox"
                    id="isPhoto"
                    checked={printwithImg}
                    onChange={(e) => handleRegCheck(e)}
                  />
                  <label>Photo Capture:</label>
                  {printwithImg ? (
                    <>
                      <input
                        type="file"
                        placeholder="Upload Photo"
                        onChange={(e) => handlePhotoPrint(e)}
                      />
                      <button
                        style={{
                          background: "#016fe8",
                          border: "2px solid #016fe8",
                          color: "#fff",
                          padding: "2px 6px",
                          position: "absolute",
                          right: "8%",
                        }}
                        onClick={UploadSelectedPhotoreg}
                        disabled={uploadfiledisable}
                      >
                        upload
                      </button>
                      <p
                        style={{
                          display: "inline-block",
                          transform: "scale(1.5)",
                        }}
                        onClick={handleRegisterCamera}
                      >
                        {" "}
                        <ion-icon name="camera-outline"></ion-icon>
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </center>

                {hideprintbtn ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center" }}>
                    {/* <Button style={{background:"#016fe8",border:"2px solid #016fe8",color:"#fff",padding:"5px"}} onClick={printThisQR}>Print QR</Button> */}
                    <Button
                      style={{ background: "green" }}
                      variant="contained"
                      color="primary"
                      disabled={AskForImage}
                      onClick={reactPrintRegistration}
                    >
                      Print Ticket
                    </Button>
                  </div>
                )}

                {RegPrinter ? (
                  <Print
                    type="ticket"
                    pic={capturedRegImage}
                    isImg={printwithImg}
                    qr={currentRegQr}
                    name={currentregistrationName}
                    company={askcompany}
                    ticket={currentregistrationTicketID}
                    reload={true}
                    event={eventname}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {regisPopup ? (
          <>
            <div className="userpopup animatingTop">
              <p
                className="cross mb "
                id="camerapop"
                style={{ display: "inline-flex" }}
                onClick={closeregcamera}
              >
                <ion-icon name="close-outline"></ion-icon>
              </p>

              <div className="popupdata" style={{ padding: "20px" }}>
                <Camera
                  upload={setAskForImage}
                  mcode={currentregistraionMcode}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default BuyTicket;
