import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../table/datagrid.css";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  URL_ALLEVENTS,
  URL_APPROVEUSER,
  URL_UPDATEEVENTS,
} from "../../api/constants";
import Cellpopup from "../Cellpopup";
import Alert from "../alert/alert";
import Cookies from "js-cookie";
import Loader from "../Loader";

function Approved() {
  const [all, setall] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [popupmsg, setpopupmsg] = useState("");
  const [finalClickInfo, setFinalClickInfo] = useState(null);
  const [thisuser, setthisuser] = useState([]);
  const [margin, setmargin] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  //   confirm alertbox
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");

  //alert color

  var red = "red";
  var green = "green";

  setTimeout(() => {
    setalert(false);
  }, 5000);

  const approveuser = (item) => {
    console.log(item.id);
    window.location.href = `/dashboard/itemmaster/eventdetails/${item.id}`;
  };

  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: "orgName", headerName: "Organiser Name", width: 200 },
    { field: "name", headerName: "Event Name", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionStartDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionEndDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "edit",
      headerName: "Add Margin",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button
            onClick={marginPercentage}
            variant="contained"
            color="primary"
          >
            ADD MARGIN
          </Button>
        );
      },
    },
    {
      field: "View Event",
      headerName: "View Event",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => approveuser(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Event
          </Button>
        );
      },
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button variant="contained" color="secondary"  > Delete </Button>
    //     );
    //   }
    // }
  ];

  // add margin

  const marginPercentage = () => {};

  // after updating we have to call all pending api again so we don't have to refresh it everytime
  const refresh = () => {
    var data = JSON.stringify({
      status: "PENDING",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setall(response?.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log("oops" + error);
      });
  };

  var user = Cookies.get("userType");
  var Orgmcode = Cookies.get("orgmcode");
  var Org = [];
  var approvedEvent = [];

  useEffect(() => {
    setIsLoading(true);
    document.querySelector(".pagenames").innerHTML = "Dashboard > Unapproved";
    setalert(true);
    setalertmsg("Click On Table Cell TO Approve !");

    var data = JSON.stringify({
      status: "PENDING",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response?.data.data);
        var mydata = response.data.data;

        var row = document.querySelector(".MuiDataGrid-overlay");
        // row.innerHTML = `No Event Found`;
        for (var i = 0; i < mydata.length; i++) {
          var today = new Date();
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].status === "PENDING" && today < EndDate) {
            approvedEvent.push(mydata[i]);
          }
        }
        if (user === "ORGANIZER") {
          console.log("hi theerrre");
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode == Orgmcode) {
              Org.push(mydata[i]);
            }
          }

          console.log("unnapprORG", Org);
          setall(Org);
          setIsLoading(false);
        } else {
          console.log("unaprovedEvent", approvedEvent);
          var uniqueRowpos = [];
          var uniqueEvents = approvedEvent.filter(function (event) {
            var rowpos = event.rowpos;
            if (uniqueRowpos.indexOf(rowpos) === -1) {
              uniqueRowpos.push(rowpos);
              return true;
            }
            return false;
          });
          setall(uniqueEvents);
          setIsLoading(false);
        }

        // refresh()
        // setalert(true)
        // setalertmsg("")
      })
      .catch(function (error) {
        console.log("oops" + error);
      });
  }, []);

  const handleOnCellClick = (params) => {
    setpopupcell(true);
    setFinalClickInfo(params);
    setthisuser(params);

    // setpopupmsg(params.row)

    // alert(params.id)
  };

  const unapproveuser = (thisuser) => {
    if (margin > 100) {
      setpopupcell(false);
      setalert(true);
      setalertmsg(`Margin Percentage should not be greater than 100 `);
    } else {
      var name = thisuser.row.name;
      setpopupcell(false);
      var id = thisuser.id;
      console.log(id);

      var data = JSON.stringify({
        Status: "APPROVED",
        ROWPOS: id,
        dMarginPCNT: margin,
      });

      var config = {
        method: "post",
        url: URL_APPROVEUSER,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          setalert(true);
          setalertmsg(`✔ ${name} has been Approved`);
          refresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  // send to draft

  const sendtodraft = () => {
    var name = thisuser.row.name;

    setpopupcell(false);
    var id = thisuser.id;

    var data = JSON.stringify({
      Status: "DRAFT",
      ROWPOS: id,
    });

    var config = {
      method: "post",
      url: URL_UPDATEEVENTS,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setalert(true);
        setalertmsg(`✔ ${name} has been Added to Draft`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="alertposition ">
        {alert ? <Alert msg={alertmsg} color={green} /> : ""}
      </div>
      {popupcell ? (
        <div className="detailbox animatingTop">
          <button className="cross" onClick={() => setpopupcell(false)}>
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <span>Are you sure to approve or draft </span>
          <label style={{ marginLeft: "35px" }}>Margin : </label>
          <input
            type="number"
            style={{ width: "50px", marginLeft: "15px" }}
            value={margin}
            onChange={(e) => setmargin(e.target.value)}
          />
          %
          <Button
            className="animatingBottom"
            style={{
              borderRadius: 5,
              backgroundColor: "green",
              fontSize: "12px",
              float: "right",
              marginRight: "140px",
              marginTop: "20px",
            }}
            onClick={() => unapproveuser(thisuser)}
            variant="contained"
            color="primary"
          >
            Approve
          </Button>
          <Button
            className="animatingBottom"
            style={{
              borderRadius: 5,
              backgroundColor: "rgb(9, 65, 250)",
              fontSize: "12px",
              position: "absolute",
              right: "3px",
              bottom: "3px",

              marginTop: "20px",
            }}
            onClick={() => sendtodraft(thisuser)}
            variant="contained"
            color="primary"
          >
            DRAFT
          </Button>
        </div>
      ) : (
        ""
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboardtable animatingBottom dashtbl">
          <DataGrid
            className=""
            rows={all}
            columns={column}
            pagination={false}
            getRowId={(row) => row.rowpos}
            onCellClick={handleOnCellClick}
            components={{
              Toolbar: GridToolbarExport,
            }}
          />
        </div>
      )}
    </>
  );
}

export default Approved;
