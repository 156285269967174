import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function ImageCropper() {
  const [src, setSrc] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100, // Default width
    height: 50, // Default height
  });

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setImageUrl(""); // Reset imageUrl when a new image is selected
      }, false);

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getCroppedImage = () => {
    if (src) {
      const croppedImageUrl = getCroppedImageFromSrc(src, crop);
      setImageUrl(croppedImageUrl);
    }
  };

  const getCroppedImageFromSrc = (src, crop) => {
    const image = new Image();
    image.src = src;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg"); // You can change the format if needed
  };

  return (
    <div className="">
      <div>
        <input type="file" onChange={onSelectFile} />
      </div>
      {src && (
        <div>
          <ReactCrop
            locked={true}
            crop={crop}
            onImageLoaded={() => {}}
            onChange={(newCrop) => {
              setCrop(newCrop);
              getCroppedImage();
            }}
            
          >
            <img src={src} />
          </ReactCrop>
        </div>
      )}

    

      {imageUrl && (
        <div>
          <center>
            <img src={imageUrl} alt="Cropped" />
          </center>
        </div>
      )}
    </div>
  );
}

export default ImageCropper;
