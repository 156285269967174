import React from 'react'
import Navbar from './navbar';
import noticketimg from "../images/noticket.png"

function Verifyticketnotfound() {
  return (
    <>
     <Navbar />
       <center>  
        <img className='noticketbefore' src={noticketimg} />
       <div className=''><h3 style={{color:"rgb(55, 108, 255",marginTop:"-100px",fontWeight:"900"}}>NO TICKET DETAILS FOUND</h3></div>
       </center>    
    </>
  )
}

export default Verifyticketnotfound;