import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { URL_UPLOADIMAGE, URL_UPLOADProfileImg } from '../api/constants';
import Button from '@mui/material/Button';
import SearchableDropdown from './itemmaster/SearchableDropdown';


function ExternalDevice(mcode) {

    
    const webcamRef = useRef(null);
    const [image,setimage] = useState("")
    const [file,setfile] =useState([])
    const [searchval,setsearchval]= useState("search Camera")
    const [deviceArr, setdeviceArr] = useState([])
    const [thisdevice,setthisdevice] = useState("")

    

    const videoConstraints = {
        width: 1890,
        height: 1220,
        facingMode: "user"
      };

useEffect(()=>{
  navigator.mediaDevices.enumerateDevices().then(devices => {
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    console.log(videoDevices);
    setdeviceArr(videoDevices)
  });
  
},[])

const handlesearchval =(val)=>{
console.log(val)
setthisdevice(val)
 
 
}

   
      const capture = useCallback(
        () => {
            
            console.log(webcamRef.current.getScreenshot())
          var img =webcamRef.current.getScreenshot()
          setimage(img)
            var data = img.toString().replace(/^data:image\/webp;base64,/, "");
            
            // var buf = Buffer.from(data, 'base64');
            // setimage(data)
            // console.log(data)
            
        },
        [webcamRef]
      );
     
      function urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }
    
        //Usage example:
          urltoFile(image, 'profile.jpeg','image/jpeg')
          .then(function(file){
            
            setfile(file)
            });
        

      

       
     
       const upload =()=>{
        var FormData = require('form-data');
        var data = new FormData();
        data.append('File', file);
        data.append('Type', 'ORDER');
        data.append('Id', mcode.mcode);
        data.append('SubType', 'ProfilePhoto');
        
        var config = {
          method: 'post',
          url: URL_UPLOADIMAGE,
          headers: { 
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          console.log(response.data);
          if(response.data.isSuccess === true){
           alert("Image Uploaded ")
           document.getElementById('camerapop').click()
           mcode.upload(false)
          }
          else{
            alert(response.data.message)
          }
        
        })
        .catch(function (error) {
          console.log(error);
        });
       }
                                                                                                                                                             
      
       const jsonArray = deviceArr?.map(item => {
        return {
          id: item.deviceId,
          name: item.label,
        
        };
      });
    


  return (
    
    <>
  
  <SearchableDropdown
              options={jsonArray}
              label="name"
              id="rowpos"
              selectedVal={searchval}
              handleChange={(val) =>  setsearchval(val)}
              handleChangeval={(val) =>handlesearchval(val) }
            />


      <Webcam
       screenshotFormat='image/jpeg' 
       ref={webcamRef}
       audio={false}
       videoConstraints={{ deviceId: thisdevice}} 
        />
        <div className='flex-button'>
            <Button variant='contained' onClick={capture}>capture image</Button>
            <Button style={{background:"green",color:'#fff'}} onClick={upload} >Upload</Button>
        </div>
    </>
  )
}

export default ExternalDevice ;