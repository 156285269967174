import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../table/datagrid.css";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Api, {
  UnApprovedEvents,
  URL_ALLEVENTS,
  URL_APPROVEUSER,
  URL_GET_COMPANY_DETAILS,
  URL_UPDATE_ORGANISER_EVENT,
} from "../../api/constants";
import Cellpopup from "../Cellpopup";
import Alert from "../alert/alert";
import Cookies from "js-cookie";
import Loader from "../Loader";

function Approved() {
  const [all, setall] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [popupmsg, setpopupmsg] = useState("");
  const [finalClickInfo, setFinalClickInfo] = useState(null);
  const [thisuser, setthisuser] = useState([]);
  const [orgname, setorgname] = useState([]);
  const [dropdown, setdropdown] = useState([]);
  const [forApprove, setforApprove] = useState(true);
  const [thisorganiser, setthisorganiser] = useState("");
  const [thisEvent, setthisEvent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   confirm alertbox
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");

  setTimeout(() => {
    setalert(false);
  }, 7000);

  const changeOrganiser = (item) => {
    console.log(item.row.rowpos);
    console.log(item.row.orgMcode);

    setthisEvent(item.row.rowpos);
    setthisorganiser(item.row.orgMcode);
    setforApprove(false);
  };

  const approveButton = () => {
    setforApprove(true);
  };

  const approveuser = (item) => {
    setforApprove(false);
    console.log(item.id);
    window.location.href = `/dashboard/itemmaster/eventdetails/${item.id}`;
  };

  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: "orgName", headerName: "Organiser Name", width: 200 },
    { field: "name", headerName: "Event Name", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionStartDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionEndDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button variant="contained" color="primary" onClick={approveButton}>
            APPROVE
          </Button>
        );
      },
    },
    {
      field: "View Event",
      headerName: "View Event",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => approveuser(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Event
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Change Organisor",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => changeOrganiser(params)}
          >
            Change Organisor
          </Button>
        );
      },
    },
  ];

  const refresh = () => {
    var data = JSON.stringify({
      status: "DRAFT",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setall(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log("oops" + error);
      });
  };

  //data by organisor
  var user = Cookies.get("userType");
  var Orgmcode = Cookies.get("orgmcode");
  var OrgDRAFT = [];

  useEffect(() => {
    setIsLoading(true);
    document.querySelector(".pagenames").innerHTML = "Dashboard > Draft";
    var data = JSON.stringify({
      status: "DRAFT",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        var mydata = response.data.data;
        var row = document.querySelector(".MuiDataGrid-overlay");
        // row.innerHTML = `No Event Found`;
        for (var i = 0; i < mydata.length; i++) {
          var today = new Date();
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].status === "DRAFT" && today < EndDate) {
            OrgDRAFT.push(mydata[i]);
          }
        }

        if (user === "ORGANIZER") {
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode == Orgmcode) {
              OrgDRAFT.push(mydata[i]);
            }
          }
          console.log("orgdd", OrgDRAFT);
          setall(OrgDRAFT);
          setIsLoading(false);
        } else {
          var uniqueRowpos = [];
          var uniqueEvents = OrgDRAFT.filter(function (event) {
            var rowpos = event.rowpos;
            if (uniqueRowpos.indexOf(rowpos) === -1) {
              uniqueRowpos.push(rowpos);
              return true;
            }
            return false;
          });

          setall(uniqueEvents);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log("oops" + error);
      });
  }, []);

  // now append organiser dropdown for change organsor ( company wise )

  useEffect(() => {
    var allcompany = [];
    var config = {
      method: "get",
      url: URL_GET_COMPANY_DETAILS,
      headers: {},
    };

    axios(config).then(function (response) {
      console.log(response.data.data);
      for (var i = 0; i < response.data.data.length; i++) {
        if (response.data.data[i].strCompanyName !== "") {
          allcompany.push(response.data.data[i]);
        }
      }
      console.log(allcompany);

      setdropdown(allcompany);
    });
  }, []);

  const handleOnCellClick = (params) => {
    setpopupcell(true);
    setFinalClickInfo(params);
    setthisuser(params);
    // setpopupmsg(params.row)

    // alert(params.id)
  };

  const unapproveuser = (thisuser) => {
    var name = thisuser.row.name;
    console.log(thisuser);
    setpopupcell(false);
    var id = thisuser.id;
    console.log(id);

    var data = JSON.stringify({
      Status: "APPROVED",
      ROWPOS: id,
    });

    var config = {
      method: "post",
      url: URL_APPROVEUSER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setalert(true);
        setalertmsg(`✔ ${name} has been Approved`);
        refresh();
      })
      .catch(function (error) {
        console.log("oops" + error);
      });
  };

  const handleOrgUpdate = () => {
    console.log(thisorganiser);
    console.log(thisEvent);

    if (orgname === "") {
      setalert(true);
      setalertmsg("Please Select The Company !");
    } else {
      var data = JSON.stringify({
        OrgMcode: orgname,
        ROWPOS: thisEvent,
      });

      var config = {
        method: "post",
        url: URL_UPDATE_ORGANISER_EVENT,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data.data);
          var res = response.data.isSuccess;

          if (res === true) {
            setorgname("");
            document.querySelector(".cross").click();
            setalert(true);
            setalertmsg("Organisor  updated Successfully  !!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handlecross = () => {
    setpopupcell(false);
    setorgname("");
  };

  var color = "green";

  return (
    <>
      <div className="alertposition ">
        {alert ? <Alert className="" msg={alertmsg} color={color} /> : ""}
      </div>
      {popupcell ? (
        <div className="detailbox">
          <button className="cross" onClick={handlecross}>
            <ion-icon name="close-outline"></ion-icon>
          </button>
          {forApprove ? (
            <>
              <span>
                <b>Id:</b>
                {thisuser.id}
              </span>
              <span>
                <b>Name:</b>
                {thisuser.row.name}
              </span>
              <span>
                <b>Description:</b>
                {thisuser.row.summary?.slice(0, 40)}
              </span>
              <span>
                <b>Venue Type :</b>
                {thisuser.row.venueType}
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <span>
                  <b>Start Time: &nbsp;&nbsp;&nbsp;</b>
                  {thisuser.row.exhibitionStartTime}
                </span>
                <span>
                  <b>End Time: &nbsp;&nbsp;&nbsp;</b>
                  {thisuser.row.exhibitionStartTime}
                </span>
              </div>
              <Button
                style={{
                  borderRadius: 5,
                  backgroundColor: "green",
                  padding: "1px 6px",
                  fontSize: "12px",
                  float: "right",
                  marginRight: "140px",
                  marginTop: "20px",
                }}
                onClick={() => unapproveuser(thisuser)}
                variant="contained"
                color="primary"
              >
                APPROVE
              </Button>
            </>
          ) : (
            <>
              <div className="mb-3 ">
                <label>Choose Company</label>
                <br />
                <select
                  className="das"
                  value={orgname}
                  onChange={(e) => setorgname(e.target.value)}
                >
                  <option value="">Select Company</option>
                  {dropdown.map((item, i) => (
                    <>
                      <option value={item.mcode}>{item.strCompanyName}</option>
                    </>
                  ))}
                </select>
                <button
                  style={{
                    borderRadius: 5,
                    backgroundColor: "green",
                    border: "1px solid green",
                    fontSize: "12px",
                    marginTop: "20px",
                    color: "#FFF",
                    width: "100px",
                    textAlign: "center",
                  }}
                  variant="contained"
                  onClick={handleOrgUpdate}
                >
                  UPDATE
                </button>
              </div>
            </>
          )}
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboardtable dashtbl">
          <DataGrid
            className=""
            rows={all}
            columns={column}
            pagination={false}
            getRowId={(row) => row.rowpos}
            onCellClick={handleOnCellClick}
            components={{
              Toolbar: GridToolbarExport,
            }}
          />
        </div>
      )}
    </>
  );
}

export default Approved;
