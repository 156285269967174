import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../components/navbar.css";
// import logo from "../images/logo.png"
import accexLogo from "../images/accexlogo.png";
import Cookies from "js-cookie";

function Navbar(props) {
  const [search, setsearch] = useState("");
  const [popupshow, setpopupshow] = useState(false);

  const searchInput = (e) => {
    setsearch(e.target.value);
  };

  const popup = () => {
    setpopupshow(true);
  };
  const removepoup = () => {
    setpopupshow(false);
  };

  const handlelogout = () => {
    window.location.href = "/";
    localStorage.setItem("login", false);
    Cookies.remove("orgName");
    Cookies.remove("orgmcode");
    Cookies.remove("userType");
    localStorage.clear();
  };
  return (
    <>
      <nav>
        <div className="logo">
          <img src={accexLogo} />
        </div>
        {/* <div className="searchBox">
          <input type="text" placeholder="search" value={search} onChange={searchInput}></input>
        </div> */}
        <p className="username"> {props.name}</p>
        <div className="flex">
          {/* <div className="navbutton"></div>
          <div className="navbutton"></div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "60px",
              fontSize: "20px",
            }}
          >
            <Link to="/qrcode">
              <div className="scaner">
                <ion-icon name="scan-outline"></ion-icon>
              </div>
            </Link>

            <div className="navbutton" onClick={popup}>
              {" "}
              <ion-icon name="power-outline"></ion-icon>
            </div>
          </div>
        </div>
      </nav>
      {popupshow ? (
        <>
          <div style={{ position: "absolute", left: "60%" }}>
            <div
              className="userpopup asklogout"
              style={{ padding: "10px", height: "100px" }}
            >
              <p className="cross" onClick={removepoup}>
                <ion-icon name="close-outline"></ion-icon>
              </p>
              Are You Sure To LOGOUT <br />
              <button
                style={{
                  borderRadius: 5,
                  backgroundColor: "#0169df",
                  padding: "6px 18px",
                  fontSize: "14px",
                  float: "right",
                  color: "#fff",
                  border: "2px solid #0169df",
                  marginRight: "20px",
                }}
                onClick={handlelogout}
              >
                LOGOUT
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Navbar;
