import React, { useState } from 'react'
import Leftnav from '../leftNav/Leftnav';
import Navbar from '../navbar';
// import "../offers/offers.css"
import { DataGrid } from '@mui/x-data-grid';


const columns = [
  { field: 'id', headerName: 'ID' },
  { field: 'title', headerName: 'Title', width: 300 },
  { field: 'body', headerName: 'Body', width: 600 }
]

function Offers() {

  const [offer, setoffers] = useState([])



  //axois call here


  return (
    <>
          
          <Navbar   />
    <Leftnav />
    <div className='main '>
    <div className="">
        <form className="d-flex space" autocomplete="off">
          <div className="mb-3">
            <label className="form-label">offers</label>
            <input type="text" className="form-control small" />
            <div className="inpalert" id="contactAlert"></div>
          </div>
          <div className="mb-3">
            <label className="form-label">latest offers</label>
            <input type="text" className="form-control small"  />
            <div className="inpalert" id="contactAlert"></div>
          </div>
          <div className="mb-3">
            <label className="form-label">50% off</label>
            <input type="text" className="form-control small"  />
            <div className="inpalert" id="contactAlert"></div>
          </div>
        </form>
        </div>
    <DataGrid
      className='table'
        rows={offer}
        columns={columns}
        pageSize={10}
        
      >
        
      </DataGrid>
    </div>
   
      </>
  )
}

export default Offers;