import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'

import { useLocation } from "react-router-dom";
import { URL_GETtCKTbyID, URL_GET_ORDER_TICKETID } from '../api/constants';
import Leftnav from '../components/leftNav/Leftnav'
import Navbar from './navbar';

function Ticket() {


  const [detail, setdetail] = useState([])
  const [Array,setarray]= useState([])
  const [regdata,setrregdata] = useState(true)
  const [typ, settyp] = useState([])
  const [regname,setregname] = useState("")
  const [regprefix,setregprefix] = useState("")
  const [regsurname,setregsurname] = useState("")
  const [regemail,setregemail] = useState("")
  const [regjobTitle,setregjobTitle] = useState("")
  const [regworkPhone,setregworkPhone] = useState("")
  const [regcompanyOrganisation,setregcompanyOrganisation] = useState("")
  const [tableData,settableData] =useState([])

  const search = useLocation().search;
  var name = new URLSearchParams(search).get('name');
  var event_Id = new URLSearchParams(search).get('event');
 
  var user = Cookies.get("userType");


 const columns = [
  { field: 'strCustomerName', headerName: 'Customer Name', width: 130   },
  { field: 'orgName', headerName: 'Organiser Name', width: 130 },
  { field: 'strTicketID', headerName: 'Ticket Id', width: 180 },
  { field: 'billNumber', headerName: 'Order Number', width: 150 },
  {
    field: "billDate",
    headerName: "Order Date",
    width: 180,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return params.row.billDate
        .split("T")[0]
        .split("-")
        .reverse()
        .join()
        .replace(",", "-")
        .replace(",", "-");
    },
  },
  
  { field: 'pcQuantity', headerName: 'Quantity', width: 180 },
  {
    field: "balanceAmount",
    headerName: "Amount",
    width: 180,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return params.row.balanceAmount * params.row.pcQuantity;
       
    },
  },
  { field: 'remark', headerName: 'Status', width: 150 },
 ]

useEffect(()=>{
  var thisticketsale = []

  console.log(name)
var config = {
  method: 'get',
  url: URL_GET_ORDER_TICKETID,
  headers: { }
};

axios(config)
.then(function (response) {
  console.log(response.data.data)

for(var i =0 ; i< response.data.data.length ; i++){
  if(response.data.data[i].strTicketID === name){
    thisticketsale.push(response.data.data[i])
  }
}
console.log(thisticketsale)
  settableData(thisticketsale) 
})
.catch(function (error) {
  console.log(error);
});


  var data = JSON.stringify({
    "TicketId": name,
    "EventId":event_Id
  });

  var config = {
    method: 'post',
    url: URL_GETtCKTbyID,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };

  axios(config)
    .then(function (response) {
      console.log(response.data)
      setdetail(response.data.data)
      const array = [];
      array.push(response.data.data[0])
      
     
      setarray(array)
    
      if(response.data.data[0].register === null){
        setrregdata(false)
      }
      settyp(response.data.data[0].ticketType)
      setregprefix(response.data.data[0].register.prefix)
      setregname(response.data.data[0].register.name)
      setregsurname(response.data.data[0].register.surname)
      setregemail(response.data.data[0].register.email)
      setregjobTitle(response.data.data[0].register.jobTitle)
      setregworkPhone(response.data.data[0].register.workPhone)
      setregcompanyOrganisation(response.data.data[0].register.companyOrganisation)
    })
    .catch(function (error) {
      console.log(error);
    });
    
},[name])



  return (
    <>
    <Navbar />
    <Leftnav />
   <div className='main ticketDetailspage' >
   <h3 className="eventname"> SOLD {typ} </h3>
      <br />
      {
        Array.map((item,i) => {
          if(user === "ORGANIZER"){
            return(
              <>
               
                   <div className='table animatingBottom ' style={{height:"500px"}}>
       
                      <DataGrid
                      rows={tableData}
                      columns={columns}
                      pageSize={6}
                      getRowId={(row) => row.rowpos}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = tableData.filter((row) =>
                            selectedIDs.has(row.rowpos)
                          );
                        }}
                        {...tableData}
                      />
                    </div>
             </>
                 )
             }
            else{
          
            return(
              <>
            
                 <div className='table animatingBottom' style={{height:"500px",marginLeft:"120px"}}>
       
                        <DataGrid
                      rows={tableData}
                      columns={columns}
                      pageSize={6}
                      getRowId={(row) => row.rowpos}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = tableData.filter((row) =>
                            selectedIDs.has(row.ticketId)
                          );
                        }}
                        {...tableData}
                     />
                </div>
          
              </>
            )
          }
        })
      }
      {/* {
        regdata ?
        <>
          <center> <h3 className="eventname" style={{fontSize:"24px"}}>REGISTRATION DETAILS</h3></center>
     
     <div className="eventdetails" style={{ height: "50vh" }}  >
      <div>
             <p><span>PREFIX :</span>{regprefix}</p> 
             <p><span>NAME :</span>{regname}</p> 
           <p><span>SURNAME :</span>{regsurname}</p> 
            <p><span>EMAIL :</span>{regemail}</p> 
      </div>
      <div>
            <p><span>JOB TITLE : </span>{regjobTitle}</p> 
            <p><span>PHONE NO :</span>{regworkPhone}</p> 
              <p><span>COMPANY ORGANISATION :</span>{regcompanyOrganisation}</p> 
      </div>
    </div>

        </>:""
      } */}
      
  
   </div>
 
    </>
  )
}

export default Ticket