import react, { useState } from 'react';
import './App.css';
import Navbar from './components/navbar';
import Navigation from './components/Navigation';
import {
  BrowserRouter as Router, Routes , Route} from "react-router-dom";
  import Rojezpay from './components/RozerPay/RozerPay';
import StudentForm from './components/login';
import Dashboard from './components/dashboard/dashboard';
import Tagmaster from './components/tagmaster/tagmaster';
import Brandmaster from './components/brandmaster/brandmaster';
import Itemmaster from './components/itemmaster/itemmaster';
import Usermanager from './components/usermanager/usermanager';
import CategoryMaster from './components/categorymaster/categorymaster';
import Ordermaster from './components/ordermaster/ordermaster';
import Supportticket from './components/supportticket/supportticket';
import Reports from './components/reports/reports';
import Offers from './components/offers/offers';
import ProtectedRoute from "./components/ProtectedRoute.js"
import Notfound from './components/Notfound';
import { useEffect } from 'react';
import Eventdetails from './components/itemmaster/Eventdetails';
import Loader from './components/Loader';
import Qrcode from './components/Qrcode';
import Ticket from './components/Ticket';
import Verifyticket from './components/Verifyticket';
import Verifyticketnotfound from './components/verifyticketnotfound';
import Scanner from './components/Scanner';
import Banner from "./components/Banner"
import Addbanner from './components/Addbanner';
import Ranking from './components/Ranking';
import Cookies from 'js-cookie'
import NouserFound from './components/NouserFound';
import BuyTicket from './components/BuyTicket';
import OnSpotBooking from './components/OnSpotBooking';
import Camera from './components/Camera';
import Toast from './components/Toast';
import ReactToPrint from './components/ReactToPrint';
import Print from './components/ReactToPrint';
import CreateQr from './components/CreateQr';

import TicketApproval from './components/TicketApproval';
import Linechart from './components/Linechart';
import ExhibitorRanking from './components/ExhibitorRanking';
import ExternalDevice from './components/ExternalDevice';
import ImageCropper from './components/ImageCropper'

function App() {

  const [name,setname] = useState("")
  const [userType,setuserType] = useState("")
  function Parentcomponenent(myname){
    // alert(myname)
    // setname(myname)
    // console.log(myname);
  
    }
    
  const handleprivacypoicy =() =>{
    console.log("clicked")
    window.location.href = 'https://accex-api.saitec.in//Accex_Policy/Terms&Conditions.pdf';
  }

var userCode = Cookies.get('orgmcode');

if(userCode===undefined||userCode===""||userCode===null)
{
return(
  <Router>
      <Routes>
  <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
  <Route  path="*"  element={ <StudentForm alert={Parentcomponenent}  />} />
  </Routes>
  </Router>
)
}
else
{
  var user = Cookies.get('userType')
  // console.log(user)
  if(user === 'REPORTING'){
 return (
    <>
    <Router>
    <div className="App">
    
 
        <Routes>
        <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
        <Route exact path="/dashboard/reports"  element={<Reports />} />

        <Route  path="*"  element={<Notfound />} />
        <Route exact path="/dashboard/rojezpay" element={<Rojezpay />} />
     </Routes>
    </div>
    </Router>
    </>
  );
  }

  else if( user === "TICKETMASTER"){
    return (
      <>
      <Router>
      <div className="App">
      
   
          <Routes>
          <Route exact path="/dashboard/rojezpay" element={<Rojezpay />} />
          <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
          <Route  exact path="/qrcode"  element={<Qrcode />} />
          <Route exact path="/dashboard/ordermaster"  element={<Ordermaster />} />
          <Route  exact path="/BuyTicket"  element={<BuyTicket />} />
           <Route  exact path="/OnSpotBooking"  element={<OnSpotBooking />} />
          <Route  path="*"  element={<Notfound />} />
     
       </Routes>
      </div>
      </Router>
      </>
    );
  }
  
  else if(user === "EVENTMASTER"){
    debugger;
    return (
      <>
      <Router>
      <div className="App">
          <Routes>
          {/* <Route exact path="/dashboard/rojezpay" element={<Rojezpay />} /> */}
          <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
          <Route exact path="/dashboard"  element={<Dashboard  />} />
          <Route exact path="/dashboard/itemmaster"  element={<Itemmaster  />} />
        <Route exact path="/dashboard/itemmaster/eventdetails/:id"  element={<Eventdetails  />} />
        <Route exact path="/dashboard/reports"  element={<Reports />} />
        <Route  exact path="/BuyTicket"  element={<BuyTicket />} />
        <Route  exact path="/OnSpotBooking"  element={<OnSpotBooking />} />
         <Route  path="*"  element={<Notfound />} />
       </Routes>
      </div>
      </Router>
      </>
    );
  }

  else if(user === "VISITOR"){
    return (
      <>
      <Router>
      <div className="App">
      
   
          <Routes>
       
          <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
     
       </Routes>
      </div>
      </Router>
      </>
    );
  }


  else if(user === "ORGANIZER"){
    return (
      <>
      <Router>
      <div className="App">
      
   
          <Routes>
       
          <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}  />} />
        <Route exact path="/dashboard"  element={<ProtectedRoute Component={Dashboard}  />} />
        <Route exact path="/das" element={<Dashboard name={name} />} />
        <Route exact path="/dashboard/tagmaster"  element={<Tagmaster  />} />
        <Route exact path="/dashboard/brandmaster"  element={<Brandmaster  />} />
        <Route exact path="/dashboard/itemmaster"  element={<Itemmaster  />} />
        
        <Route exact path="/dashboard/itemmaster/eventdetails/:id"  element={<Eventdetails  />} />
        <Route exact path="/dashboard/itemmaster/eventdetails/:id/:code"  element={<Ticket  />} />
        
        <Route exact path="/dashboard/categorymaster"  element={<CategoryMaster />} />
        <Route exact path="/dashboard/ordermaster"  element={<Ordermaster />} />
        <Route exact path="/dashboard/supportsticket"  element={<Supportticket />} />
        <Route exact path="/dashboard/offers"  element={<Offers />} />
        <Route exact path="/dashboard/reports"  element={<Reports />} />
        <Route  path="*"  element={<Notfound />} />
        <Route  exact path="/load"  element={<Loader />} />
        <Route  exact path="/qrcode"  element={<Qrcode />} />
        <Route  exact path="/verifyticket"  element={<Verifyticket />} />
        <Route  exact path="/carousel"  element={<Banner />} />
        <Route  exact path="/dashboard/ranking"  element={<Ranking />} />
       
        <Route  exact path="/banner"  element={<Addbanner />} />
        <Route  exact path="/verifyticket/notfound"  element={<Verifyticketnotfound />} />
       </Routes>
      </div>
      </Router>
      </>
    );
  }



  return (
    <>
    <Router>
    <div className="App">
    
  
        <Routes>
        <Route exact path="/dashboard/rojezpay" element={<Rojezpay />} />
        <Route exact path="/"  element={ <StudentForm alert={Parentcomponenent}    />} />
        <Route exact path="/dashboard"   element={<Dashboard  userCode={userCode}/>} />
        <Route exact path="/das" element={<Dashboard name={name} />} />
        <Route exact path="/dashboard/tagmaster"  element={<Tagmaster  />} />
        <Route exact path="/dashboard/brandmaster"  element={<Brandmaster  />} />
        <Route exact path="/dashboard/itemmaster"  element={<Itemmaster  />} />
        
        <Route exact path="/dashboard/itemmaster/eventdetails/:id"  element={<Eventdetails  />} />
        <Route exact path="/dashboard/itemmaster/eventdetails/:id/:code"  element={<Ticket  />} />
        <Route exact path="/dashboard/usermanager"  element={<Usermanager  />} />
        <Route exact path="/dashboard/categorymaster"  element={<CategoryMaster />} />
        <Route exact path="/dashboard/ordermaster"  element={<Ordermaster />} />
        <Route exact path="/dashboard/supportsticket"  element={<Supportticket />} />
        <Route exact path="/dashboard/offers"  element={<Offers />} />
        <Route exact path="/dashboard/reports"  element={<Reports />} />
        <Route  path="*"  element={<Notfound />} />
        <Route  exact path="/load"  element={<Loader />} />
        <Route  exact path="/qrcode"  element={<Qrcode />} />
        <Route  exact path="/verifyticket"  element={<Verifyticket />} /> 
        <Route  exact path="/TicketApproval"  element={<TicketApproval />} />
        <Route  exact path="/carousel"  element={<Banner />} />
        <Route  exact path="/dashboard/ranking"  element={<Ranking />} />
        <Route  exact path="/dashboard/Exhibitorranking"  element={<ExhibitorRanking />} />
        <Route  exact path="/banner"  element={<Addbanner />} />
        <Route  exact path="/verifyticket/notfound"  element={<Verifyticketnotfound />} />
        <Route  exact path="/BuyTicket"  element={<BuyTicket />} />
        <Route  exact path="/OnSpotBooking"  element={<OnSpotBooking />} />
        <Route  exact path="/camera"  element={<Camera />} />
        <Route  exact path="/webcam"  element={<ExternalDevice />} />
        <Route  exact path="/toast"  element={<Toast />} />
        <Route  exact path="/Print"  element={<Print />} />
        <Route  exact path="/qr"  element={<CreateQr />} />
        <Route  exact path="/linechart"  element={<Linechart />} />
        <Route  exact path="/crop"  element={<ImageCropper />} />
        
        
        
        
        {/* <ProtectedRoute path="/dashboard" elememt={<Dashboard />}  auth={true} /> */}
     </Routes>
     <p className='privacypolicy' onClick={handleprivacypoicy}>privacy policy</p>
    </div>
    </Router>
    </>
  );
}
}

export default App;

