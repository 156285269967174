import { Button } from "@mui/material";
import { color } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { URL_ALLEVENTS, URL_UPLOADIMAGE } from "../api/constants";
import Alert from "./alert/alert";
import Banner from "./Banner";
import Leftnav from "../components/leftNav/Leftnav";
import Navbar from "./navbar";
import { ReactCrop } from "react-image-crop";

function Addbanner() {
  const [eventdata, seteventdata] = useState([]);
  const [eventData, setEventData] = useState({});
  const [event, setevent] = useState("");
  const [selectedimg, setselectedimg] = useState("");
  const [showBanner, setshowBanner] = useState(true);

  const [UploadImgWidth, setUploadImgWidth] = useState("");
  const [UploadImgHeight, setUploadImgHeight] = useState("");

  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const [deleteddate ,setdeleteeddata] = useState()

  const [src, setSrc] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [crop, setCrop] = useState({
    unit: "%",
    width: 80, // Default width
    height: 70, // Default height
  });

  setTimeout(() => {
    setalert(false);
  }, 5000);

  useEffect(() => {
    setCrop({
      unit: "%",
      width: 80, // Default width
      height: 70, // Default height
    });
  }, [src]);

  useEffect(() => {

    axios.post(URL_ALLEVENTS, { status: "ALL" }).then(function (response) {

      if (response.data.isSuccess == true) {

        seteventdata(response.data.data);
      }

    });
  }, [event]);


  // const onImageChange = (event) => {

  //   setselectedimg(event.target.files[0])

  // }

  const addbannerbyid = () => {


    var FormData = require("form-data");
    var data = new FormData();
    data.append("File", selectedimg);
    data.append("Type", "BANNER");
    data.append("Id", event);
    data.append("SubType", "HOME");

    var config = {
      method: "post",
      url: URL_UPLOADIMAGE,
      headers: {},
      data: data,
    };

    axios(config)
      .then(function (response) {

        if (response.data.isSuccess == true) {
          setalert(true);
          setalertmsg(`✔ Banner  has been added successfully !`);
          setshowBanner(true);
        } else {
          setalert(true);
          setalertmsg(`SOMETHING WENT WRONG!`);
          setshowBanner(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // banner croping functiono

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);

          setImageUrl(""); // Reset imageUrl when a new image is selected
        },
        false
      );

      reader.readAsDataURL(e.target.files[0]);
      setshowBanner(false);
    }

    // now get the size of Uploaded Image
    getImageSize(e.target.files[0]);
  };

  function getImageSize(selectedFile) {
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = new Image();
        img.src = e.target.result;

        img.onload = function () {
          setUploadImgHeight(img.height);
          setUploadImgWidth(img.width);
        };
      };

      reader.readAsDataURL(selectedFile);
    }
  }

  const getCroppedImage = () => {
    if (src) {
      const croppedImageUrl = getCroppedImageFromSrc(src, crop);
      setImageUrl(croppedImageUrl);

      urltoFile(croppedImageUrl, "profile.jpeg", "image/jpeg").then(function (
        file
      ) {

        setselectedimg(file);
      });
    }
  };

  const getCroppedImageFromSrc = (src, crop) => {
    const image = new Image();
    image.src = src;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;



    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg"); // You can change the format if needed
  };

  // here convert the image type to = > file type

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  // // function to set isLoading if banner is loading :-
  // function setLoaderIsLoadingOrNot() {
  //   setIsLoading(!isLoading);
  // }

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? <Alert className="top animatingTop" msg={alertmsg} /> : ""}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Add Banners</h5>
        </center>
        <br />

        <div className="d-flex space">

          <div className="mb-3 animatingTop">
            <label className="form-label">Select Event</label>
            <br />
            <select
              className="selectinp small"
              value={event}
              onChange={(e) => setevent(e.target.value)}
            >
              <option value="">Select Event</option>
              {eventdata.map((item, i) => {

                return (
                  <option key={item.rowpos} value={item.rowpos}>
                    {item.name || 'Unnamed Event'}
                  </option>
                );
              })}
            </select>
          </div>



          <div className="mb-3 animatingTop">
            <label className="form-label">UPLOAD BANNER</label>
            <input
              type="file"
              onChange={onSelectFile}
              className="filetype form-control small animatingTop"
              id="group_image"
            />
          </div>
          <div className="mb-3 animatingBottom">
            <br />
            <Button
              style={{ marginLeft: "40px" }}
              variant="contained"
              onClick={addbannerbyid}
            >
              ADD BANNER
            </Button>
          </div>
        </div>

        {showBanner ? (
          <Banner />
        ) : (
          <>
            <center>
              <p className="bannerimgMsg">
                Uploaded Image Size ({" "}
                <span style={{ color: "blue" }}>
                  {UploadImgWidth}x{UploadImgHeight}
                </span>
                )px ! Required Size({" "}
                <span style={{ color: "#0b7dff" }}>1200 x 700</span> ) px
              </p>{" "}
            </center>
            <div className="croperPopup">
              {src && (
                <div>
                  <ReactCrop
                    locked={true}
                    crop={crop}
                    onImageLoaded={() => { }}
                    onChange={(newCrop) => {
                      setCrop(newCrop);
                      getCroppedImage();
                    }}
                  >
                    <div className="">
                      <img
                        style={{ border: "5px solid red", width: "100%" }}
                        src={src}
                      />
                    </div>
                  </ReactCrop>
                </div>
              )}

              {imageUrl && (
                <img style={{ width: "100%" }} src={imageUrl} alt="Cropped" />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Addbanner;





