import { useCallback,useEffect,useState } from "react";
import { useNavigate,useLocation  } from 'react-router-dom';
import {User_exist,ticket_exist,ORDER_CREATE,VERITY_PAYMENT} from '../../api/constants';
// import UseRazorpay from "react-razorpay";
import img from '../../images/success.gif';

import axios  from "axios";

function RozerPay() {
  var Mcode =""
  var ORDERID= ""
  var Tickectname = ""
  var Tickectid= ""
  var qnt = 0;
  var amt=0;
const [btnshow,setBtnshow]=useState(true);

  const search = useLocation().search;
  useEffect(()=>{
    debugger;
    var url=window.location.href.split("?");
    var v=search;
    var ticketurl = atob(url[1])
    var mytic = ticketurl?.split("&");
    Mcode = mytic[0].split("=")[1]
     ORDERID= mytic[1].split("=")[1]
    Tickectname = mytic[2].split("=")[1]
     Tickectid= mytic[3].split("=")[1]
     qnt = mytic[4].split("=")[1];
     amt=mytic[5].split("=")[1];
    //check user exist
    var config = {
      method: 'post',
      url: User_exist,
      headers: { 
        'Content-Type': 'application/json'
      },
      data:{
        "Mcode": Mcode
   },
    };
    
    axios(config).then(function (response) {
  
      if(response.data.isSuccess!==true)
      {
      alert("user not exist");
      window.location.href='/TicketApproval';
      }
      else
      {
           //check ticke exist
    var config = {
      method: 'post',
      url: ticket_exist,
      headers: { 
        'Content-Type': 'application/json'
      },
      data:{
        "TicketId": Tickectid
   },
    };
    
    axios(config).then(function (response) {
      debugger;
      if(response.data.isSuccess!==true)
      {
        alert("Ticket not exist....");
      window.location.href='/TicketApproval';
      }
  
    })
    .catch(function (error) {
      console.log(error);
     
    });
      }
  
    })
    .catch(function (error) {
      console.log(error);
     
    });
 
  },[]);
  //load function
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  //dispaly page
  async function displayRazorpay() {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }
    else{
      var data = JSON.stringify({
        "amount": 100,
        "currency": "INR",
        "receipt": "rcptid_11",
        "notes": []
      });
      
      var config = {
        method: 'post',
        url: 'https://api.razorpay.com/v1/orders',
        headers: { 
          'Authorization': 'Basic cnpwX3Rlc3RfdEM0VmZUNVZzenF1Z1c6d2syYmRTYTZnNlBLNVcxc3RkQlBpMDBq', 
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
  .then(function (response) {
    debugger;
    console.log(JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log(error);
  });
    }
    // creating a new order

//     const result = await axios.post("https://api.razorpay.com/v1/orders",{
//     "amount": amt,

//     "currency": "INR",

//     "receipt": "rcptid_11",

//    "notes": []

// });

//     if (!result) {
//         alert("Server error. Are you online?");
//         return;
//     }

    // Getting the order details back
   //  const { amount, id: order_id, currency } = result.data;
  //create order by admin
 
  var config = {
    method: 'post',
    url: ORDER_CREATE,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : {

      "MCODE": Mcode,

      "PCQuantity": qnt,

      "strSubType": Tickectname,//Tickectname,

      "ticket": {"ticketID":Tickectid }//order_id  Tickectid

    },
  };
  
  axios(config).then(function (response) {
    debugger;
    if(response.data.isSuccess!==true)
    {
  alert(response.data.message);
  window.location.href='/TicketApproval';
    }

  })
  .catch(function (error) {
   
  });
    const options = {                                                                                                                                                                                                                                                                     
        key: "rzp_test_tC4VfT5VszqugW", // Enter the Key ID generated from the Dashboard
        amount: amt,
        currency: "INR",
        name: "Soumya Corp.",
        description: "Test Transaction",
        order_id: "order_id",
        handler: async function (response) {
          debugger;
            const data = {
                orderCreationId: "order_id",
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
//payment verification

var configpay = {
  method: 'post',
  url: VERITY_PAYMENT,
  headers: { 
    'Content-Type': 'application/json'
  },
  data : {

    "CHNumber":ORDERID,
    "strRazorPayPaymentID":response.razorpay_payment_id,
    "strRazorPayOrderID":response.razorpay_order_id,
    "strRazorPayMessage":"Payment complete"

  },
};

axios(configpay).then(function (response) {
  if(response.data.isSuccess===true)
  {
    setBtnshow(false);
    setTimeout(() => {
      window.location.href='/TicketApproval';
    }, 1000);
  }

})
.catch(function (error) {
  console.log(error);
 
});
        },
        prefill: {
            name: "Soumya Dey",
            email: "SoumyaDey@example.com",
            contact: "9999999999",
        },
        notes: {
            address: "Soumya Dey Corporate Office",
        },
        theme: {
            color: "#61dafb",
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}
  return (
    <div className="App">
      <div className="row my-3">
        <div className="col-md-12 text-center my-2">
       
{
  btnshow?<button className="p-2"onClick={displayRazorpay} style={{backgroundColor:"green",color:"white"}}>Pay Here </button>
  : <div> <p>Payment Successfully Done..</p> <img src={img}></img></div>
}
        </div>
      </div>
    </div>
  );
}
export default RozerPay;