import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import { SHARE_VIA_EMAIL } from '../api/constants';
import { WhatsappShareButton } from 'react-share';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import axios from 'axios';
import { Button } from '@mui/material';


function Print(props) {


  var thisname = props.type == "reg" ? props.name : localStorage.getItem('onspotname');
  var ticketprice =  props.type == "reg" ? props.tckprice :  localStorage.getItem("ticketprice")
  var ticketqty =   props.type == "reg" ? props.tckqty :  localStorage.getItem("onspotqty")  

  const [isimage, setisimage] = useState(false)
  const [mailtoInput, setmailtoInput] = useState("")
  const [mailbtnImg, setmailbtnImg] = useState(false)
  const [imageUrl, setImageUrl] = useState('');

  const getImage = () => {

  }

  useEffect(() => {

    console.log(props.pic)
    if (props.pic.length < 2 || !props.isImg) {
      setisimage(true)
    }

  }, [])

  // setTimeout(() => {
  //     handleprint()
  // }, 10);

  const handleAfter = () => {
  
    if (props.reload === false) {

    }
    else {
      // window.location.reload()
    }

  }

  const compoentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => compoentRef.current,
    documentTitle: ' ACCEX TICKET',
    onAfterPrint: () => handleAfter()
  })

  function isValidEmail(email) {
    // Regular expression pattern for email validation
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  const handleShareEmail = () => {
    debugger;
    setmailbtnImg(true)
    console.log(mailtoInput)
    var isValid = isValidEmail(mailtoInput?.trim());
    if (isValid) {

      // axios.post(SHARE_VIA_EMAIL, { "Name": thisname, "Company": props.company, "TicketID": props.ticket, "profile_pic":"https://dev-api.accex.saitec.in/ORDER/1142/QR/QR_20234623030646.png", "QRPath":"https://dev-api.accex.saitec.in/ORDER/1142/QR/QR_20234623030646.png", "MailTo": mailtoInput ,"EventID":props.event ,"TicketPrice":ticketprice , "TicketQty" :ticketqty }).then((response) => {
      axios.post(SHARE_VIA_EMAIL, { "Name": thisname, "Company": props.company, "TicketID": props.ticket, "profile_pic": props.pic, "QRPath": props.qr, "MailTo": mailtoInput, "EventID": props.event, "TicketPrice": ticketprice, "TicketQty": ticketqty }).then((response) => {
        console.log(response)
        if (response.data) {
          document.querySelector('.sharemailDiv button').style.background = 'green'

          setTimeout(() => {
        document.querySelector('.sharemailDiv button').style.background = '#0b7dff'
        document.querySelector('.sharemailDiv button').innerHTML ='✉'
          }, 4000);
        }
      })
    }
    else {
      alert("Please Enter a vaild Email Address");
      return;
    }
  };



  // whats app emailing to client 

  // const handleScreenshot = () => {
  //   html2canvas(compoentRef.current).then((canvas) => {
  //     canvas.toBlob((blob) => {
  //       const file = new File([blob], 'screenshot.png', { type: 'image/png' });
  //       const url = URL.createObjectURL(file);
  //       setImageUrl(url); // Set the imageUrl state with the object URL
  //       saveAs(blob, 'screenshot.png'); // Save the screenshot as an image file
  //     });
  //   });
  // };



  const handleShareWhatsApp = () => {
    // Capture the content to be shared using html2canvas
    html2canvas(compoentRef.current).then((canvas) => {
      const imageUrl = canvas.toDataURL();
      console.log(imageUrl)

    });
  };


  const createImageFromDataURL = (dataURL) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = dataURL;
    });
  };




  const sendImageViaWhatsApp = async () => {
    try {
      const imageFile = `https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png`;

      const formData = new FormData();
      formData.append('image', imageFile);

      const response = await axios.post(
        'https://api.whatsapp.com/send?phone=6388056383&text=Helloworld!',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      console.log('Image sent successfully!', response);
    } catch (error) {
      console.error('Error sending image:', error);
    }
  };




  // const imageURL = `https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png`


  return (
    <>
     <div>
        <center>
          <Button style={{ background: '#37c14e' }} variant="contained" color="primary" onClick={handleprint}>Print Ticket</Button>
        </center>
        <br />
        <div className='sharemailDiv'>
          <input type="text" style={{ width: '75%' }} placeholder='Enter Mail Address' value={mailtoInput} onChange={e => setmailtoInput(e.target.value)} />
          <button onClick={handleShareEmail}>{mailbtnImg ? `✔` : `✉`} </button>
        </div>
        <br />

        <br />
        {/* <div className='shareWhatsappDiv'>
        <button onClick={sendImageViaWhatsApp }>Take Screenshot</button>
        <button onClick={handleShareWhatsApp }>whatsapp web</button>
          <WhatsappShareButton url={imageUrl}>
            Share on WhatsApp 
            </WhatsappShareButton>
        </div> */}
      </div>
      <div ref={compoentRef} style={{ width: "100%", height: "100%", letterSpacing: '1px' }} >
      {/* <div ref={compoentRef} style={{ width: "100%", height: window.innerHeight, letterSpacing: '1px' }}> */}
        <div className='printPdfpaper_bannerImg'></div>
        <center>
          <h3 className='ticketclient_name'>{thisname == null ? "" : thisname}</h3>
          <h5 className='ticketcompany_name'>{props.company}</h5>
          {
            isimage ?
              <div>
                <img style={{ height: "100px", width: "100px" }} src={props.qr} />
              </div>
              :
              <div className='printPdfpaper_flexedImg'>
                <div>
                  <img style={{ height: "80px", width: "80px" }} src={props.pic} />
                </div>
                <div>
                  <img style={{ height: "80px", width: "80px" }} src={props.qr} />
                </div>
              </div>
          }
          <h6 className='ticket_ticket_id'>Ticket ID : {props.ticket}</h6>
        </center>
        <br />
        <div className='printPdfpaper_vip'></div>
      </div>

     
    </>
  )
}

export default Print;