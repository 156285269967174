import React from "react";
import "../components/loader.css";

function Loader({ style }) {
  return (
    <>
      <main style={style} className="main-loader">
        <div className="preloader">
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
        </div>
      </main>
    </>
  );
}

export default Loader;
