import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

import { DataGrid } from "@mui/x-data-grid";

import Leftnav from "../leftNav/Leftnav";
import Navbar from "../navbar";
import "../tagmaster/tagmaster.css";



function Tagmaster() {
  const [getevent, setgetevent] = useState([]);
  

  const columns= [
    // { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'EVENT NAME', width: 250 },
    { field: 'description', headerName: 'DESCRIPTION', width: 200 },
    { field: 'startDate', headerName: 'START DATE', width: 300 },
    { field: 'endDate', headerName: 'END DATE', width: 300 }
  ]
 


 
  // useEffect(() => {
  //  var fetchEvents = async ()=>{
  // const res = await axios.get("http://localhost:45455/api/Event/getAllEvents")
  // console.log(res.data)
  // setgetevent(res.data)
  //  }
  //  fetchEvents()

  // },[]);


  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="main">
        <div className="">
          <form className="d-flex space">
            <div className="mb-4">
              <label className="form-label">Tag Name</label>
              <input type="text" className="form-control small" />

            </div>

            <div className="mb-4">
              <label className="form-label">from date</label>
              <input type="text" className="form-control small" />
           
            </div>

            <div className="mb-4">
              <label className="form-label">To date</label>
              <input type="text" className="form-control small" />
          
            </div>
          </form>
     
        </div>
        <DataGrid
          rows={getevent}
          columns={columns}
          pageSize={10}
          getRowId={(row) => row.rowpos}
        />
      </div>
    </>
  );
}

export default Tagmaster;
