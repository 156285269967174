import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { URL_GETALLORDER, URL_REGISTRATION_BY_QR, URL_VERIFYTICKET } from '../api/constants';
import { useNavigate,useLocation  } from 'react-router-dom';
import Navbar from './navbar';
import {Fab, TextareaAutosize} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import { Link } from "react-router-dom";
import noticketimg from "../images/noticket.png"
import "../components/verifyticket.css"
import Alert from './alert/alert';
import { useParams } from "react-router";

function Verifyticket() {
  setTimeout(() => {
    setalert(false)
  }, 5000);


    const Navigate = useNavigate()
    const search = useLocation().search;
    const mcode = new URLSearchParams(search).get('MCODE');
   const chnumber =new URLSearchParams(search).get('CHNUMBER');
   const ticketid =new URLSearchParams(search).get('TICKETID');
   const registrationid = new URLSearchParams(search).get('REGISTRATIONID');
   const registrationcode =new URLSearchParams(search).get('REGISTRATIONCODE');
   const [alert,setalert] =useState(false)
   const[alertmsg,setalertmsg] =useState("")
    const [noticketFound,setnoticketFound] =useState(false)
    const [found,setfound] = useState(true)
    const [registrationFound,setregistrationFound] = useState(true)
    
    const [url,seturl]=useState(true)
    const [date,setdate] = useState("")
    const [success,setsuccess]  =useState("success-box")
    const [succsshead,setsuccsshead] = useState("")
    const [ticketmsg,setticketmsg]  =useState("")
    const [thischNumber,setthischNumber] = useState("")

  const [Address,setAddress] = useState("")
  const [onlineOffline,setonlineOffline] = useState("")
  const [EventType,setEventType] = useState("")

    const [thismcode,setthismcode]=useState("")
    const [eventname,seteventname] = useState("")
    const [orgname,setorgName] = useState("")
    const [startingdate,setstartingdate] = useState("")
    const [eventStartdate,seteventStartdate] = useState("")
    const [eventEnddate,seteventEnddate] = useState("")
    const [endingdate,setendingdate] = useState("")
    const [price,setprice] = useState("")
    const [ticketnum,setticketid] = useState("")
    const [ticketnametyp,setticketnametyp] = useState("")

    // ------------------

    const [printPopup,setprintPopup] = useState(false)
    const [printingArr,setprintingArr] = useState([])


    const [registerArray,setregisterArray] = useState([])

    var   MCODE = "jyoti0000000000000001";
    var chNumber = "25";
    var TICKETID ="QRIVIUBMAXJFBMES";
useEffect(()=>{


  // now decode this url  // now i have to decode these decodes          note :  btoa and atob 

  var ticketurl = atob(search?.slice(1))
  // now decode this url  // now i have to decode these decodes         note :  btoa and atob 

  var ticketurl = atob(search.slice(1))

  var mytic = ticketurl?.split("=")
  var Mcode = mytic[1]?.split("&")[0]
  var Chnumber = mytic[2]?.split("&")[0]
  var TicketiD = mytic[3]?.split("&")[0]


  console.log(Mcode)

  if(Mcode?.length > 16){

    var data = JSON.stringify({
      "MCODE": Mcode,
      "CHNumber": Chnumber,
      "ticket": {
        "TicketId": TicketiD
      }
    });
    
    var config = {
      method: 'post',
      url: URL_VERIFYTICKET,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(response)
      var notFund = response.data.isSuccess;
      if(notFund === false){
          
        // Navigate("/verifyticket/notfound")
        setalert(true)
        setalertmsg("Ticket Already Verified !")
        
    }
      
      setthischNumber(response.data.data.chNumber)
      setthismcode(response.data.data.mcode)
      setticketid(response.data.data.ticket.ticketId)
      setticketnametyp(response.data.data.ticketType)
      seteventname(response.data.data.eventName)
      setorgName(response.data.data.pcQuantity)   // this is quantity 
      setstartingdate(response.data.data.strExibitStartTime)
      seteventStartdate(response.data.data.dtExibitStartDate)
      seteventEnddate(response.data.data.dtExibitEndDate)
      setendingdate(response.data.data.strExibitEndTime)
      setprice(response.data.data.ticket.price)
      setEventType(response.data.data.eventRowpos)
      setonlineOffline(response.data.data.remark)
      setAddress(response.data.data.strSubType)
      console.log(response.data.message);
     
      
      setdate(response.data.data.billDate)
      var result = response.data.isSuccess;
     
     
      if(result === false){
          setsuccsshead("OOPS !")
          setsuccess("error-box")
          setticketmsg(response.data.message)
          
      }
      else if(result === true){
        setsuccess("success-box")
        setsuccsshead("Success !")
        setticketmsg(response.data.message)
      }
     
    
      seturl(false)
      
      
  
    })
    .catch(function (error) {
      console.log(error);
     
    });

  }
  else{

    seturl(false)
    setfound(false)
    setregistrationFound(true)
    
    
    console.log("registration details ")
    console.log(ticketurl)
    var myRegistration = ticketurl?.split("=")
    var registrationID = myRegistration[1]?.split("&")[0]
    var registrationCode = myRegistration[2]?.split("&")[0]
    console.log(registrationID , registrationCode)

    // now get the registration details of this registration code 

    var data = JSON.stringify({
      "strRegistrationCode": registrationCode
    });
    
    var config = {
      method: 'post',
      url: URL_REGISTRATION_BY_QR,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(response.data);
      if(response.data.isSuccess === true){
        setregisterArray(response.data.data)
      }
      else{
        debugger;
        window.location.href = '/notfound';
      }
      
    })
    .catch(function (error) {
      console.log(error);
    });

//-----------------------------------------end ------

  }


    

},[mcode,chnumber,ticketid])


const handleopenpo = () =>{
  console.log(printingArr)
  setprintPopup(true)
  // now hit api to get details for the scanned QR values 

}

if(url){
    return(
        <>
         <Navbar />
            <Link to={`${(`/verifyticket?${btoa(`MCODE=${MCODE}&CHNUMBER=${chNumber}&TICKETID=${TICKETID}`)}`)}`}>
            <Fab style={{marginRight:10}} color="primary">
                <ArrowBack/>
            </Fab>
            </Link>
        </>
    )
}






if(found){


return (
    <>
    <Navbar />
    {alert ?  <div><Alert  className="top animating" msg={alertmsg} clas="verifyticketpp"  /></div>:""}
    
   <center>
    
    <div id={success}>
  <div className="face">
      <div className="eye"></div>
      <div className="eye right"></div>
      <div className="mouth happy"></div>
    </div>
    <div className="shadow scale"></div>
    <div className="message"><h3 style={{color:"#fff",fontSize:"30px"}} className="alert">{succsshead}</h3><p style={{color:"#fff",fontSize:"24px"}}>{ticketmsg}</p></div> 
    
  </div>
    </center> 
  
  <div id={success}></div>
<div className="ticketbox">


<div className="ticket">
  <span className="airline eventnaaame">{eventname}</span>
  <span className="airline airlineslip">{ticketnametyp}</span>
  
  <div className="content">
    
  

    <div className="eventdetails verfifyTckt" style={{width:"100%"}}>
      <div>
      <p>Start Date : <span>{eventStartdate?.slice(0,10)}</span> </p>
      <p>Start Time : <span>{startingdate}</span> </p>
      <p>Ticket ID :<span >{ticketnum}</span> </p>
      <p>Order Quantity :<span >{orgname}</span> </p>
      <p>Client Name : <span>{thismcode}</span></p>
        <p>CH NUMBER :<span>{thischNumber}</span> </p>
      
      </div>
      <div className='marginFoeMobile'>
      <p>End Date : <span>{eventEnddate?.slice(0,10)}</span> </p>
      <p>End Time : <span>{endingdate}</span> </p>

        <p>Ticket Price : <span>{price}&nbsp;Rs</span> </p>
       
        <p>EVENT TYPE:<span>{onlineOffline}</span> </p>
        {/* <p> <span>{EventType}</span></p> */}
        <p>Address: <span>{Address}</span></p>
        
        </div>
    </div>
  </div>

</div>
</div> 
<button onClick={handleopenpo} ></button>


    </>
  )

}

if(registrationFound){
  return(
    <>
    {
        registerArray?.map((tickets,index)=>{
          return(
            <>
            
              <div className="" key={index}>
                <center><h3 className="registratoinh3" >REGISTRATION DETAILS</h3></center>
                  <div className="eventdetails registrationbox">
                  
                    <div>

                    {tickets.name === ""? "":<p><span>name:</span>{tickets.name}</p>}
                    {tickets.surname === ""? "":<p><span>surname:</span>{tickets.surname}</p>}
                    {tickets.gender === ""? "":<p><span>gender:</span>{tickets.gender}</p>}
                    
                    {tickets.email === ""? "":<p><span>email:</span>{tickets.email}</p>}
                    {tickets.jobTitle === ""? "":<p><span>jobTitle:</span>{tickets.jobTitle}</p>}
                    {tickets.companyOrganisation === ""? "":<p><span>company / <br />Organisation:</span>{tickets.companyOrganisation}</p>}
                    </div>
                    <div>
                    {tickets.workAddress === ""? "":<p><span>workAddress:</span>{tickets.workAddress}</p>}
                    {tickets.workPhone === ""? "":<p><span>workPhone</span>:{tickets.workPhone}</p>}
                    {tickets.homeAddress === ""? "":<p><span>homeAddress:</span>{tickets.homeAddress}</p>}
                    {tickets.homePhone === ""? "":<p><span>homePhone:</span>{tickets.homePhone}</p>}
                    {tickets.website === ""? "":<p><span>website:</span>{tickets.website}</p>}
                    {tickets.cellPhone === ""? "":<p><span>cellPhone:</span>{tickets.cellPhone}</p>}
                    {tickets.blog === ""? "":<p><span>blog:</span>{tickets.blog}</p>}
                    </div>
                  </div>
              </div>
            </>
          )
        })
      }
    </>
  )
}
}
export default Verifyticket;