import React, { useEffect, useState } from 'react'
import "./alert.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Alert(props) {
  // useEffect(()=>{
  //   notify()
  // },[])
  const [display,setdisplay]=useState("aletpopup animatingTop")
var color = props.color;
function func(e){
  setdisplay("none")

}

const showorhide = ()=>{
  if(display == "none"){
    setdisplay("aletpopup")
  }
}
showorhide()

const notify = () => toast(props.msg);

  return (
    <>
    <p className={`${display} ${props.clas}`} style={{color:color}}>{props.msg}<span id='crossAlert' onClick={(e)=>func(e)}><ion-icon name="close-outline"></ion-icon></span></p> 
    {/* <div>
        <ToastContainer />
      </div> */}
    </>
  )
}

export default Alert;