import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../table/datagrid.css";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  ApprovedEvents,
  URL_ALLEVENTS,
  URL_UPDATEEVENTS,
} from "../../api/constants";
import Cellpopup from "../Cellpopup";
import Alert from "../alert/alert";
import Cookies from "js-cookie";
import Loader from "../Loader";

function Approved() {
  const [approvedData, setApprovedData] = useState([]);
  const [popupcell, setpopupcell] = useState(false);
  const [popupmsg, setpopupmsg] = useState("");
  const [finalClickInfo, setFinalClickInfo] = useState(null);
  const [thisuser, setthisuser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //   confirm alertbox
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");

  //alert color

  setTimeout(() => {
    setalert(false);
  }, 5000);

  const approveuser = (item) => {
    console.log(item.id);
    window.location.href = `/dashboard/itemmaster/eventdetails/${item.id}`;
  };

  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: "orgName", headerName: "Organiser Name", width: 200 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionStartDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionEndDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "View Event",
      headerName: "View Event",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => approveuser(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Event
          </Button>
        );
      },
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button onClick={unapproveuser}  variant="contained" color="primary" >UnApprove</Button>
    //     );
    //   }
    // }
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button variant="contained" color="secondary"  > Delete </Button>
    //     );
    //   }
    // }
  ];

  //after updating or deleting call get all events so we don't have to refresh after updating

  // const refresh= () => {
  //   var data = JSON.stringify({
  //     "status": "APPROVED"
  //   });

  //   var config = {
  //     method: 'post',
  //     url: URL_ALLEVENTS,

  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     console.log(response.data.data);
  //     var row = document.querySelector(".MuiDataGrid-overlay");
  //     row.innerHTML = `<img height="80px" src="https://gifimage.net/wp-content/uploads/2018/04/page-loading-gif-14.gif" />`;
  //     setApprovedData(response.data.data)

  //   })
  //   .catch(function (error) {
  //     console.log("oops"+error);
  //   });
  // }

  var user = Cookies.get("userType");
  var Orgmcode = Cookies.get("orgmcode");
  var Org = [];
  var approvedEvent = [];
  // approved update /edit
  useEffect(() => {
    setIsLoading(true);
    document.querySelector(".pagenames").innerHTML = "Dashboard > Approved";
    setalert(true);
    setalertmsg("Click On Table Cell TO UnApprove !");

    var data = JSON.stringify({
      Status: "APPROVED",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        var mydata = response.data.data;
        console.log("from approved");
        console.log(mydata);
        var row = document.querySelector(".MuiDataGrid-overlay");
        // row.innerHTML = `No Event Found`;  
        for (var i = 0; i < mydata.length; i++) {
          var today = new Date();
          var EndDate = new Date(mydata[i].exhibitionEndDate);
          if (mydata[i].status === "APPROVED" && today < EndDate) {
            approvedEvent.push(mydata[i]);
          }
        }

        console.log(approvedEvent); // no data for now
        if (user === "ORGANIZER") {
          console.log("hi theere");
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode == Orgmcode) {
              Org.push(mydata[i]);
            }
          }
          console.log(Org) // org data 
          console.log("apporg", Org);
          console.log("approveORGArry", Org);
          setApprovedData(Org);
          setIsLoading(false);
        } else {
          console.log("approveArry", approvedEvent);
          var uniqueRowpos = [];
          var uniqueEvents = approvedEvent.filter(function (event) {
            var rowpos = event.rowpos;
            if (uniqueRowpos.indexOf(rowpos) === -1) {
              uniqueRowpos.push(rowpos);
              return true;
            }
            return false;
          });

          console.log("unique", uniqueEvents);

          setApprovedData(uniqueEvents);
          setIsLoading(false);
        }
        // setApprovedData(response.data?.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleOnCellClick = (params) => {
    setpopupcell(true);
    setFinalClickInfo(params);
    setthisuser(params);
    console.log(params);

    // setpopupmsg(params.row)

    // alert(params.id)
  };

  const unapproveuser = (thisuser) => {
    var name = thisuser.row.name;
    console.log("eventID", thisuser.row.rowpos);
    setpopupcell(false);
    var id = thisuser.row.rowpos;

    var data = JSON.stringify({
      Status: "PENDING",
      ROWPOS: id,
    });

    var config = {
      method: "post",
      url: URL_UPDATEEVENTS,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setalert(true);
        setalertmsg(`✔ ${name} has been UnApproved`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendtodraft = () => {
    var name = thisuser.row.name;

    setpopupcell(false);
    var id = thisuser.id;

    var data = JSON.stringify({
      Status: "DRAFT",
      ROWPOS: id,
    });

    var config = {
      method: "post",
      url: URL_UPDATEEVENTS,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
        setalert(true);
        setalertmsg(`✔ ${name} has been Added to Draft`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var color = "green";

  return (
    <>
      <div className="alertposition ">
        {alert ? <Alert className="" msg={alertmsg} color={color} /> : ""}
      </div>
      {popupcell ? (
        <div className="detailbox animatingTop">
          <button className="cross" onClick={() => setpopupcell(false)}>
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <span>
            Are You sure want to <b>Unapprove</b> Or <b>Draft</b>{" "}
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              className="animatingBottom"
              style={{
                borderRadius: 5,
                backgroundColor: "red",
                padding: "1px 6px",
                fontSize: "12px",

                marginTop: "20px",
              }}
              onClick={() => unapproveuser(thisuser)}
              variant="contained"
              color="primary"
            >
              UnApprove
            </Button>
            <Button
              className="animatingBottom"
              style={{
                borderRadius: 5,
                backgroundColor: "rgb(9, 65, 250)",
                padding: "1px 6px",
                fontSize: "12px",

                marginTop: "20px",
              }}
              onClick={() => sendtodraft(thisuser)}
              variant="contained"
              color="primary"
            >
              DRAFT
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboardtable animatingBottom dashtbl">
          <DataGrid
            className=""
            rows={approvedData}
            columns={column}
            pagination={false}
            getRowId={(row) => row.exhibitionStartDate}
            onCellClick={handleOnCellClick}
            components={{
              Toolbar: GridToolbarExport,
            }}
          />
        </div>
      )}
    </>
  );
}

export default Approved;
