import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import "../../table/datagrid.css";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Alert from "../alert/alert";
import { URL_ALLEVENTS } from "../../api/constants";
import Cookies from "js-cookie";
import Loader from "../Loader";

function All(loading) {
  const [allData, setAllData] = useState([]);
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [popupcell, setpopupcell] = useState(false);

  const [finalClickInfo, setFinalClickInfo] = useState(null);
  const [thisuser, setthisuser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const [org,setorg] = useState([])

  const approveuser = (item) => {
    console.log(item.id);
    window.location.href = `/dashboard/itemmaster/eventdetails/${item.id}`;
  };

  setTimeout(() => {
    setalert(false);
  }, 5000);

  const column = [
    // { field: 'id', headerName: 'ID' },
    { field: "orgName", headerName: "Organiser Name", width: 200 },
    { field: "name", headerName: "Event Name", width: 200 },
    { field: "category", headerName: "Category", width: 180 },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionStartDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "edate",
      headerName: "End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.exhibitionEndDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },

    {
      field: "View Event",
      headerName: "View Event",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => approveuser(params)}
            variant="contained"
            color="primary"
          >
            View Event
          </Button>
        );
      },
    },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   sortable: false,
    //   width: 130,
    //   disableClickEventBubbling: true,
    //   renderCell: () => {
    //     return (
    //       <Button variant="contained" color="secondary"  > Delete </Button>
    //     );
    //   }
    // }
  ];
  var user = Cookies.get("userType");
  var Orgmcode = Cookies.get("orgmcode");
  var Org = [];
  useEffect(() => {
    setIsLoading(true);
    document.querySelector(".pagenames").innerHTML = "Dashboard > All";

    // console.log(user);
    var data = JSON.stringify({
      status: "ALL",
    });

    var config = {
      method: "post",
      url: URL_ALLEVENTS,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // debugger;
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        var mydata = response.data.data;
        var row = document.querySelector(".MuiDataGrid-overlay");
        // row.innerHTML = `No Event Found !`;  // this line causing error
        // if (mydata !== null) {
        //   var row = document.querySelector(".MuiDataGrid-overlay");
        //   row.innerHTML = `<img height="80px" src="https://gifimage.net/wp-content/uploads/2018/04/page-loading-gif-14.gif" />`;
        //   setAllData(response.data.data);
        // }
        if (user === "ORGANIZER") {
          console.log("hi ORGANIZER theere");
          for (var i = 0; i < mydata.length; i++) {
            if (mydata[i].orgMcode == Orgmcode) {
              Org.push(mydata[i]);
              // setIsLoading(false)
            }
          }

          setAllData(Org);
          setIsLoading(false);
        } else {
          const reversedArray = [];

          for (let i = mydata.length - 1; i >= 0; i--) {
            const valueAtIndex = mydata[i];

            reversedArray.push(valueAtIndex);
          }

          // console.log(reversedArray)
          setAllData(reversedArray);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleOnCellClick = (params) => {
    console.log(params);
    setpopupcell(true);
    setFinalClickInfo(params);
    setthisuser(params);
  };

  var color = "green";

  return (
    <>
      {alert ? (
        <Alert className="top animatingTop" msg={alertmsg} color={color} />
      ) : (
        ""
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className="dashboardtable animatingTop dashtbl">
          <DataGrid
            className="alleventtbl"
            rows={allData}
            columns={column}
            // pageSize={110}
            // pagination={false}
            getRowId={(row) => row.rowpos}
            onCellClick={handleOnCellClick}
            components={{
              Toolbar: GridToolbarExport,
            }}
          />
          {popupcell ? (
            <div className="detailbox animatingTop">
              <button
                className="cross animatingBottom"
                onClick={() => setpopupcell(false)}
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
              <span>
                <b>Id: &nbsp;&nbsp;&nbsp;</b>
                {thisuser.id}
              </span>
              <span>
                <b>Name: &nbsp;&nbsp;&nbsp;</b>
                {thisuser.row.name}
              </span>
              <span>
                <b>Description: &nbsp;&nbsp;&nbsp;</b>
                {thisuser.row.summary?.slice(0, 50)}
              </span>
              <span>
                <b>No Of TICKETS : &nbsp;&nbsp;&nbsp;</b>
                {thisuser.row.ticket_list.length}
              </span>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <span>
                  <b>Start Time: &nbsp;&nbsp;&nbsp;</b>
                  {thisuser.row.exhibitionStartTime}
                </span>
                <span>
                  <b>End Time: &nbsp;&nbsp;&nbsp;</b>
                  {thisuser.row.exhibitionStartTime}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default All;
