import React from 'react'
import { useEffect } from 'react';
import {Route, useNavigate} from 'react-router-dom'



function ProtectedRoute({ component: Component, isAuthenticated, ...rest }) {

  const navigate = useNavigate();
  
  <Route {...rest} render={(props) => (
    isAuthenticated === true
      ? <Component {...props} />
      : <navigate to='/login' />
  )} />
}

export default ProtectedRoute;
