import React from "react";
import axios from "axios";
import Select from "react-select";
import DataGrids from "../../table/DataGrid";
import Leftnav from "../leftNav/Leftnav";
import Navbar from "../navbar";
import "../usermanager/usermanager.css";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import Alert from "../alert/alert";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
  getAllUsers,
  updateUser,
  URL_ADDCATEGORY,
  URL_CREATESUPPORT,
  URL_DELETEUSER,
  URL_GETALLSUPPORT,
  URL_GETALLUSER,
  URL_UPDATEUSER,
} from "../../api/constants";
import Loader from "../Loader";

function Usermanager() {
  const [rowpos, setrowpos] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [edate, setedate] = useState("");
  const [active, setactive] = useState("");

  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [tableData, setTableData] = useState([]);
  const [showpopup, setshowpopup] = useState(false);
  const [updatebtn, setupdatebtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [plusbtn, setplusbtn] = useState(false);
  const [plusbtnview, setplusbtnview] = useState("none");
  const [minusbtnview, setminusbtnview] = useState("block");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  setTimeout(() => {
    setalert(false);
  }, 4000);

  //table columns

  const columns = [
    {
      field: "eventRowpos",
      headerName: "EVENT NAME",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <a
            style={{ color: "blue" }}
            href={`/dashboard/itemmaster/eventdetails/${params.row.eventRowpos}`}
          >
            {params.row.eventName}
          </a>
        );
      },
    },
    { field: "ticketID", headerName: "EVENT NO", width: 150 },
    { field: "subject", headerName: "Subject", width: 150 },
    { field: "message", headerName: "Message", width: 300 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "edate",
      headerName: " Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.edate
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    { field: "userName", headerName: "User Name", width: 150 },
    { field: "userType", headerName: "Usertype", width: 150 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        if (params.row.phoneNumber === "") {
          return "--NA--";
        } else {
          return params.row.phoneNumber;
        }
      },
    },
    {
      field: "Action",
      headerName: "Edit",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <Button variant="contained" color="primary" onClick={edit}>
            view Detail
          </Button>
        );
      },
    },
  ];
  //refresh function call next after update and delete api

  const refresh = () => {};

  //delete user

  const deleteuser = (item) => {};

  // edit function

  const edit = () => {
    setshowpopup(true);

    // console.log(selectedRows.firstName)
  };

  //update

  const update = (item) => {
    setupdatebtn(true);

    setshowpopup(false);
    // updateuser()
  };

  const updateuser = () => {
    console.log("fine  update button clicked  !");
  };

  // get all users
  useEffect(() => {
    setIsLoading(true);
    var config = {
      method: "post",
      url: URL_GETALLSUPPORT,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data.data);
        setIsLoading(false);

        var res = response.data.data;
        const unique = [...new Map(res.map((m) => [m.edate, m])).values()];
        setTableData(unique);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const adduser = () => {
    var data = JSON.stringify({
      MCODE: "gajen0000000000000001",
      Subject: subject,
      Message: message,
      EventRowpos: "20020",
    });

    var config = {
      method: "post",
      url: URL_CREATESUPPORT,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleplus = () => {
    setplusbtnview("block");
    setminusbtnview("none");
    setplusbtn(true);
    setIsDisabled(false);
  };
  const handleminus = () => {
    setplusbtnview("none");
    setminusbtnview("block");
    setplusbtn(false);
  };

  var color = "green";

  return (
    <>
      <Navbar />
      <Leftnav />
      {alert ? (
        <Alert className="top animating" msg={alertmsg} color={color} />
      ) : (
        ""
      )}
      <div className="main">
        <center>
          <h5 className="pagenames orgrank animatingLeft">Support Ticket</h5>
        </center>

        <br />
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {plusbtn ? (
              <div className="">
                <div className="d-flex space">
                  <div className="mb-3 animatingBottom">
                    <label className="form-label">Subject</label>
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => setsubject(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Message</label>
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                      className="form-control small"
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="mb-3 animatingBottom">
                    <label className="form-label">edate</label>
                    <input
                      type="text"
                      value={edate}
                      onChange={(e) => setedate(e.target.value)}
                      className="form-control small"
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="d-flex space">
                  <div className="mb-3 animatingTop">
                    <label className="form-label">Active</label>
                    <input
                      type="text"
                      value={active}
                      onChange={(e) => setactive(e.target.value)}
                      className="form-control small"
                    />
                  </div>
                </div>
                <div className="mb-3 mtop animatingBottom">
                  <Button
                    style={{ marginLeft: "40px" }}
                    variant="contained"
                    onClick={adduser}
                  >
                    Add Support
                  </Button>
                  {updatebtn ? (
                    <Button
                      style={{ marginLeft: "40px" }}
                      variant="contained"
                      onClick={updateuser}
                    >
                      update user
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="dashtbl">
              <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={7}
                getRowId={(row) => row.edate}
                components={{
                  Toolbar: GridToolbarExport,
                }}
                // checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = tableData.filter((row) =>
                    selectedIDs.has(row.edate)
                  );

                  setSelectedRows(selectedRows);
                  console.log(selectedRows);
                  // setfirstname(selectedRows.mcode)
                }}
                {...tableData}
              />
              {showpopup ? (
                <div className="popupbg">
                  <div className="userpopup animatingTop">
                    <p className="cross mb" onClick={() => setshowpopup(false)}>
                      <ion-icon name="close-outline"></ion-icon>
                    </p>
                    {selectedRows.map((item, i) => (
                      <>
                        {i === 0 ? (
                          <div className="popupdata animatingBottom">
                            <p>
                              {" "}
                              <span>Subject:&nbsp;&nbsp;</span>
                              {item.subject}
                            </p>
                            <p>
                              <span>Message:&nbsp;&nbsp;</span>
                              {item.message}
                            </p>
                            <p>
                              <span>Edate:&nbsp;&nbsp;</span>
                              {item.edate?.slice(0, 10)}
                            </p>
                          </div>
                        ) : null}

                        {/* <Button className='animatingBottom' style={{marginLeft:"40px"}}  variant="contained" onClick={()=>update(item)}>edit</Button>          
         <Button 
         className='animatingBottom'
         style={{
        borderRadius: 5,
        backgroundColor: "red",
        padding: "6px 18px",
        fontSize: "14px",
        float:"right",
        marginRight:"20px"
       }}
       variant="contained"  onClick={()=>deleteuser(item)}>delete</Button> */}
                      </>
                    ))}
                  </div>
                </div>
              ) : (
                " "
              )}
            </div>
          </>
        )}

        {/* <div className='toggle animatingTop'>  
      <button className={minusbtnview} id="plusadduser" onClick={handleplus}><ion-icon name="add-outline"></ion-icon></button>
      <button className={plusbtnview} onClick={handleminus}><ion-icon name="arrow-up-outline"></ion-icon></button>
      </div>  */}
      </div>
    </>
  );
}

export default Usermanager;
