import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Leftnav from "../leftNav/Leftnav";
import "./dashboard.css";
import Navbar from "../navbar";
import axios from "axios";
import MaterialTable from "material-table";
import All from "./all";
import Approved from "./approved";
import UnApproved from "./unapproved";
import Reject from "./reject";
import Gridbox from "./gridbox";
import Banner from "../Banner";
import { URL_ALLEVENTS, URL_GETUNIQUEEVENT } from "../../api/constants";
import Cookies from "js-cookie";
import CountUp from "react-countup";
import Loader from "../Loader";

var columns = [
  { title: "id", field: "id", hidden: true },

  { title: "First name", field: "first_name" },
  { title: "Last name", field: "last_name" },
  { title: "email", field: "email" },
];

function Dashboard(props) {
  var userCode = Cookies.get("orgmcode");
  if (userCode === undefined || userCode === "" || userCode === null) {
    window.location.href = "/";
  }
  const [all, setall] = useState(true);
  const [approvedbtn, setapprovedbtn] = useState(false);
  const [Unapprovedbtn, setUnapprovedbtn] = useState(false);
  const [reject, setreject] = useState(false);
  const [data, setData] = useState([]);
  const [allcolor, setallcolor] = useState(
    "afterclicktopbuttons animatingBottom"
  );
  const [apprColor, setapprColor] = useState("topbuttons animatingTop");
  const [unApprColor, setunApprColor] = useState("topbuttons animatingBottom");
  const [trashcolor, settrashcolor] = useState("topbuttons animatingTop");
  const [isLoading, setIsLoading] = useState(false);
  const [carouseldata, setcarouseldata] = useState([]);

  const [allcount, setallcount] = useState("");
  const [apprcount, setapprcount] = useState("");
  const [unapprcount, setunapprcount] = useState("");
  const [draftcount, setdraftcount] = useState("");

  const [ALLCount, setALLCount] = useState("");
  const [unapprCnt, setunapprCnt] = useState("");
  const [apprCnt, setapprCnt] = useState("");
  const [draftCnt, setdraftCnt] = useState("");

  var user = Cookies.get("orgmcode");

  useEffect(() => {
    // debugger;
    //  console.log(user)
    //  console.log("dashbord Enter")
    //  const childElement = document.querySelector('.MuiDataGrid-footerContainer');
    //  const parentElement = childElement.parentElement;
    //  parentElement.classList.add('custom-style');
  }, []);

  const allbutton = (e) => {
    setallcolor("afterclicktopbuttons");
    setapprColor("topbuttons");
    setunApprColor("topbuttons");
    settrashcolor("topbuttons");
    setall(true);
    setUnapprovedbtn(false);
    setreject(false);
    setapprovedbtn(false);
  };


  const approvedbutton = (e) => {
    setallcolor("topbuttons");
    setapprColor("afterclicktopbuttons");
    setunApprColor("topbuttons");
    settrashcolor("topbuttons");
    setapprovedbtn(true);
    setall(false);
    setUnapprovedbtn(false);
    setreject(false);
  };


  const unapprovedbutton = (e) => {
    setallcolor("topbuttons");
    setunApprColor("afterclicktopbuttons");
    setapprColor("topbuttons");
    settrashcolor("topbuttons");
    setall(false);
    setUnapprovedbtn(true);
    setreject(false);
    setapprovedbtn(false);
  };

  const trashbutton = (e) => {
    setallcolor("topbuttons");
    settrashcolor("afterclicktopbuttons");
    setapprColor("topbuttons");
    setunApprColor("topbuttons");
    setall(false);
    setUnapprovedbtn(false);
    setreject(true);
    setapprovedbtn(false);
  };

  useEffect(() => {
    setallcount(localStorage.all);
    setapprcount(localStorage.appr);
    setunapprcount(localStorage.unappr);
    // setdraftcount(localStorage.draft)
  }, []);
  //  const parentDiv = document.querySelector(' .table div div');
  //  parentDiv.firstChild.style.display ='none'

  return (
    <>
      <Navbar />
      <Leftnav />

      <div className="main dashboardMain">
        {/* <Banner  /> */}

        <center>
          <h5 className="pagenames orgrank animatingLeft">Dashboard</h5>
        </center>
        <br />
        <Gridbox
          setALLCount={setALLCount}
          setunapprCnt={setunapprCnt}
          setapprCnt={setapprCnt}
          setdraftcount={setdraftcount}
        />
        <div className="gridbox ">
          <button className={allcolor} onClick={allbutton}>
            <span>All &nbsp;</span>
            <span>
              ( <CountUp end={ALLCount} /> )
            </span>
          </button>
          <span className="divider">|</span>
          <button className={apprColor} onClick={approvedbutton}>
            <span>Approved</span>
            <span>
              ( <CountUp end={apprCnt} /> )
            </span>
          </button>
          <span className="divider">|</span>
          <button className={unApprColor} onClick={unapprovedbutton}>
            <span>UnApproved</span>
            <span>
              ( <CountUp end={unapprCnt} /> )
            </span>
          </button>
          <span className="divider">|</span>
          <button className={trashcolor} onClick={trashbutton}>
            <span>DRAFT</span>
            <span>
              ( <CountUp end={draftcount} /> )
            </span>
          </button>
        </div>
        <div style={{ height: "6px" }}></div>
        {/* <div className="table animatingBottom"> */}
        {all ? <All /> : ""}
        {approvedbtn ? <Approved /> : ""}
        {Unapprovedbtn ? <UnApproved /> : ""}
        {reject ? <Reject /> : ""}
        {/* </div> */}
      </div>
    </>
  );
}

export default Dashboard;
