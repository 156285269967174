import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { URL_UPLOADIMAGE, URL_UPLOADProfileImg } from '../api/constants';
import Button from '@mui/material/Button';


function Camera(mcode) {

    
    const webcamRef = useRef(null);
    const [image,setimage] = useState("")
    const [file,setfile] =useState([])
    const [camshow,setcamshow]= useState("block")
    const [uploadfiledisable,setuploadfiledisable]=useState(true)
    

    const videoConstraints = {
        width: 1890,
        height: 1220,
        facingMode: "user"
      };


   
      const capture = useCallback(
        () => {
            
            console.log(webcamRef.current.getScreenshot())
          var img =webcamRef.current.getScreenshot()
          setimage(img)
            var data = img.toString().replace(/^data:image\/webp;base64,/, "");

            const imgElement = document.createElement('img');
            imgElement.src = img;
            
            const divElement = document.getElementById('priviewimg');
            divElement.innerHTML = '';
            divElement.appendChild(imgElement);
            setcamshow("block")
            setuploadfiledisable(false)
            

            // var buf = Buffer.from(data, 'base64');
            // setimage(data)
            // console.log(data)
            
        },
        [webcamRef]
      );
     
      function urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    }
        
        //Usage example:
          urltoFile(image, 'profile.jpeg','image/jpeg')
          .then(function(file){
            
            setfile(file)
            });
        
  
       const upload =()=>{
        var FormData = require('form-data');
        var data = new FormData();
        data.append('File', file);
        data.append('Type', 'ORDER');
        data.append('Id', mcode.mcode);
        data.append('SubType', 'ProfilePhoto');
        
        var config = {
          method: 'post',
          url: URL_UPLOADIMAGE,
          headers: { 
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          console.log(response.data);
          if(response.data.isSuccess === true){
           alert("Image Uploaded ")
           document.getElementById('camerapop').click()
           mcode.upload(false)
          }
          else{
            alert(response.data.message)
          }
        
        })
        .catch(function (error) {
          console.log(error);
        });
       }
                                                                                                                                                             
      

    


  return (
    
    <>
  <div style={{display: image=="" ?  camshow : "none"}}>
  <Webcam
      screenshotFormat='image/jpeg' 
      ref={webcamRef}
      audio={false}
      videoConstraints={videoConstraints}
       /> 
  </div>
  {
    camshow == "block" ?<div id="priviewimg"></div> : ""
  }
       
        <div className='flex-button'>
            <Button variant='contained' onClick={capture}>capture image</Button>
            <Button style={{background:"green",color:'#fff'}} onClick={upload} disabled={uploadfiledisable}>Upload</Button>
        </div>
    </>
  )
}

export default Camera