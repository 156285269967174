import React from "react";
import QRCode from "react-qr-code";

function CreateQr() {
    return (
        <>
            <div style={{ background: 'white', padding: '16px' }}>
                <QRCode  value="https://dev-api.accex.saitec.in\verifyTicket?UkVHSVNUUkFUSU9OSUQ9NjA4JlJFR0lTVFJBVElPTkNPREU9R0RWV1NRVVRXVVBXRldDRQ==" />
            </div>
        </>
    )
}

export default CreateQr;