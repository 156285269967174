import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import "./itemmaster.css";
import {
  URL_CREATETICKET,
  URL_GETUNIQUEEVENT,
  URL_GETtCKTbyID,
  URL_DOWNLOAD_QR,
  URL_GET_REGIDTRATION_QR,
  URL_GET_QR_PDF,
} from "../../api/constants";
import { useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Alert from "../alert/alert";
import Cookies from "js-cookie";
import Navbar from "../navbar";
import Leftnav from "../leftNav/Leftnav";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { ListItemAvatar } from "@material-ui/core";
import SimpleImageSlider from "react-simple-image-slider";
function Eventdetails() {
  const { id } = useParams();
  const [rowpos, setrowpos] = useState("");
  const [ticketname, setticketname] = useState("");
  const [tickettype, settickettype] = useState("");
  const [ticketid, setticketid] = useState("");
  const [eventid, seteventid] = useState(id);
  const [maxcount, setmaxcount] = useState("");
  const [price, setprice] = useState("");
  const [tableData, setTableData] = useState([]);
  const [slider, setSlider] = useState([]);
  const [carouselWidth, setcarouselWidth] = useState(1060);
  // const [thiseventname,setthiseventname] = useState("")
  const images = [];
  const [selectedRows, setSelectedRows] = useState([]);

  // const[gstpercent,setgstpercent] = useState("")
  const [bookingstart, setbookingstart] = useState("");
  const [bookingend, setbookingend] = useState("");
  const [eversion, seteversion] = useState("");
  const [minsize, setminsize] = useState("");
  const [amtpercent, setamtpercent] = useState("");
  const [showpopup, setshowpopup] = useState(false);
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [createticketbtn, setcreateticketbtn] = useState(false);
  const [registerArray, setregisterArray] = useState([]);
  const [requiredFields, setrequiredFields] = useState("");
  const [addbooth, setaddbooth] = useState(false);
  const [ticket, setticket] = useState([]);
  const [booth, setbooth] = useState([]);
  const [addon, setaddon] = useState([]);
  const [thisQrcode, setthisQrcode] = useState("");
  const [registrationmcode, setregistrationmcode] = useState("");
  const [TBAarray, setTBAarray] = useState([]);
  const [data, setdata] = useState([]);

  let [isDisabled, setIsDisabled] = useState(false);
  var Addoncolumns = [
    { field: "eventId", headerName: "Event Id" },
    { field: "ticketId", headerName: "Ticket ID", width: 150 },
    { field: "ticketType", headerName: "Ticket Type", width: 150 },
    { field: "ticketName", headerName: "Description", width: 150 },

    { field: "price", headerName: "Price", width: 150 },
    {
      field: "View Event",
      headerName: "View Addon",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewTickt(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Addon
          </Button>
        );
      },
    },
  ];
  // const images = [

  // ];
  var columns = [
    { field: "eventId", headerName: "Event Id" },
    { field: "ticketId", headerName: "Ticket ID", width: 150 },
    { field: "ticketType", headerName: "Ticket Type", width: 150 },
    { field: "ticketName", headerName: "Ticket Name", width: 150 },
    { field: "minCount", headerName: "Min Ticket", width: 150 },
    { field: "maxCount", headerName: "Max Ticket", width: 150 },
    {
      field: "booking_Start_Date",
      headerName: "Booking Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.booking_Start_Date
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    {
      field: "booking_End_Date",
      headerName: "Booking End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.booking_End_Date
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    { field: "price", headerName: "Price", width: 150 },
    {
      field: "View Event",
      headerName: "View Ticket",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewTickt(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Ticket
          </Button>
        );
      },
    },
  ];

  var boothcolumns = [
    { field: "eventId", headerName: "Event Id" },
    { field: "ticketId", headerName: "Ticket ID", width: 150 },
    { field: "ticketType", headerName: "Ticket Type", width: 150 },
    { field: "area", headerName: "Area", width: 150 },
    {
      field: "incrementThereAfter",
      headerName: "Increment There After",
      width: 150,
    },
    {
      field: "booking_Start_Date",
      headerName: "Booking Start Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.booking_Start_Date
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    { field: "bookingStartTime", headerName: "Start Time", width: 150 },
    {
      field: "booking_End_Date",
      headerName: "Booking End Date",
      width: 180,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return params.row.booking_End_Date
          .split("T")[0]
          .split("-")
          .reverse()
          .join()
          .replace(",", "-")
          .replace(",", "-");
      },
    },
    { field: "bookingEndTime", headerName: "End Time", width: 150 },
    { field: "rental", headerName: "Price", width: 150 },
    { field: "ticketName", headerName: "Stall Name", width: 150 },
    { field: "minSize", headerName: "Minimum Stall Size", width: 150 },
    {
      field: "View Event",
      headerName: "View Booth",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewTickt(params)}
            variant="contained"
            color="primary"
          >
            {" "}
            View Booth
          </Button>
        );
      },
    },
  ];

  var registrationcolumns = [
    { field: "name", headerName: "Name" },
    { field: "surname", headerName: "Surname", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "jobTitle", headerName: "Job Title", width: 150 },
    {
      field: "companyOrganisation",
      headerName: "Company / Organisation",
      width: 150,
    },
    { field: "workAddress", headerName: "Work Address", width: 150 },
    { field: "workPhone", headerName: "Work Phone", width: 150 },
    { field: "homeAddress", headerName: "Home Address", width: 150 },
    { field: "homePhone", headerName: "Home Phone", width: 150 },
    { field: "website", headerName: "Website", width: 150 },
    { field: "cellPhone", headerName: "Cell Phone", width: 150 },

    { field: "blog", headerName: "Blog", width: 150 },
    {
      field: "View Event",
      headerName: "QR Code",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => viewQR(params.row)}
            variant="contained"
            color="primary"
          >
            {" "}
            Qr Code
          </Button>
        );
      },
    },
  ];

  const viewQR = (item) => {
    setregistrationmcode(item.mcode);
    setshowpopup(true);
    console.log(item);
    var data = JSON.stringify({
      rowpos: item.rowpos,
    });

    var config = {
      method: "post",
      url: URL_GET_REGIDTRATION_QR,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data.path);
        setthisQrcode(response.data.data.path);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const viewTickt = (item) => {
    console.log(item.id);
    window.location.href = `/dashboard/itemmaster/eventdetails/${id}/id?name=${item.id}&event=${id}`;
  };

  // -------------------------------------------columns of table end
  var img = "";

  var thisEventTicketID;
  setTimeout(() => {
    setalert(false);
  }, 5000);

  var user = Cookies.get("userType");
  useEffect(() => {
    // registration details list of this event
    var data = {
      ROWPOS: id,
    };

    // axios.get(URL_GETREQUIRED_FIELD,data).then((response) => {
    //   console.log(response)

    // })

    // var config = {
    //   method: 'post',
    //   url: URL_GETREQUIRED_FIELD,
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    // axios(config)
    // .then(function (response) {
    //   console.log(response.data);
    //   console.log(response.data.data[0].requiredFields);
    //   // setrequiredFields(response.data[0].requiredFields)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    // _________________________________end :)

    var data = JSON.stringify({
      ROWPOS: id,
    });

    var config = {
      method: "post",
      url: URL_GETUNIQUEEVENT,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // debugger;
        console.log(response.data.data);
        console.log(response.data.data[0].ticket_list);

        var arr1 = [];
        var arr2 = [];
        var arr3 = [];
        var merge = [];
        var uniqueImages = [];

        setdata(response.data.data);
        //  console.log(response.data.data[0].ticket_list)
        setticket(response.data.data[0].ticket_list);
        setbooth(response.data.data[0].booth_list);
        console.log(response.data.data[0].booth_list);
        setaddon(response.data.data[0].addon_list);
        console.log(response.data.data[0].name);

        const unique = [
          ...new Map(
            response.data.data[0].media_list.map((m) => [m.path, m])
          ).values(),
        ];
        console.log("unique" + unique.length);

        for (var i = 0; i < unique.length; i++) {
          images.push({ url: unique[i].path });
        }
        console.log("images count" + images.length);

        setSlider(
          <div className="slider-container">
            <SimpleImageSlider
              width={carouselWidth}
              height={carouselWidth / 2}
              images={images}
              showBullets={window.matchMedia("(max-width: 768px)")}
              showNavs={false}
              loop={true}
            />
          </div>
        );
        arr1.push(response.data.data[0].ticket_list);
        arr2.push(response.data.data[0].booth_list);
        arr3.push(response.data.data[0].addon_list);

        setTableData(merge.concat(...arr1, ...arr2, ...arr3));
        console.log(merge.concat(...arr1, ...arr2, ...arr3));

        setregisterArray(response.data.data[0].registration_list);
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log(registerArray);

    // debugger;
    // making carousel responsive
    var media = window.matchMedia("(max-width: 768px)").matches;
    if (media) {
      console.log("mobile view ");
      setcarouselWidth(380);
    } else {
      console.log("dekstop view");
      setcarouselWidth(1080);
    }
  }, []);

  const addticket = () => {
    console.log(
      eventid,
      ticketname,
      tickettype,
      maxcount,
      price,
      bookingstart,
      bookingend,
      eversion
    );

    if (ticketname == "") {
      setalert(true);
      setalertmsg("ticketname can not be blank !");
    } else if (tickettype == "") {
      setalert(true);
      setalertmsg("tickettype can not be blank !");
    } else if (maxcount == "") {
      setalert(true);
      setalertmsg("maxcount  can not be blank !");
    } else if (price == "") {
      setalert(true);
      setalertmsg("price can not be blank !");
    } else if (bookingstart == "") {
      setalert(true);
      setalertmsg("booking start Date can not be blank ! ");
    } else if (bookingend == "") {
      setalert(true);
      setalertmsg("Booking End Date can not be blank !");
    }

    var data = JSON.stringify({
      EventId: eventid,
      TicketType: tickettype,
      TicketName: ticketname,
      MaxCount: maxcount,
      Price: price,
      GST_PERCENT: "5",
      Booking_Start_Date: bookingstart,
      Booking_End_Date: bookingend,
      Eversion: eversion,
    });

    var config = {
      method: "post",
      url: URL_CREATETICKET,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        var ok = response.data.isSuccess;
        if (ok === true) {
          setalert(true);
          setalertmsg(`✔ ${ticketname}  has been created successfully !`);
          setcreateticketbtn(false);
        } else {
          setalert(true);
          setalertmsg(`sorry some error occured ! please try again`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Addbooth = () => {
    console.log(
      eventid,
      ticketname,
      tickettype,
      maxcount,
      price,
      bookingstart,
      bookingend,
      eversion
    );

    //  if(maxcount == ""){
    //   setalert(true)
    //   setalertmsg("maxcount  can not be blank !")
    //  }
    //  else if(price == ""){
    //   setalert(true)
    //   setalertmsg("price can not be blank !")
    //  }
    //  else if(bookingstart == ""){
    //   setalert(true)
    //   setalertmsg("booking start Date can not be blank ! ")
    //  }
    //  else if(bookingend == ""){
    //   setalert(true)
    //   setalertmsg("Booking End Date can not be blank !")
    //  }
    //  else if(minsize == ""){
    //   setalert(true)
    //   setalertmsg("minsize can not be blank !")
    //  }
    //  else if(amtpercent == ""){
    //   setalert(true)
    //   setalertmsg("tickettype can not be blank !")
    //  }

    var data = JSON.stringify({
      EventId: eventid,
      TicketType: tickettype,
      TicketName: ticketname,
      MaxCount: maxcount,
      Price: price,
      GST_PERCENT: "5",
      Booking_Start_Date: bookingstart,
      Booking_End_Date: bookingend,
      Eversion: eversion,
      MinSize: minsize,
      MinAmountPercent: amtpercent,
    });

    var config = {
      method: "post",
      url: URL_CREATETICKET,

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        var ok = response.data.isSuccess;
        if (ok === true) {
          setalert(true);
          setalertmsg(`✔ ${ticketname} BOOTH  has been created successfully !`);
          setcreateticketbtn(false);
        } else {
          setalert(true);
          setalertmsg(`sorry some error occured ! please try again`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const switchbooth = () => {
    settickettype("BOOTH");
    setaddbooth(true);
    setcreateticketbtn(true);
    setalert(true);
    setalertmsg("Average square  must be greater than 200 square feet");
    setIsDisabled(true);
  };

  const switchticket = () => {
    settickettype("TICKET");
    setcreateticketbtn(true);
    setaddbooth(false);
    setIsDisabled(false);
  };

  const viewticket = (tickets) => {
    console.log(tickets);
  };

  const GetTicket = () => {
    console.log(data);
    console.log(data[0].orgMcode);

    // hit api
  };

  const hidepopup = () => {
    debugger;
    setshowpopup(false);
  };

  const printThisQR = () => {
    console.log(thisQrcode);
    console.log(registrationmcode);

    var data = JSON.stringify({
      MCODE: registrationmcode,
      QRCodePath: thisQrcode,
    });

    var config = {
      method: "post",
      url: URL_GET_QR_PDF,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        window.open(response.data.data, "_blank");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(images);

  return (
    <>
      <Navbar />
      <Leftnav />
      <div className="staticalert">
        {alert ? <Alert className="top" msg={alertmsg} /> : ""}
      </div>

      <div className="main eventdetailsPage">
        {data?.map((mydata, index) => {
          return (
            <div className="">
              <center>
                <h5 className="pagenames">Event Details</h5>
              </center>

              <h3 className="eventname" key={index}>
                {" "}
                {mydata.name}{" "}
              </h3>
              {slider}

              {/* <div className="eventbanner">
            {
              
              mydata.media_list?.map((c,i)=>
              {
            return( <img src={ c=="" ? "":  c.path.replace(/ /g, '%20')} />)
              })
            }
            </div> */}

              <div className="eventdetails" style={{ width: "85%" }}>
                <div>
                  <p>
                    <span>StartDate:</span>
                    {mydata.exhibitionStartDate.slice(0, 10)}
                  </p>
                  <p>
                    <span>Start time:</span>
                    {mydata.exhibitionStartTime}
                  </p>
                  {mydata.venueType === "ONLINE" ? (
                    <a
                      style={{ color: "red" }}
                      href={mydata.eventURL}
                      target="_blank"
                    >
                      {" "}
                      <p>
                        <span>Event Url:</span>
                        {mydata.eventURL}
                      </p>
                    </a>
                  ) : (
                    <p>
                      <span>Event Address</span>
                      {mydata.venueAddress}
                    </p>
                  )}

                  {/* <p style={{display:"inline-block"}}><span>Summary</span>{mydata.summary }</p> */}
                  <p>
                    <span>Venue Type:</span>
                    {mydata.venueType}
                  </p>
                  <p>
                    <span>Venue State:</span>
                    {mydata.venueState === ""
                      ? "NOT ASSIGNED"
                      : mydata.venueState}
                  </p>
                  <p>
                    <span>Venue Name:</span>
                    <span style={{ width: "289px", color: "#0d82ffba" }}>
                      {mydata.venueName === " " || mydata.venueName === ""
                        ? "NOT ASSIGNED"
                        : mydata.venueName}
                    </span>
                  </p>
                  <p>
                    <span>Created At:</span>
                    {mydata.createdAt?.slice(0, 10)}
                  </p>
                  <p>
                    <span>Event Post Type:</span>
                    {mydata.postType}
                  </p>
                  <p>
                    <span>Dismantling Start Date:</span>
                    {mydata.dismantlingStartDate?.slice(0, 10) === "1900-01-01"
                      ? ""
                      : mydata.dismantlingStartDate?.slice(0, 10)}
                  </p>
                  {mydata.dismantlingStartTime === "StartTime" ? (
                    ""
                  ) : (
                    <p>
                      <span>Dismantling Start time:</span>
                      {mydata.dismantlingStartTime}
                    </p>
                  )}
                  <p>
                    <span>Dismantling End Date:</span>
                    {mydata.dismantlingEndDate?.slice(0, 10) === "1900-01-01"
                      ? ""
                      : mydata.dismantlingEndDate?.slice(0, 10)}
                  </p>
                  {mydata.dismantlingEndTime === "EndTime" ? (
                    ""
                  ) : (
                    <p>
                      <span>Dismantling End time:</span>
                      {mydata.dismantlingEndTime}
                    </p>
                  )}
                  <p>
                    <span>Mobile:</span>
                    {mydata.mobile}
                  </p>
                  <p>
                    <span>Email:</span>
                    {mydata.email}
                  </p>
                  <p>
                    <span>Category:</span>
                    {mydata.category}
                  </p>
                  <p>
                    <span>Hall:</span>
                    {mydata.halls}
                  </p>
                  <p className="eventDetails_weblink">
                    <span>Weblink</span>&nbsp;&nbsp;
                    <a
                      style={{ color: "red" }}
                      href={mydata.webLink}
                      target="_blank"
                    >
                      {mydata.webLink}
                    </a>
                  </p>
                </div>

                <div>
                  <p>
                    <span>status:</span>
                    {mydata.status}
                  </p>
                  <p>
                    <span>End date:</span>
                    {mydata.exhibitionEndDate.slice(0, 10)}
                  </p>
                  <p>
                    <span>End time:</span>
                    {mydata.exhibitionEndTime}
                  </p>
                  <p>
                    <span>Venue Landmark:</span>
                    {mydata.venueLandMark === ""
                      ? "NOT ASSIGNED"
                      : mydata.venueLandMark}
                  </p>
                  {mydata.show_name === "Show OrgniserName" ? (
                    <p>
                      <span>Organiser Name:</span>
                      {mydata.organiseR_NAME}
                    </p>
                  ) : (
                    <p>
                      <span>Organiser Company:</span>
                      {mydata.orgCompany}
                    </p>
                  )}

                  <p>
                    <span>Summary:</span>
                    <span style={{ width: "289px", color: "#0d82ffba" }}>
                      {mydata.summary}
                    </span>
                  </p>

                  <p>
                    <span>Mounting Start Date:</span>
                    {mydata.mountingStartDate.slice(0, 10) === "1900-01-01"
                      ? ""
                      : mydata.mountingStartDate.slice(0, 10)}
                  </p>
                  {mydata.mountingStartTime === "StartTime" ? (
                    ""
                  ) : (
                    <p>
                      <span>Mounting Start Time:</span>
                      {mydata.mountingStartTime}
                    </p>
                  )}
                  <p>
                    <span>Mounting End Date:</span>
                    {mydata.mountingEndDate.slice(0, 10) === "1900-01-01"
                      ? ""
                      : mydata.mountingEndDate.slice(0, 10)}
                  </p>
                  {mydata.mountingEndTime === "EndTime" ? (
                    ""
                  ) : (
                    <p>
                      <span>Mounting End Time:</span>
                      {mydata.mountingEndTime}
                    </p>
                  )}
                  {/* <p><span>VenueType</span>{mydata.VenueType}</p>  */}
                </div>
              </div>
            </div>
          );
        })}
        <br />

        <div className="table animatingBottom eventdetailTable">
          <p style={{ color: "#000", fontWeight: "800" }}> Ticket details</p>
          <DataGrid
            rows={ticket}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.ticketId}
            components={{
              Toolbar: GridToolbarExport,
            }}
            // checkboxSelection
            // onSelectionModelChange={(ids) => {
            //   const selectedIDs = new Set(ids);
            //   const selectedRows = tableData.filter((row) =>
            //     selectedIDs.has(row.qrCodePath),

            //   );
            //   ;
            //   setSelectedRows(selectedRows);

            //   console.log(selectedRows)
            //   // setfirstname(selectedRows.mcode)

            // }}
            {...tableData}
          />
        </div>

        <div className="table animatingBottom eventdetailTable">
          <p style={{ color: "#000", fontWeight: "800" }}> Booth details</p>
          <DataGrid
            rows={booth}
            columns={boothcolumns}
            pageSize={5}
            getRowId={(row) => row.ticketId}
            components={{
              Toolbar: GridToolbarExport,
            }}
            {...tableData}
          />
        </div>

        <div className="table animatingBottom eventdetailTable">
          <p style={{ color: "#000", fontWeight: "800" }}> Addon details</p>
          <DataGrid
            rows={addon}
            columns={Addoncolumns}
            pageSize={5}
            getRowId={(row) => row.ticketId}
            components={{
              Toolbar: GridToolbarExport,
            }}
            {...tableData}
          />
        </div>

        <div className="table animatingBottom eventdetailTable">
          <p style={{ color: "#000", fontWeight: "800" }}>
            {" "}
            registration details
          </p>
          <DataGrid
            rows={registerArray}
            columns={registrationcolumns}
            pageSize={5}
            getRowId={(row) => row.rowpos}
            components={{
              Toolbar: GridToolbarExport,
            }}
            {...tableData}
          />

          {showpopup ? (
            <>
              <div className="userpopup">
                <div className="popupdata animatingBottom">
                  <p
                    className="cross mb"
                    style={{ color: "#111", fontSize: "24px" }}
                    onClick={hidepopup}
                  >
                    <ion-icon name="close-outline"></ion-icon>
                  </p>
                  <div
                    style={{ height: "400px", width: "400px", display: "flex" }}
                  >
                    <img
                      className="animatingBottom"
                      style={{ padding: "20px" }}
                      src={thisQrcode}
                    />
                    <br />
                    <input type="hidden" value={registrationmcode} />
                  </div>
                  <center>
                    {" "}
                    <button
                      style={{
                        background: "#016fe8",
                        border: "2px solid #016fe8",
                        color: "#fff",
                        padding: "5px",
                      }}
                      onClick={printThisQR}
                    >
                      Print QR
                    </button>
                  </center>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="alltyptckt">
          {/* {
        ticket.map((tickets,index)=>{
         
          return(
            <>
            <Link to={`${(`/dashboard/itemmaster/eventdetails/${id}/id?name=${tickets.ticketId}&event=${id}`)}`} style={{textDecoration:"none"}}>
            <div className="thisevent_tickets" key={index} >
            {
                tickets.ticketName == "PREMIUM" ?   <div className="thisevent_tickets_icon" ><ion-icon name="ribbon-outline"></ion-icon></div> :<div className="thisevent_tickets_icon"><ion-icon name="medal-outline"></ion-icon></div>

              }
        <div className="display_flex" >
        <input type="hidden" className="thisisticketid" value={tickets.ticketId} />
          <p key={tickets.ticketId}>Ticket Id:{tickets.ticketId}</p> 
          <p>{tickets.ticketType}</p> 
          <p>Event Id:{tickets.eventId}</p>
           <p>Max count :{tickets.maxCount}</p>
           {user === "ORGANIZER" ? "":<p> Rs:  {tickets.price}</p>}
           
        </div>
      </div>
      </Link>
            </>
          )
        })
      } */}

          {/* {
        booth.map((tickets,index)=>{
          return(
            <>
            <Link to={`${(`/dashboard/itemmaster/eventdetails/${id}/id?name=${tickets.ticketId}&event=${id}`)}`} style={{textDecoration:"none"}}>
            <div className="thisevent_booth">
              {
                tickets.ticketName == "PREMIUM" ?   <div className="thisevent_tickets_icon"><ion-icon name="ribbon-outline"></ion-icon></div> :<div className="thisevent_tickets_icon"><ion-icon name="medal-outline"></ion-icon></div>

              }
        <div className="display_flex para">
         <input type="hidden" className="thisisticketid" value={tickets.ticketId} />
          <p  key={index}>Ticket Id:{tickets.ticketId}</p> 
          <p>{tickets.ticketType}</p> 
          <p>Event Id:{tickets.eventId}</p>
           <p>Max count :{tickets.maxCount}</p>
           {user === "ORGANIZER" ? "":<p> Rs:  {tickets.rental}</p>}
        </div>
      </div>
      </Link>
            </>
          )
        })
      } */}

          {/* {
        addon.map((tickets,index)=>{
          return(
            <>
            <Link to={`${(`/dashboard/itemmaster/eventdetails/${id}/id?name=${tickets.ticketId}&event=${id}`)}`} style={{textDecoration:"none"}}>
            <div className="thisevent_addon">
              {
                tickets.ticketName == "PREMIUM" ?   <div className="thisevent_tickets_icon"><ion-icon name="ribbon-outline"></ion-icon></div> :<div className="thisevent_tickets_icon"><ion-icon name="medal-outline"></ion-icon></div>

              }
        <div className="display_flex para">
        <input type="hidden" className="thisisticketid" value={tickets.ticketId} />
          <p   key={index}>Ticket Id:{tickets.ticketId}</p> 
          <p>{tickets.ticketType}</p> 
          <p>Event Id:{tickets.eventId}</p>
           <p>Max count :{tickets.maxCount}</p>
           {user === "ORGANIZER" ? "":<p> Rs:  {tickets.price}</p>}
        </div>
      </div>
      </Link>
            </>
          )
        })
      } */}

          <br />
          <br />
          {/* {
        registerArray?.map((tickets,index)=>{
          return(
            <>
            
              <div className="" key={index}>
                <center><h3 className="registratoinh3" >REGISTRATION DETAILS</h3></center>
                  <div className="eventdetails registrationbox">
                  
                    <div>

                    {tickets.name === ""? "":<p><span>name:</span>{tickets.name}</p>}
                    {tickets.surname === ""? "":<p><span>surname:</span>{tickets.surname}</p>}
                    {tickets.gender === ""? "":<p><span>gender:</span>{tickets.gender}</p>}
                    
                    {tickets.email === ""? "":<p><span>email:</span>{tickets.email}</p>}
                    {tickets.jobTitle === ""? "":<p><span>jobTitle:</span>{tickets.jobTitle}</p>}
                    {tickets.companyOrganisation === ""? "":<p><span>company / <br />Organisation:</span>{tickets.companyOrganisation}</p>}
                   
                   
                     
                   
                    </div>
                    <div>
                    {tickets.workAddress === ""? "":<p><span>workAddress:</span>{tickets.workAddress}</p>}
                    {tickets.workPhone === ""? "":<p><span>workPhone</span>:{tickets.workPhone}</p>}
                    {tickets.homeAddress === ""? "":<p><span>homeAddress:</span>{tickets.homeAddress}</p>}
                    {tickets.homePhone === ""? "":<p><span>homePhone:</span>{tickets.homePhone}</p>}
                    {tickets.website === ""? "":<p><span>website:</span>{tickets.website}</p>}
                    {tickets.cellPhone === ""? "":<p><span>cellPhone:</span>{tickets.cellPhone}</p>}
                    {tickets.blog === ""? "":<p><span>blog:</span>{tickets.blog}</p>}
                    </div>
                  </div>
              </div>
            </>
          )
        })
      } */}
        </div>

        <br />
        {/* <div className="flexed">
    
       <Button 
         className='animatingBottom'
         style={{
          borderRadius: 5,
          backgroundColor: "green",
          padding: "6px 12px",
          fontSize: "14px"
        
          
      }}  variant="contained" onClick={switchticket} > START CREATING TICKET </Button>


       <Button 
         className='animatingBottom'
         style={{
          borderRadius: 5,
          backgroundColor: "color: rgb(13, 118, 255)",
          padding: "6px 12px",
          fontSize: "14px",
         
          
      }}  variant="contained"  onClick={switchbooth} > START CREATING BOOTH </Button>
     </div> */}
        <br />
        <br />

        {/* <Button 
         className='animatingBottom'
         style={{
          borderRadius: 5,
          backgroundColor: "green",
          padding: "6px 12px",
          fontSize: "14px",
        marginTop:"40px",
          marginLeft:"130px"
      }}  variant="contained" onClick={GetTicket}  >DOWNLOAD QR</Button> */}

        {createticketbtn ? (
          <div className="createticketform">
            <div className="d-flex space">
              <div className="mb-3 animatingTop">
                <label className="form-label">EVENT ID</label>
                <input
                  type="text"
                  value={eventid}
                  onChange={(e) => seteventid(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingBottom">
                <label className="form-label">TICKET TYPE</label>
                <input
                  type="text"
                  value={tickettype}
                  onChange={(e) => settickettype(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingTop">
                <label className="form-label">TICKET NAME</label>
                <input
                  type="text"
                  value={ticketname}
                  onChange={(e) => setticketname(e.target.value)}
                  className="form-control small"
                />
              </div>
            </div>
            <div className="d-flex space">
              <div className="mb-3 animatingTop">
                <label className="form-label">MAX_COUNT</label>
                <input
                  type="text"
                  value={maxcount}
                  onChange={(e) => setmaxcount(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingBottom">
                <label className="form-label">PRICE</label>
                <input
                  type="text"
                  value={price}
                  onChange={(e) => setprice(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingTop">
                <label className="form-label">BOOKing start date</label>
                <input
                  type="date"
                  value={bookingstart}
                  onChange={(e) => setbookingstart(e.target.value)}
                  className="form-control small"
                />
              </div>
            </div>
            <div className="d-flex space">
              <div className="mb-3 animatingBottom">
                <label className="form-label">Booking End Date</label>
                <input
                  type="date"
                  value={bookingend}
                  onChange={(e) => setbookingend(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingTop">
                <label className="form-label">EVERSION</label>
                <input
                  type="text"
                  value={eversion}
                  onChange={(e) => seteversion(e.target.value)}
                  className="form-control small"
                />
              </div>
              <div className="mb-3 animatingTop">
                <Button
                  className="animatingBottom"
                  style={{
                    borderRadius: 5,
                    backgroundColor: "green",
                    padding: "6px 12px",
                    fontSize: "14px",
                    marginTop: "40px",
                    marginLeft: "130px",
                  }}
                  variant="contained"
                  onClick={addticket}
                  disabled={isDisabled}
                >
                  {" "}
                  ADD TICKET{" "}
                </Button>
              </div>
            </div>
            {addbooth ? (
              <div className="d-flex space">
                <div className="mb-3 animatingBottom">
                  <label className="form-label">Average Square feet</label>
                  <input
                    type="text"
                    value={minsize}
                    onChange={(e) => setminsize(e.target.value)}
                    className="form-control small"
                  />
                </div>
                <div className="mb-3 animatingBottom">
                  <label className="form-label">Amount Percentage</label>
                  <input
                    type="text"
                    value={amtpercent}
                    onChange={(e) => setamtpercent(e.target.value)}
                    className="form-control small"
                  />
                </div>
                <div className="mb-3 animatingTop">
                  <Button
                    className="animatingBottom"
                    style={{
                      borderRadius: 5,
                      backgroundColor: "green",
                      padding: "6px 12px",
                      fontSize: "14px",

                      marginLeft: "130px",
                    }}
                    variant="contained"
                    onClick={Addbooth}
                  >
                    {" "}
                    ADD BOOTH{" "}
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Eventdetails;
